
export interface ContactReasonModel {
    contactReason: string
    contactReasonComment: string
}

class ContactController {    

    applicationId;
    contactReasonData: ContactReasonModel ;

    static $inject = ['$scope', '$q', '$state', 'ContactLondonOffice', 'applicationId', 'Auth', 'dialogs'];
    constructor(private $scope, private $q, private $state, private ContactLondonOffice, applicationId, private Auth, private dialogs) {
        this.applicationId = applicationId;
        this.clear();
    }

    sendEmail() {
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.contactReasonForm;

        if (this.isValidForm(form)) {

            var user = this.Auth.user;

            var data = {
                applicationId: this.applicationId,
                userId: user.userId,
                senderName: user.firstName + ' ' + user.lastName,
                senderEmailId: user.userName,
                contactReasonId: this.contactReasonData.contactReason,
                contactReasonComment: this.contactReasonData.contactReasonComment
            };

            this.ContactLondonOffice.send(data)
                .$promise
                .then((results) => {                        
                    form.$setPristine();
                    window.location.reload();
                }, () => {
                    this.dialogs.error('Error', 'We are sorry but Email cannot be send. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>')
                });
        }
    }
    clear() {
            
        this.contactReasonData = {
            contactReason : null,
            contactReasonComment : null
        }
    }
    isValidForm(form) {
        var isValid = false;
        if (form['ContactLondonOffice'].$dirty && (this.contactReasonData.contactReasonComment !== null && this.contactReasonData.contactReasonComment !== '')) {
            isValid = true;
        }
        return isValid;
    }
}
angular
    .module('app')
    .controller('ContactController', ContactController);