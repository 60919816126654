import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { DocumentUploadConfig, SingleDocumentUploader } from '../../../controls/documentsUpload/DocumentUploadConfig'

export class extensionDriving {
    aupairId: string
    driverInd: boolean
    drivingFrequency: string
    drivingSkills: string
    drivingAccident: boolean
    accidentDetail: string
    hasStateLicense: boolean
    hasIntLicense: boolean        
    stateId: string
    stateExpirationDate: string
    countryId: string
    countryExpirationDate: string
    showError: boolean
    isSubmitted: boolean
}


class ExtensionDrivingController {

    stateDrivingUploadConfig: DocumentUploadConfig<SingleDocumentUploader>
    intDrivingUploadConfig: DocumentUploadConfig<SingleDocumentUploader>        
    showMessageText: boolean = false
    alerts: IAlertConfig[] = []
    details = new extensionDriving();
    displayReadOnly: boolean = false;
    displayOfficeMessage: boolean = false;
    message: string;
    data: any;

    static $inject = ['$rootScope', '$scope', '$q', '$timeout', 'applicationId', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $scope, private $q, private $timeout, public applicationId, private Auth, private USAuPairs, private Spinner) {       
        this.getDrivingData();
    }

    getDrivingData() {
        this.Spinner.show();

        this.USAuPairs.getDriving({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = (result.data && result.data.auPairDrivingLicense) || new extensionDriving();
                this.data = result.data.extensionAppReview;

                // If first time, dont want to auto populate
                if (result.data.auPairDrivingLicense) {
                    this.details.driverInd = result.data.driverInd;
                    this.details.drivingFrequency = String(result.data.drivingFrequency);
                }

                this.details.hasStateLicense = false;
                this.details.hasIntLicense = false;

                if (result.data.auPairDrivingLicense && (result.data.auPairDrivingLicense.stateId || result.data.auPairDrivingLicense.stateExpirationDate)) {
                    this.details.hasStateLicense = true;
                }

                if (result.data.auPairDrivingLicense && (result.data.auPairDrivingLicense.countryId || result.data.auPairDrivingLicense.countryExpirationDate)) {
                    this.details.hasIntLicense = true;
                }

                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.drivLicenseSectionStatus == '3'
                    || this.data.extensionApplicationReview.drivLicenseSectionStatus == '5'
                    || this.data.extensionApplicationReview.drivLicenseSectionStatus == '6')
                        ) || false;

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.drivLicenseSectionStatus == '4') || false;

                this.message = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.drivLicenseSectionStatusReason)
            })
            .finally(() => {
                this.Spinner.hide();
            });   
    }

    submit() {            
        this.$scope.$broadcast('submitted', true);
        var promises = [];
        this.alerts = [];

        var form = this.$scope.drivingForm;

        // Check the validity of form
        var isStateFormValid = true;
        var isIntFormValid = true;
        var isUSDriverValid = true;
        this.details.showError = false;

        if (this.details.driverInd) {

            if (!this.details.hasStateLicense && !this.details.hasIntLicense) {
                this.details.showError = true;
            }

            isUSDriverValid = form['USDriving'].$valid;

            // If state license chosen, check its validity
            if (this.details.hasStateLicense) {
                isStateFormValid = form['USStateLicense'].$valid;
            }

            // If international license chosen, check its validity
            if (this.details.hasIntLicense) {
                isIntFormValid = form['USInternationalLicense'].$valid;
            }
        }            

        if (isStateFormValid && isIntFormValid && isUSDriverValid && !this.details.showError) {
            this.Spinner.show();
            this.details.aupairId = this.Auth.user.usAuPairId;
            this.details.isSubmitted = true;

            promises.push(this.USAuPairs.saveDriving({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

            this.$q.all(promises)
                .then((result) => {
                    this.$scope.drivingForm.$setPristine()
                    this.$rootScope.$broadcast('update:extension');
                    this.getDrivingData();
                    this.alerts.push({
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                }, (result) => {

                    var timeOut = 5000;
                    var errorMessage = 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>'

                    if (result.data.hasError) {
                        timeOut = 30000;
                        errorMessage = result.data.error[0].errors
                    }

                    this.alerts.push({
                        type: 'danger',
                        msg: errorMessage,
                        dismissOnTimeout: timeOut
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, timeOut);
                })
                .finally(() => {
                    this.Spinner.hide();
                });
        }
    }
}

angular
    .module('app')
    .controller('ExtensionDrivingController', ExtensionDrivingController)
