import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class usExtensionProfile {
    beingAP: string
    spareTime: string
    interest: string
    aupairId: string
    isSubmitted: boolean
}

class ExtensionProfileController {  
    $dirty: boolean = false;
    showMessageText: boolean = false;
    alerts: IAlertConfig[] = [];
    details = new usExtensionProfile();
    displayReadOnly: boolean = false;
    displayOfficeMessage: boolean = false;
    message: string;
    data: any;

    static $inject = ['$rootScope', '$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $scope, private $q, private $timeout, private Auth, private USAuPairs, private Spinner) {

        this.getExtensionProfileData();            
    }

    getExtensionProfileData() 
    {
        this.Spinner.show();

        this.USAuPairs.getExtensionProfile({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = (result.data.aupairExtensionProfile) || new usExtensionProfile();
                this.data = result.data.extensionAppReview;

                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.profileSectionStatus == '3'
                    || this.data.extensionApplicationReview.profileSectionStatus == '5'
                    || this.data.extensionApplicationReview.profileSectionStatus == '6')
                        ) || false;

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.profileSectionStatus == '4') || false;

                this.message = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.profileSectionStatusReason)
            })
            .finally(() => {
                this.Spinner.hide();
            }); 
    }

    submit() {            
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.profileForm;
        if ((form['USExtensionProfile'].$valid)) {
            this.Spinner.show();
            this.details.isSubmitted = true;
            this.save();                
        }             
    }

    save() {
        var promises = [];
        this.alerts = [];

        this.details.aupairId = this.Auth.user.usAuPairId;

        promises.push(this.USAuPairs.saveExtensionProfile({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

        this.$q.all(promises)
            .then(() => {
                this.$scope.profileForm.$setPristine();
                this.$rootScope.$broadcast('update:extension');
                this.getExtensionProfileData();
                this.alerts.push({
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 3000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 3000);
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 3000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 3000);
            })
            .finally(() => {
                this.Spinner.hide();
            });
    }
}
angular
    .module('app')
    .controller('ExtensionProfileController', ExtensionProfileController);