import { SectionController } from '../SectionController';
import { NoteItemId, ItemGroupId } from '../../../models/ItemGroup';
import { ReviewPage } from '../../../services/ReviewService';
import { ReviewItemId } from '../../../models/ReviewItem';



export class PreInterviewController extends SectionController {

    step: number = 0

    contact:any = {}
    passport:any = {}
    driving: any = {}
    availability: any = {}
    program: any = {}
    prematch: any = {}
    visa: any = {}
    childcare: any = {}
    medical: any = {}
    health: any = {}

    isOfficeUser: boolean;
    isApplicant: boolean;
    stickyNodeMode: string;
    page: ReviewPage;
    applicationStatus: string;

    displayMedicalAndHealth: boolean;
    displayHealthAndWellbeing: boolean;

    step1Form
    step2Form
    step3Form
    step4Form

    dceSnapshots;

    static override $inject = [
        '$rootScope',
        '$scope',
        'Auth',
        'ReevaluateSection', 
        'review',
        'applicantId',
        'applicationId',
        'applicationObservable',
        'dialogs',
        '$q',
        '$state',
        'PreInterviewContact',
        'PreInterviewPassport',
        'PreInterviewDriving',
        'PreInterviewAvailability',
        'PreInterviewProgram',
        'PreInterviewPrematch',
        'PreInterviewVisa',
        'PreInterviewChildcare',
        'PreInterviewMedical',
        'PreInterviewHealth'
    ];
    constructor(
        $rootScope: angular.IRootScopeService,
        $scope: angular.IScope,
        Auth,
        ReevaluateSection,
        review,
        applicantId,
        applicationId,
        applicationObservable,
        dialogs,
        private $q: ng.IQService,
        private $state,
        private PreInterviewContact,
        private PreInterviewPassport,
        private PreInterviewDriving,
        private PreInterviewAvailability,
        private PreInterviewProgram,
        private PreInterviewPrematch,
        private PreInterviewVisa,
        private PreInterviewChildcare,
        private PreInterviewMedical,
        private PreInterviewHealth
    ) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.PreInterview, applicationObservable, dialogs);

        this.page = review.getPage(ItemGroupId.PreInterview);

        this.displayMedicalAndHealth = this.page.items.some(i => i.type == ReviewItemId.MyMedicalAndHealth);
        this.displayHealthAndWellbeing = this.page.items.some(i => i.type == ReviewItemId.MyHealthAndWellbeing);

        this.contact = PreInterviewContact.get({ applicantId: applicantId });
        this.passport = PreInterviewPassport.get({ applicationId: applicationId });
        this.driving = PreInterviewDriving.get({ applicationId: applicationId });
        this.availability = PreInterviewAvailability.get({ applicationId: applicationId });
        this.program = PreInterviewProgram.get({ applicationId: applicationId });
        this.prematch = PreInterviewPrematch.get({ applicationId: applicationId });
        this.visa = PreInterviewVisa.get({ applicationId: applicationId });
        this.childcare = PreInterviewChildcare.get({ applicationId: applicationId });

        if (this.displayMedicalAndHealth)
            this.medical = PreInterviewMedical.get({ applicationId: applicationId });

        if (this.displayHealthAndWellbeing)
            this.health = PreInterviewHealth.get({ applicationId: applicationId });

        $q.all([
            this.contact.$promise,
            this.passport.$promise,
            this.driving.$promise,
            this.availability.$promise,
            this.program.$promise,
            this.prematch.$promise,
            this.visa.$promise,
            this.childcare.$promise,
            this.medical.$promise

        ]).then(() => {
            
            this.step = 1;

            if (!this.allCompleted(ReviewItemId.MyContactDetails, ReviewItemId.MyPassport, ReviewItemId.MyDrivingLicense)) {
                this.step = 1;
            } else if (!this.allCompleted(ReviewItemId.MyAvailability, ReviewItemId.MyProgrammeChoice, ReviewItemId.MyPrematchHostFamily, ReviewItemId.MyPreviousUSTravelVisa)) {
                this.step = 2;
            } else if (!this.allCompleted(ReviewItemId.MyChildcareExperience)) {
                this.step = 3;
            } else {
                this.step = 4
            }
        }, () => {
            this.step = -1;
        })

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;
        
        //$scope.$watch(() => this.$scope.childcareFrom.$dirty, () => {
        //    this.$dirty = this.$scope.childcareFrom.$dirty
        //})

        applicationObservable.subscribe(i => {
            if (Auth.user.isAgent || Auth.user.isOfficer || i.interviewerId == Auth.user.roleDetails.contactId) {
                this.stickyNodeMode = 'edit';
            }
            this.applicationStatus = i.status;
        })

    }

    allCompleted(...item: ReviewItemId[]) {
        return item.every(i => this.page.items.some(p => p.type == i && p.isComplete == true));
    }

    saveStep1() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];

        var form = this.step1Form;

        var isFormValid = this.contact.$validation();
        promisses.push(
            this.PreInterviewContact.update(this.contact)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyContactDetails))
        );

        isFormValid = isFormValid && this.passport.$validation();
        promisses.push(
            this.PreInterviewPassport.update(this.passport)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyPassport))
        );

        isFormValid = isFormValid && this.driving.$validation();
        promisses.push(
            this.PreInterviewDriving.update(this.driving)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyDrivingLicense))
        );
        this.onSave(promisses, form, isFormValid);
    }


    saveStep2() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];

        var form = this.step2Form;

        var isFormValid = this.availability.$validation();
        promisses.push(
            this.PreInterviewAvailability.update(this.availability)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyAvailability))
        );

        isFormValid = isFormValid && this.program.$validation();
        promisses.push(
            this.PreInterviewProgram.update(this.program)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyProgrammeChoice))
        );

        isFormValid = isFormValid && this.prematch.$validation();
        promisses.push(
            this.PreInterviewPrematch.update(this.prematch)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyPrematchHostFamily))
        );

        isFormValid = isFormValid && this.visa.$validation();
        promisses.push(
            this.PreInterviewVisa.update(this.visa)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyPreviousUSTravelVisa))
        );
        this.onSave(promisses, form, isFormValid);
    }


    saveStep3() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];

        var form = this.step3Form;

        var isFormValid = this.childcare.$validation();
        promisses.push(
            this.PreInterviewChildcare.update(this.childcare)
                .$promise
                .then(() => this.sectionChanged(ReviewItemId.MyChildcareExperience))
        );
        this.onSave(promisses, form, isFormValid);
    }

    saveStep4() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];

        var form = this.step4Form;

        var isFormValid;

        if (this.displayMedicalAndHealth) {
            isFormValid = this.medical.$validation();
            promisses.push(
                this.PreInterviewMedical.update(this.medical)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.MyMedicalAndHealth))
            );
        }

        if (this.displayHealthAndWellbeing) {
            isFormValid = this.health.$validation();
            promisses.push(
                this.PreInterviewHealth.update(this.health)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.MyHealthAndWellbeing))
            );
        }

        this.onSave(promisses, form, isFormValid);
    }

    onSave(promisses, form, isFormValid) {
        this.$q.all(promisses)
            .then(() => {
                if (isFormValid) {
                    if (this.step < 4) {
                        this.step++;
                    } else {
                        
                        this.dialogs
                            .notify('Thank you for submitting your Pre-Interview Form', 'You will now be taken to the full application. Please continue completing your application and your local interviewer will get in touch with your next steps soon!')
                            .result
                            .then(() => { this.Auth.completePreinterview(); });
                    }
                }
                form.$setPristine();
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('PreInterviewController', PreInterviewController);

