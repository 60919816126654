import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController';

class EditProfileController extends EditApplicationController {

    hobbyUploadConfig: any;
    aboutYou: any;

    //applicationId: number

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', '$uibModal', '$q', 'dialogs', 'applicationId', 'profilePage', 'ApplicationUploader', 'AboutYou', 'applicantHobbies', 'Hobby', 'ApplicationReviewItemsAny'];
    constructor($scope, private $uibModal, private $q, private dialogs, applicationId, profilePage, private ApplicationUploader, private AboutYou, private applicantHobbies, private Hobby, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);

        this.applicationId = applicationId;

        this.aboutYou = profilePage.aboutYou

        $scope.$watch(() => this.$scope.highlightsForm.$dirty, () => {
            this.$dirty = this.$scope.highlightsForm.$dirty
        })

    }

    getHobbyConfig(entry) {
        if (!entry.data) {
            entry.data = {
                uploader: new this.ApplicationUploader(this.applicationId, 10, entry.applicantHobbyId),
                hideDescription: true,
                msg: {
                    upload: 'Upload Certificate',
                    uploaded: 'View Certificate',
                    approved: 'Approved Certificate'

                }
            }
        }
        return entry.data
    }


    addHobby() {
        var applicantId = this.applicantHobbies.applicantId
        var applicationId = this.applicationId
        this.$uibModal.open({
            templateUrl: 'areas/application/highlights/modals/hobbyModal.html',
            controller: 'HobbyModalController as hobbyModalCtrl',
            backdrop: false,
            resolve: {
                applicantId: () => applicantId,
                applicationId: () => applicationId,
                hobby: () => <any>{},
                availableHobbies: () => this.Hobby.availableHobbies({ applicantId: applicantId }).$promise
            }
        }).result.then((data) => {
            this.applicantHobbies.hobbies.push(data)
        })
    }

    deleteHobby(entry) {
        this.dialogs
            .confirm('Please Confirm', 'Are you sure that you wish to remove this entry?')
            .result
            .then(() => {
                this.Hobby
                    .remove({
                        applicantId: this.applicantHobbies.applicantId,
                        applicantHobbyId: entry.applicantHobbyId
                    })
                    .$promise
                    .then(() => {
                        var i = this.applicantHobbies.hobbies.indexOf(entry)
                        if (i > -1) this.applicantHobbies.hobbies.splice(i, 1)
                    }, () => {
                        this.dialogs.error('Error', 'We are sorry but your entry could not be deleted. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>')
                    })
            })
    }

    editHobby(entry) {
        this.$uibModal.open({
            templateUrl: 'areas/application/highlights/modals/hobbyModal.html',
            controller: 'HobbyModalController as hobbyModalCtrl',
            backdrop: false,
            resolve: {
                applicantId: () => entry.applicantId,
                applicationId: () => entry.applicationId,
                hobby: () => angular.copy(entry),
                availableHobbies: () => <any>{}
            }
        }).result.then((data) => {
            var i = this.applicantHobbies.hobbies.indexOf(entry)
            if (i > -1) this.applicantHobbies.hobbies[i] = data
        })
    }


    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.highlightsForm
        if (form['Edit_AboutYou'].$dirty) {
            this.aboutYou.metaform = form['Edit_AboutYou'].$metaformVersion
            promisses.push(
                this.AboutYou.update(this.aboutYou).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditProfileController', EditProfileController)
