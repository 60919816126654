import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController';
import { ApplicationStatus } from '../ApplicationStatus'

class EditPlacementController extends EditApplicationController {
    
    //applicationId: number;
    placementMatchDetails: any = {};
    showPlacementdetailsForEdit: boolean;
    isOnlineOrAwaitingOrToBeMatch: boolean;
    isPlacedOrActive: boolean;
    hasBeenPlaced: boolean;
    isREM: boolean;

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', 'applicationObservable', '$q', 'applicationId', 'ApplicationPlacement', 'placementMatchDetails', 'applicationStatusHistory','ApplicationReviewItemsAny'];
    constructor($scope, applicationObservable, private $q, applicationId, private ApplicationPlacement, placementMatchDetails, applicationStatusHistory, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);
            
        this.applicationId = applicationId;
        this.placementMatchDetails = placementMatchDetails;

        for (let change of applicationStatusHistory) {
            if (change.newStatusCode == ApplicationStatus.Placed) {
                this.hasBeenPlaced = true;
            }
        }

        applicationObservable.subscribe(applicationInstance => {
            this.isREM = (applicationInstance.status === ApplicationStatus.InRematch);
            this.isOnlineOrAwaitingOrToBeMatch = (applicationInstance.status === ApplicationStatus.Registered|| applicationInstance.status === ApplicationStatus.Online || applicationInstance.status === ApplicationStatus.AwaitingAssessment || applicationInstance.status === ApplicationStatus.ToBeMatched);
            this.isPlacedOrActive = (applicationInstance.status === ApplicationStatus.Placed || applicationInstance.status === ApplicationStatus.Active);

            this.showPlacementdetailsForEdit = (this.placementMatchDetails && this.placementMatchDetails.match && this.hasBeenPlaced);
        })



        $scope.$watch(() => this.$scope.placementDetailsForm.$dirty, () => {
            this.$dirty = this.$scope.placementDetailsForm.$dirty
        });
    }        

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.placementDetailsForm;

        var data = {
            applicationId: this.applicationId,
            isPlacementPackReceivedText: this.placementMatchDetails.isPlacementPackReceivedText['true'],
            trackingNumber: this.placementMatchDetails.trackingNumber,
            placementPackSentDate: this.placementMatchDetails.placementPackSentDate
        };

        if (form['EditPlacementPackDetails'].$dirty) {
            this.placementMatchDetails.metaform = form['EditPlacementPackDetails'].$metaformVersion;
            promisses.push(
                this.ApplicationPlacement.savePlacementPackDetails(data).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditPlacementController', EditPlacementController)