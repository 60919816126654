import { BusinessArea } from '../../../services/AuthService';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class PaymentDetailsController {

    financeAdminArea: boolean;

    alerts: IAlertConfig[] = [];

    static $inject = ['$q', 'dialogs', '$window', '$scope', 'Auth', 'contactPaymentDetails', 'contactId', 'ContactPaymentDetails'];
    constructor(private $q, private dialogs, private $window, private $scope, Auth, private contactPaymentDetails, private contactId: number, private ContactPaymentDetails) {

        this.financeAdminArea = Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR);

        if (!this.contactPaymentDetails.contactId) {
            this.contactPaymentDetails.contactId = contactId;
        }
    }

    save() {
        return this.dialogs.confirm('Please Confirm', `Are you sure you want to save these changes?`)
            .result
            .then(() => {
                this.$scope.$broadcast('submitted', true);
                var promisses = [];
                this.alerts = [];

                var form = this.$scope.contactPaymentDetailsForm;

                if (form['ContactPaymentDetails'].$dirty) {
                    this.contactPaymentDetails.metaform = form['ContactPaymentDetails'].$metaformVersion
                    promisses.push(
                        this.ContactPaymentDetails.save(this.contactPaymentDetails).$promise)
                }

                this.$q.all(promisses)
                    .then(() => {
                        form.$setPristine();

                        //TODO move this to parent class and remove saved flag
                        this.alerts.push({ // TODO should I be able to display the same message twice?
                            type: 'success',
                            msg: 'Save complete',
                            dismissOnTimeout: 5000
                        });

                        //doing reload so that commision on left panel appear again
                        this.$window.location.reload();
                    }, () => {

                        //TODO move this to parent class and remove saved flag
                        this.alerts.push({ // TODO should I be able to display the same message twice?
                            type: 'danger',
                            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                            dismissOnTimeout: 5000
                        })
                    })
            });


    }

    closeAlert = function (index) {
        if (index > -1) {
            this.alerts.splice(index, 1);
        };
    }
}