
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************

export class MenuConfig {
    static $inject = ['MenuProvider'];
    constructor(MenuProvider) {

        MenuProvider.AddMenu('anon', 'contact', {
            items: [
                {
                    name: 'Your Interviewer',
                    state: 'app.contact.interviewer'
                },
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                }
            ]
        });

        MenuProvider.AddMenu('applicant', 'contact', {
            items: [
                {
                    name: 'Your Interviewer',
                    state: 'app.contact.interviewer'
                },
                {
                    name: 'Your Agency',
                    state: 'app.contact.agency'
                },
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                }
            ]
        });

        MenuProvider.AddMenu('preInterview', 'contact', {
            items: [
                {
                    name: 'Your Interviewer',
                    state: 'app.contact.interviewer'
                },
                {
                    name: 'Your Agency',
                    state: 'app.contact.agency'
                },
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                }
            ]
        });

        MenuProvider.AddMenu('preloggedin', 'contact', {
            items: [
                {
                    name: 'London Office',
                    state: 'app.contact.london'
                }
            ]
        });
    }
}

angular
    .module('app')
    .config(MenuConfig);