import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class extensionCoordinator { 
    firstName: string
    lastName: string
    officeExtension: string
    email: string
}

export class aupair {
    aupairId: string
}

class ExtendOptionController {
    isApplicationSubmitted: boolean;
    applicationNeedsReview: boolean;
    isApplicationApproved: boolean;
    isHFReferenceApproved: boolean;
    isEducationApproved: boolean;
    isAuPairActiveM: boolean;
    reviewSection: string;
    extensionData: any;
    extensionCoordinator = new extensionCoordinator();
    extensionMonths: number;
    matchBydate: string;
    showMessageText: boolean = false;
    alerts: IAlertConfig[] = [];
    details = new aupair();
    esNoMatch: boolean;
    esMatch: boolean;
    displayOption: boolean;

    static $inject = ['Auth', '$rootScope', '$scope', '$q', '$timeout', 'USAuPairs', 'Spinner'];
    constructor(private Auth, private $rootScope, private $scope, private $q, private $timeout, private USAuPairs, private Spinner) {        
        this.getData();  
    }

    getData() {
        this.Spinner.show();

        this.USAuPairs.canAupairExtend({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.isApplicationSubmitted = result.data.isApplicationSubmitted;
                this.applicationNeedsReview = result.data.applicationNeedsReview;
                this.isApplicationApproved = result.data.isApplicationApproved;
                this.extensionCoordinator = result.data.auPairExtensionCoordinator;
                this.isAuPairActiveM = result.data.isAuPairActiveM;
                this.esNoMatch = result.data.esMatchRequested;
                this.esMatch = result.data.esMatchExist;
                this.displayOption = result.data.apProgramYear === 1;
                this.isHFReferenceApproved = result.data.isHFReferenceApproved;
                this.isEducationApproved = result.data.isEducationApproved;

                if (this.applicationNeedsReview) {
                    this.reviewSection = result.data.applicationNeedsReviewMessage;
                }

                if (this.isAuPairActiveM) {
                    this.extensionMonths = result.data.apExtensionMonths;
                    this.matchBydate = result.data.y2MatchByDate;
                }                
            })
            .finally(() => {
                this.Spinner.hide();
            }); 
    }

    submit() {
        this.$scope.$broadcast('submitted', true);  
       
        this.Spinner.show();
        var promises = [];
        this.alerts = [];

        this.details.aupairId = this.Auth.user.usAuPairId;
        promises.push(this.USAuPairs.saveExtensionSameFamily({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

        this.$q.all(promises)
            .then(() => {
                this.$rootScope.$broadcast('update:extensionEXS');
                this.alerts.push({
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 3000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 3000);
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 3000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 3000);
            })
            .finally(() => {
                // Call to update the page
                this.getData()
                this.Spinner.hide();
            });       
    }
}
angular
    .module('app')
    .controller('ExtendOptionController', ExtendOptionController);