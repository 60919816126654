import { IAlertConfig } from '../../../models/bootstrap/Alert' 

interface IFlightMessageScope extends angular.IScope {
    flightMessageForm: any
}
export class FlightMessageModalController {

    alerts: IAlertConfig[] = [];
    editMode: boolean;
    flightMessageInfo: any;
    $dirty: boolean = false;
        
    static $inject = ['$uibModalInstance', '$scope', 'FlightMessages', 'flightMessageInfo', 'editMode'];
    constructor(private $uibModalInstance, private $scope: IFlightMessageScope, private FlightMessages, flightMessageInfo, editMode) {
        this.editMode = editMode;
        this.flightMessageInfo = flightMessageInfo;
    }

    saveFlightMessage() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.flightMessageForm;
        this.FlightMessages.save(this.flightMessageInfo)
            .$promise
            .then(
                () => {
                    form.$setPristine();
                    this.close();
                },
                () => {

                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
                }
        );
    }

    public closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }

    close() {
        this.$uibModalInstance.close();
    }
}
