import { IAlertConfig } from '../../../../models/bootstrap/Alert' 

export class SystemAnnouncementsModalController {

    alerts: IAlertConfig[] = []
    editMode: boolean;
    $dirty: boolean = false;
        
    minDate: Date = new Date();
    maxDate: Date;
    readOnlyForm: boolean;

    static $inject = ['$uibModalInstance', '$scope', 'SystemAnnouncements', 'announcement'];
    constructor(private $uibModalInstance, private $scope, private SystemAnnouncements, private announcement) {
        this.$scope.hstep = 1;
        this.$scope.mstep = 15;

       

        //this.message.editMode = this.message.messageId > 0;

        $scope.$on('date-time-changed', (event, data) => {
            this.$dirty = true;
        })

        $scope.$watch(() => this.$scope.announcementForm.$dirty, () => {
            this.$dirty = this.$scope.announcementForm.$dirty
        })
    }

    saveAnnouncement() {
        this.$scope.$broadcast('submitted', true);

        let form = this.$scope.announcementForm;
        if (this.$dirty && this.announcement.validFrom && this.announcement.validTo) {

            this.SystemAnnouncements.save(this.announcement)
                .$promise.then(
                    () => {
                        form.$setPristine();
                        this.$uibModalInstance.close();
                    },
                    () => {

                        this.alerts.push({
                            type: 'danger',
                            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                            dismissOnTimeout: 5000
                        });
                    }
                );
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}
