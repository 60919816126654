import { BusinessArea } from '../../../services/AuthService';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 


interface IAgencyPaymentScope extends angular.IScope {
    agencyPaymentDetailsForm: any
}

export class AgencyPaymentController {
    financeAdminArea: boolean;

    alerts: IAlertConfig[] = [];

    static $inject = ['$q', 'dialogs', '$scope', 'Auth', 'paymentDetail', 'agencyId', 'AgencyPayments'];
    constructor(private $q, private dialogs, private $scope: IAgencyPaymentScope, Auth, private paymentDetail, private agencyId: number, private AgencyPayments) {

        this.financeAdminArea = Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR);

        if (!this.paymentDetail.agencyId) {
            this.paymentDetail.agencyId = agencyId;
        }
    }

    save() {
        return this.dialogs.confirm('Please Confirm', `Are you sure you want to save these changes?`)
            .result
            .then(() => {
                this.$scope.$broadcast('submitted', true);
                var promisses = [];
                this.alerts = [];

                var form = this.$scope.agencyPaymentDetailsForm;

                if (form['AgencyPaymentDetails'].$dirty) {
                    this.paymentDetail.metaform = form['AgencyPaymentDetails'].$metaformVersion
                    promisses.push(
                        this.AgencyPayments.save(this.paymentDetail).$promise)
                }

                this.$q.all(promisses)
                    .then(() => {
                        form.$setPristine();

                        //TODO move this to parent class and remove saved flag
                        this.alerts.push({ // TODO should I be able to display the same message twice?
                            type: 'success',
                            msg: 'Save complete',
                            dismissOnTimeout: 5000
                        })
                    }, () => {

                        //TODO move this to parent class and remove saved flag
                        this.alerts.push({ // TODO should I be able to display the same message twice?
                            type: 'danger',
                            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                            dismissOnTimeout: 5000
                        })
                    })
            });

        
    }

    closeAlert = function (index) {
        if (index > -1) {
            this.alerts.splice(index, 1);
        };
    }
}

angular
    .module('app')
    .controller('AgencyPaymentController', AgencyPaymentController)
