import { IAlertConfig } from '../../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../../services/AuthService';

export class AgencyContributionsModalController {

    alerts: IAlertConfig[] = []


    static $inject = ['$scope', '$uibModalInstance', 'AgencyContributions', 'agencyContribution']
    constructor(private $scope, private $uibModalInstance, private AgencyContributions, private agencyContribution) {

    }

    validate() {
        this.$scope.$broadcast('submitted', true)
        return this.agencyContribution.$validation();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel')
    }

    save() {
        if (!this.validate()) {
            return
        }

        this.alerts = [];

        this.AgencyContributions.save(this.agencyContribution)
            .$promise.then(() => {
                this.$uibModalInstance.close()
            },
                () => {

                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    })
                });

    }

    //public closeAlert(index: number) {
    //    this.alerts.splice(index, 1)
    //}
}

angular
    .module('app')
    .controller('AgencyContributionsModalController', AgencyContributionsModalController)