import { IAlertConfig } from '../../../models/bootstrap/Alert'
import moment from 'moment';

export class usFeeType {
    id: number
}

export class usFlight {
    auPairId: string
    ownTravel: boolean = null
    deadlineDateFee: number = 0
    departDate: string
    departDateFee: number = 0
    buyingOwnInsurance: boolean = false
    travelInsuranceFee: number = 0
    departCityCode: string
    departCityFee: number = 0
    destinationCityCode: string
    totalAmount: number = 0
    isProcessed: boolean = false;
}

enum FeeType { 
    DepartureCityFeeHIAK = 5,
    TravelInsuranceFee,
    DepartueDateFee,
    DeadlineLateFee
}

enum TravelInsuranceMessage {
    HaveInsurance = 1,
    NotHaveInsuranceInitially,
    NeedInsuranceAfterDateSelect,
    // AP didnt had insurance initially but also dont need now as she is departing within 3 days of match end date
    DontNeedInsuranceAfterDateSelect    
}

class FlightRequestFormController {    
    showMessageText: boolean = false
    alerts: IAlertConfig[] = []
    flightRequestDeadlineFeeDisplay: number;
    weekendFlightFeeDisplay: number;
    travelInsuranceFeeDisplay: number;
    airportFeeDisplay: number;
    flightRequestDeadlineDate: string;
    processedDate: string;
    public departCityList: Array<any>;
    public destinationCityList: Array<any>;
    public departureDateList: Array<any>;
    displayFlightRequestFeeRow: boolean = true;
    feeTypeDetails = new usFeeType();
    details = new usFlight();
    pageError: boolean = false;

    // Local variables
    matchEndDate: Date;
    displayReadOnly: boolean = false;
    travelInsuranceFlag: number;    
    buttonText: string = 'Submit';
    itineraryRcvd: boolean;
    flightPreviouslyBooked: boolean;
    activeLReadOnly: boolean;
    hideLateFee: boolean;
    displayNonAvailabilityMessage: boolean;

    // if isFromExemptedState = true, that means HF is either from Alaska/hawaii and we wont charge AP any fee for selecting airports from these 2 states 
    isFromExemptedState: boolean;

    static $inject = ['$rootScope', 'applicationId', '$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'FlightLookup', 'ExternalResource', 'Spinner']
    constructor(private $rootScope, public applicationId, private $scope, private $q, private $timeout, private Auth, private USAuPairs, private FlightLookup, public ExternalResource, private Spinner) {
                
        this.initialLoad();
    }
    
    initialLoad() {
        this.Spinner.show();

        this.FlightLookup.departureCity()
            .$promise
            .then((result) => {
                this.departCityList = result;
            });

        this.FlightLookup.destinationCity()
            .$promise
            .then((result) => {
                this.destinationCityList = result;
            });

        this.USAuPairs.getFlightHomeData({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                if (result.data.hasError) {
                    this.pageError = true;
                }

                this.details = (result.data && result.data.auPairFlight) || new usFlight();
                this.matchEndDate = result.data.matchEndDate;
                this.displayReadOnly = this.details.isProcessed;
                this.departureDateList = result.data.departureDate;
                this.isFromExemptedState = result.data.isFromExemptedState;
                this.itineraryRcvd = result.data.obItineraryRcvd == 'Y';
                this.flightPreviouslyBooked = result.data.flightPreviouslyBooked;
                this.hideLateFee = result.data.hideLateFee;
                this.displayNonAvailabilityMessage = result.data.displayNonAvailabilityMessage;

                // this can be N,Y,P
                this.SetTravelInsuranceText(result);                

                // If user has already saved the data once, take care of which insurance message to display
                if (result.data.auPairFlight) {
                    //this.travelInsuranceSetUp();
                    this.details.ownTravel = result.data.auPairFlight.ownTravel;                    
                }

                // If activeL - set departdate and make it readOnly
                if (result.data.isAuPairActiveL && result.data.apProgramYear == 2) {
                    this.details.departDate = result.data.activeLDepartDate;
                    this.activeLReadOnly = true;
                }

                // Get weekend flight extra fee
                this.feeTypeDetails.id = FeeType.DepartueDateFee;
                this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
                    .$promise
                    .then((result1) => {                        
                        this.weekendFlightFeeDisplay = Number(result1.data.latestAmount);                        
                    }, (result1) => {
                        if (result1.data.hasError) {
                            this.pageError = true;
                        }
                    }); 

                if (this.details.isProcessed) {
                    // once user had paid, only display LateFee if user actually paid for it
                    this.displayFlightRequestFeeRow = (this.details.deadlineDateFee > 0);
                    this.calculateTotalDue();
                } else {                    
                    this.feeTypeDetails.id = FeeType.DeadlineLateFee;
                    this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
                        .$promise
                        .then((result2) => {
                            this.flightRequestDeadlineFeeDisplay = Number(result2.data.latestAmount);
                            this.details.deadlineDateFee = Number(result2.data.latestAmount);

                            // If hideLateFee or flightRequestDeadline is still in future, hide requestLateFee section                        
                            if ((this.hideLateFee) || (moment(result.data.flightRequestDeadlineDate).diff(moment(new Date()), 'days') >= 0)) {
                                this.displayFlightRequestFeeRow = false;
                                this.details.deadlineDateFee = 0;
                            }
                            this.calculateTotalDue();
                        }, (result2) => {
                            if (result2.data.hasError) {
                                this.pageError = true;
                            }
                        });
                }
                
                this.flightRequestDeadlineDate = moment(result.data.flightRequestDeadlineDate).format('MMMM D, YYYY'); 
                if (result.data.auPairFlight.modifyDate) {
                    this.processedDate = moment(result.data.auPairFlight.modifyDate).format('MMMM D, YYYY');
                }
                else
                    this.processedDate = moment(result.data.auPairFlight.createDate).format('MMMM D, YYYY');
            });     
        this.Spinner.hide();            
    }

    SetTravelInsuranceText(result) {
        // this can be N,Y,P
        if (result.data.travelMonthInsurance === "N") {
            this.travelInsuranceFlag = TravelInsuranceMessage.NotHaveInsuranceInitially;
        }
        else {
            this.travelInsuranceFlag = TravelInsuranceMessage.HaveInsurance;
        }

        // Get Travel insurance fee
        this.feeTypeDetails.id = FeeType.TravelInsuranceFee;
        this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
            .$promise
            .then((result3) => {
                this.travelInsuranceFeeDisplay = Number(result3.data.latestAmount);
            }, (result3) => {
                if (result3.data.hasError) {
                    this.pageError = true;
                }
            });  

        // Get flying from Hawaii/Alaska fee
        this.feeTypeDetails.id = FeeType.DepartureCityFeeHIAK;
        this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
            .$promise
            .then((result4) => {                
                this.airportFeeDisplay = Number(result4.data.latestAmount);
            }, (result4) => {
                if (result4.data.hasError) {
                    this.pageError = true;
                }
            }); 
    }

    buyOwnInsuranceChange() {
        if (this.details.buyingOwnInsurance) {
            this.details.travelInsuranceFee = 0;  
            this.calculateTotalDue();
        }
        else {
            //this.travelInsuranceSetUp();            
        }        
    }

    travelInsuranceSetUp() {
        // AP need to buy Travel Insurance if 
        // 1) She dont have it already and
        // 2) She chooses departure >= 4 days from her current match date        
        if ((typeof (this.details.departDate) != 'undefined') &&
            ((moment(this.details.departDate).diff(moment(this.matchEndDate), 'days') >= 4) &&
                (this.travelInsuranceFlag != TravelInsuranceMessage.HaveInsurance))) {

            this.travelInsuranceFlag = TravelInsuranceMessage.NeedInsuranceAfterDateSelect;   

            // Get Travel insurance fee
            this.feeTypeDetails.id = FeeType.TravelInsuranceFee;
            this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
                .$promise
                .then((result) => {
                    this.details.travelInsuranceFee = Number(result.data.latestAmount);

                    if (this.details.buyingOwnInsurance) {
                        this.details.travelInsuranceFee = 0;
                    }

                    this.calculateTotalDue();   
                }, (result) => {
                    if (result.data.hasError) {
                        this.pageError = true;
                    }
                }); 
        }
        else {
            if (!this.details.isProcessed && this.travelInsuranceFlag != TravelInsuranceMessage.HaveInsurance) {
                this.details.travelInsuranceFee = 0;
                this.calculateTotalDue();

                // Change travel insurance message
                if (this.travelInsuranceFlag = TravelInsuranceMessage.NotHaveInsuranceInitially) {
                    this.travelInsuranceFlag = TravelInsuranceMessage.DontNeedInsuranceAfterDateSelect;
                }
            }
        }
    }

    departDateSelected() {
        this.Spinner.show();

        // every time user changes the date, clear insurance selection
        this.details.buyingOwnInsurance = false;

        // Check if departure fee need to be shown
        if (typeof (this.details.departDate) === 'undefined')
            this.details.departDateFee = 0;
        else {
            // https://momentjs.com/docs/#/get-set/iso-weekday/
            const selectedDate = moment(this.details.departDate).isoWeekday();

            // 5 is Friday, 6 is Saturday and 7 is Sunday
            var isWeekend = (selectedDate === 5 || selectedDate === 6 || selectedDate === 7);
            if (isWeekend) {     

                // Get weekend flight extra fee
                this.feeTypeDetails.id = FeeType.DepartueDateFee;
                this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
                    .$promise
                    .then((result) => {
                        this.details.departDateFee = Number(result.data.latestAmount);
                        this.calculateTotalDue();
                    }, (result) => {
                        if (result.data.hasError) {
                            this.pageError = true;
                        }
                    });  
            }
            else {
                this.details.departDateFee = 0;
                this.calculateTotalDue();
            }
        }

        //this.travelInsuranceSetUp();

        this.Spinner.hide();               
    }

    ownTravelChange() {
        if (this.details.ownTravel) {
            this.buttonText = 'Submit';
        }
        else {
            this.calculateTotalDue();
        }
    }

    departCitySelected() {
        if (!this.isFromExemptedState) {
            if (this.details.departCityCode === 'ANC' || this.details.departCityCode === 'HNL' || this.details.departCityCode === 'OGG'
                || this.details.departCityCode === 'KOA' || this.details.departCityCode === 'ITO') {
                // Get flying from Hawaii/Alaska fee
                this.feeTypeDetails.id = FeeType.DepartureCityFeeHIAK;
                this.FlightLookup.getFeeTypeAmount({ id: this.feeTypeDetails.id })
                    .$promise
                    .then((result) => {
                        this.details.departCityFee = Number(result.data.latestAmount);
                        this.calculateTotalDue();
                    }, (result) => {
                        if (result.data.hasError) {
                            this.pageError = true;
                        }
                    });                
            }
            else {
                this.details.departCityFee = Number(0);
                this.calculateTotalDue();
            }
        }
    }

    calculateTotalDue() {
        this.details.totalAmount = Number(this.details.deadlineDateFee) +
            Number(this.details.departDateFee) +
            //Number(this.details.travelInsuranceFee) +
            Number(this.details.departCityFee);

        if (this.details.totalAmount > 0) {
            this.buttonText = 'Pay now';
        }
        else
            this.buttonText = 'Submit';
    }

    submit() {
        var form = this.$scope.flightForm;

        if (form.$valid) {
            this.Spinner.show();
            var promises = [];
            this.alerts = [];

            // If user selected 'own Travel', clear object and send only 1 answer
            if (this.details.ownTravel) {
                this.details = new usFlight();
                this.details.ownTravel = true;
            }
            this.details.auPairId = this.Auth.user.usAuPairId;

            promises.push(this.USAuPairs.saveFlightHomeData({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

            this.$q.all(promises)
                .then((result) => {
                    // Send user to payment page if AP wants APIA to book flight and AP needs to pay
                    if (this.details.totalAmount > 0 && !this.details.ownTravel) {
                        this.ExternalResource.flightPaymentURL({
                            applicationId: this.Auth.user.usAuPairId
                        })
                            .$promise
                            .then((result) => {
                                //console.log(result.returnurl);
                                window.location.href = result.returnurl;                                
                            });
                    } else {
                        // If user selected OwnTravel or total is 0, reload page in readOnly mode
                        this.initialLoad();

                        $("html, body").animate({ scrollTop: 0 }, "slow");

                        this.alerts.push({
                            type: 'success',
                            msg: 'Save complete',
                            dismissOnTimeout: 3000
                        });
                        this.showMessageText = true;
                        this.$timeout(() => { this.showMessageText = false; }, 3000);
                        this.Spinner.hide();

                        //$("#flightForm").scroll();
                    }                    
                }, (result) => {
                    var timeOut = 5000;
                    var errorMessage = 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>'

                    if (result.data.data.hasError) {
                        timeOut = 15000;
                        errorMessage = result.data.error[0].errors
                    }

                    this.alerts.push({
                        type: 'danger',
                        msg: errorMessage,
                        dismissOnTimeout: timeOut
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, timeOut);
                    this.Spinner.hide();
                });
        }
    }
}
angular
    .module('app')
    .controller('FlightRequestFormController', FlightRequestFormController);