
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        var data: state<AccessLevelView> = {
            name: 'messages',
            url: '/messages',
            params: {
                recieverUserId: null,
                applicantUserId: null,
                applicationId: null
            },
            resolve: {
                //applicationId: ['$stateParams', 'Auth', function ($stateParams, Auth) {
                //    if ($stateParams.applicationId) {
                //        return $stateParams.applicationId;
                //    } else if (Auth.user && Auth.user.roleDetails && Auth.user.roleDetails.applicationId) {
                //        return Auth.user.roleDetails.applicationId;
                //    }
                //}],
                //applicationInstanceManager: ['ApplicationInstance', 'applicationId', function (ApplicationInstance, applicationId) {
                //    if (applicationId) {
                //        return ApplicationInstance(applicationId);
                //    }
                //}],
                //applicationInstance: ['applicationInstanceManager', function (applicationInstanceManager) {
                //    if (applicationInstanceManager) {
                //        return applicationInstanceManager.instance.$promise;
                //    }
                //}],
                recieverUserId: ['$stateParams', function ($stateParams) {
                    return $stateParams.recieverUserId;
                }],
                applicantUserId: ['$stateParams', function ($stateParams) {
                    return $stateParams.applicantUserId;
                }],
                $title: () => 'Messages'
            },
            //abstract: true,
            views: {
                content: {
                    applicant: {
                        controller: 'UserMessagesController as msgCtrl',
                        template: 'areas/messages/applicant.html'
                    },
                    interviewer: {
                        controller: 'UserMessagesController as msgCtrl',
                        template: 'areas/messages/admin.html'
                    },
                    branch: {
                        controller: 'UserMessagesController as msgCtrl',
                        template: 'areas/messages/admin.html'
                    },
                    agency: {
                        controller: 'UserMessagesController as msgCtrl',
                        template: 'areas/messages/admin.html'
                    }
                }
            }
            //states: [
            //    {
            //        name: 'list',
            //        url: '',
            //        views: {
            //            '': {
            //                applicant: {
            //                    controller: 'MessagesListController',
            //                    template: 'templates/messages/list.html'
            //                },
            //                interviewer: {
            //                    controller: 'MessagesListController',
            //                }
            //            }
            //        }
            //    },
            //    {
            //        name: 'compose',
            //        url: '/compose',
            //        views: {
            //            '': {
            //                applicant: {
            //                    template: 'templates/messages/compose.html'
            //                }
            //            }
            //        }
            //    },
            //    {
            //        name: 'settings',
            //        url: '/settings',
            //        views: {
            //            '': {
            //                applicant: {
            //                    template: 'templates/messages/settings.html'
            //                }
            //            }
            //        }
            //    }
            //]
        };

        RoutingProvider.addState(data, 'app');

    }
}

angular
    .module('app')
    .config(RoutingConfig);