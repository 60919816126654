import _ from 'lodash-es'
import { ConfirmMeeting } from '../action/confirmMeeting/confirmMeeting';
import { archiveApplication } from '../action/archiveApplication/archiveApplication';
import { sendBulkMessage } from '../action/bulkMessanger/sendBulkMessage';
import { AssignToAgency } from '../action/assignToAgency/AssignToAgency';
import { AssignToInterviwer } from '../action/assignToInterviewer/AssignToInterviewer';
import { CommentModalController } from '../../controls/comment/commentModalController';
import { AgencyModalController } from '../../controls/agency/agencyModalController';
import { AgencyCompetitorModalController } from '../agency/AgencyDetails/modals/AgencyCompetitorModalController';
import { ScheduleInterview } from '../action/scheduleInterview/scheduleInterview';
import { BusinessArea } from '../../services/AuthService';
import moment from 'moment';

interface WorkQueueParams {
    page: number;
    pageSize: number;
    orderBy: string;
    descending: boolean;
    filters: {
        applicationStatus?: string[];
        drivingLicence?: string[];
        program?: string[];
        country?: string[];
        gender?: string[];
        agency?: number[];
        interviewer?: number[];
        quickSerch?: string;
        appliedDiscount?: string[];
    };
    workqueueId: number;
    workqueueType?: WorkqueueType;
    columns: {};
    gridCountrySettingsForWQ: IGridCountrySettingsForWQ
}

interface WorkQueueSettings {
    userSettings: any[]
}

enum ProgressItemStatus {
    //unknown = 0,
    incomplete = 1,
    complete = 2,
    needAction = 3  
}

export enum WorkqueueType {
    Applicants = 0,
    Participants = 1,
    All = 2,
    Transaction = 3,
    Agency = 4
}

interface IGridCountrySettingsForWQ {
    listByUserCountry: boolean;
}
interface IDefaultWqSettings {
    officeAppWqName: string;
    officeParWqName: string;
    officeAllWqName: string;
    officeAgyWqName: string;
    officeTranWqName: string;
    nonOfficeWqName: string;
}

interface IExpandCollapse {
    myWorkqueues: boolean;
    workqueues: boolean;
    leftSideBar: boolean;
}

const gridCountrySettingsForWQlocalStorageServiceKey = 'country-grid-settings-wq';
const defaultWQSettingslocalStorageServiceKey = 'default-wq-settings';
const expandCollapseWQlocalStorageServiceKey = 'expand-collapse-wq';

class QueueController {

    params: WorkQueueParams;
    selectedFilterWorkQueue: any;
    allSelected: boolean;
    totalCount: number;
    currentPage: any;
    pageSizes: number[];
    lastPage: number;

    selections: any[];

    selectedGridItems: any[];

    agencies: any[];
    agencieOptions: any[];
    interviewers: any[];
    interviewerOptions: any[];
    genderOptions: any[] = [{ id: 'F', name: 'Female' }, { id: 'M', name: 'Male' }, { id: 'O', name: 'Other' }];
    //competitorStatusOptions: any[] = [{ id: 0, name: 'Pending' }, { id: 1, name: 'In Progress' }, { id: 2, name: 'Completed' }];

    applicationStatusOptions: any[];
    progresStatus: any[];


    isOfficer: boolean;
    isAgent: boolean;
    isBranchManager: boolean;
    isInterviewer: boolean;
    isLoggedInJunoUserFromGermany: boolean;
    isGdpr: boolean;

    filters: any;

    settings: WorkQueueSettings;

    loading: boolean = false;

    gridSettings: any;

    selectedAction: string;
    selectedColumns: string[];

    localStorageServiceKeyPrefix = 'workqueue-columns-'

    disableRefresh: boolean;

    workqueueType: WorkqueueType
    WorkqueueType = WorkqueueType

    workqueue: any;
    gridTitle: string;

    gridCountrySettingsForWQ: IGridCountrySettingsForWQ;
    expandCollapseWQ: IExpandCollapse;
    defaultWqSettings: IDefaultWqSettings;
    selectedDefaultWqName: string;

    gridTimer: any;
    reviewGridTimer: any;
    agencyStatusList: any[];

    static $inject = ['$scope', '$rootScope', '$uibModal', '$state', 'Auth', 'WorkQueue', 'WorkQueueSettings', 'queueName', 'localStorageService', 'dialogs', 'quickSerch', '$timeout', 'columnSelections', 'WorkQueueColumnSelection', 'UserSettings',
        'drivingLicenceOptions', 'programOptions', 'countryOptions', 'visaOptions', 'applicationStatuses', 'interviewersList', 'queues', 'Workqueues', 'Finance', '$window', 'PlacementLetter', 'GridExport', 'SendBulkMessage', 'Interviewer',
        'PushHub', 'displayTypes', 'AgencyContract', 'ITep', '$q', 'AgencyContact', 'AgencyBasicDetails', 'activeApplicationsPromotionList', 'Competitors'];
    constructor(
        private $scope,
        private $rootScope,
        private $uibModal,
        private $state,
        private Auth,
        private WorkQueue,
        private WorkQueueSettings,
        private queueName,
        private localStorageService,
        private dialogs,
        private quickSerch,
        private $timeout,
        private columnSelections,
        private WorkQueueColumnSelection,
        private userSettings,
        private drivingLicenceOptions,
        private programOptions,
        private countryOptions,
        private visaOptions,
        private applicationStatuses,
        interviewersList,
        private queues,
        private Workqueues,
        private Finance,
        private $window,
        private PlacementLetter,
        private GridExport,
        private SendBulkMessage,
        private Interviewer,
        private PushHub,
        private displayTypes,
        private AgencyContract,
        private ITep,
        private $q,
        private AgencyContact,
        private AgencyBasicDetails,
        private activePromotions,
        private Competitors
    ) {
        this.disableRefresh = true;

        this.selectedGridItems = [];
        this.selections = [];

        this.agencyStatusList = [{ 'statusCode': 'A', 'agencyStatus': 'Active' }, { 'statusCode': 'I', 'agencyStatus': 'Inactive' }, { 'statusCode': 'P', 'agencyStatus': 'Prospective' }];

        this.isOfficer = this.Auth.user.isOfficer;
        this.isAgent = this.Auth.user.isAgent;
        this.isBranchManager = this.Auth.user.isBranchManager;
        this.isInterviewer = this.Auth.user.isInterviewer;
        this.isGdpr = this.Auth.user.gdpr;

        this.getSetGridCountrySettings();
        this.getSetExpandCollapseWQ();
            
        $scope.$on("$destroy", () => {
            if (this.gridTimer) {
                this.$timeout.cancel(this.gridTimer);
            }
            if (this.reviewGridTimer) {
                this.$timeout.cancel(this.reviewGridTimer);
            }
        });

        //TODO : this need to be done in a different way!!!! that's a temp fix !!!
        this.isLoggedInJunoUserFromGermany = (Auth.user.isOfficer && _.endsWith(Auth.user.userName, '@aifs.de'));

        this.params = {
            page: 0,
            pageSize: 100,
            orderBy: '',
            descending: true,
            filters: <any>{
                applicationStatus: [],
                drivingLicence: [],
                program: [],
                gender: [],
                interviewer: [],
                country: [],
                agency: [],
                appliedDiscount: []
            },
            workqueueId: 0,
            columns: {},
            gridCountrySettingsForWQ: this.gridCountrySettingsForWQ
        };
        this.workqueueType = this.isOfficer ? WorkqueueType.Participants : WorkqueueType.Applicants;
        if (displayTypes.indexOf(this.workqueueType) === -1) {
            this.workqueueType = displayTypes[0];
        }

        if (this.isLoggedInJunoUserFromGermany) {
            _.merge(this.params, { filters: { agency: [1210] } });
        }

        $scope.$watch(() => this.selectedGridItems.length, (value, oldValue) => {
            if (value > 0) {
                var url = this.getUrl();
                if (url) {
                    window.open(url, '_blank');
                }
                this.selectedGridItems = [];
                this.selectedAction = '';
            }

        }, true);

        // Provide column definitions for grid
        $scope.$watchCollection(() => this.params.columns, () => {
            let selCol = [];
            if (this.params.columns) {
                angular.forEach(this.params.columns, (value, key) => {
                    if (value) {
                        selCol.push(key);
                    }
                });

            }
            selCol = selCol.sort((a, b) => this.getColumnPriority(a) - this.getColumnPriority(b));
            selCol.unshift('check')

            this.selectedColumns = selCol;
        });

        $scope.$watch(() => this.params.filters.applicationStatus, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.drivingLicence, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.agency, (newval, oldval) => {
            if (newval != oldval) {
                this.updateInterviewerOptions();
                this.refresh();
            }
        }, true);

        $scope.$watch(() => this.params.filters.country, (newval, oldval) => {
            if (newval != oldval) {
                this.updateAgencieOptions();
                this.updateInterviewerOptions();
                this.refresh();
            }
        }, true);

        $scope.$watch(() => this.params.filters.program, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.interviewer, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.gender, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$watch(() => this.params.filters.appliedDiscount, (newval, oldval) => {
            if (newval != oldval)
                this.refresh();
        }, true);

        $scope.$on('queue:refresh', () => {
            this.refresh();
        })

        this.getSetDefaultWqSettings();

        //
        // Lookups
        //
        this.applicationStatusListFilter();
        this.fillInterviewersAndAgencies(interviewersList);

        this.progresStatus = [
            { id: 2, name: ProgressItemStatus.complete, displayText: 'Complete' },
            { id: 1, name: ProgressItemStatus.incomplete, displayText: 'Incomplete' },
            { id: 3, name: ProgressItemStatus.needAction, displayText: 'Outstanding Notes' }
        ];
        this.pageSizes = [100, 500, 1000];

        this.getSettings();

 
        if (queueName === '') {
            if (this.isOfficer) {
                if (this.workqueueType == 0) {
                    queueName = this.defaultWqSettings.officeAppWqName;
                } else if (this.workqueueType == 1) {
                    queueName = this.defaultWqSettings.officeParWqName;
                } else if (this.workqueueType == 2) {
                    queueName = this.defaultWqSettings.officeAllWqName;
                } else if (this.workqueueType == 3) {
                    queueName = this.defaultWqSettings.officeTranWqName;
                } else if (this.workqueueType == 4) {
                    queueName = this.defaultWqSettings.officeAgyWqName;
                }

            } else {
                queueName = this.defaultWqSettings.nonOfficeWqName;
            }
            }

        var queue = this.queues.find(q => q.name == queueName);
        this.loadQueue(queue, true);


        if (this.isOfficer) {
            this.PushHub.openApplicationListForOffice(this.Auth.user.userId);

            this.startGridDotUpdateTimer();
        }
    }

    getUrl() {
        if (this.workqueue) {            

            if (this.workqueue.workqueueType == WorkqueueType.Transaction) {
                if (this.selectedGridItems[0].agencyId) {
                    return this.$state.href('^.agencyInformation.agencyPayments', { agencyId: this.selectedGridItems[0].agencyId });
                }
                else if (this.selectedGridItems[0].interviewerId) {
                    return this.$state.href('^.contactInformation.contactPayments', { contactId: this.selectedGridItems[0].interviewerId });
                }
            } else if (this.workqueue.workqueueType == WorkqueueType.Agency) {
                if (this.workqueue.workqueueId === 49) { // competitor workqueue = 49
                    return this.$state.href('^.agencyInformation.agencyCompetitors', { agencyId: this.selectedGridItems[0].agencyId, competitorId: this.selectedGridItems[0].competitorId });
                }

                return this.$state.href('^.agencyInformation', { agencyId: this.selectedGridItems[0].agencyId });
            }
        }
            

        switch (this.selectedAction) {
            case 'reference':
                return this.$state.href('^.applications.inst.references', { applicationId: this.selectedGridItems[0].applicationId });
            case 'interviewReport':
                return this.$state.href('^.applications.interviewReport', { applicationId: this.selectedGridItems[0].applicationId });
            default:
                return this.$state.href('^.applications.inst.highlights', { applicationId: this.selectedGridItems[0].applicationId });
        }
    }
        
    isAnySelected = function () {
        return this.selections.length !== 0
    }

    isCorrectStatus = function () {
        return this.selections.every(x => x.status === 'REG' || x.status === 'OLA')
    }

    isSameCountry = function () {
        return this.selections.length !== 0 && this.selections.every(x => x.countryCode === this.selections[0].countryCode)
    }

    validateAssignApplication = function () {
        if (!this.isAnySelected()) {
            this.dialogs
                .notify('Assign Application', 'You need to select an applicant first')
                .result;
            return false;
        }
        else if (!this.isCorrectStatus()) {
            this.dialogs
                .notify('Assign Application', 'Applicants can only be assigned at REG and OLA status')
                .result;
            return false;
        }
        else if (!this.isSameCountry()) {
            this.dialogs
                .notify('Assign Application', 'You have to select applicants from the same country')
                .result;
            return false;
        }
        return true;
    }

    applicationListRefresh(){
        if (this.currentPage) {
            var applicationIdsForCheckingReview = this.currentPage.filter(a => a.isStartedReviewingByOther || a.isAlreadyReviewingByMe).map(({ applicationId }) => applicationId);
            if (applicationIdsForCheckingReview.length > 0) {
                this.PushHub.applicationListRemovedFromReview(applicationIdsForCheckingReview);
            }
        }
        this.startGridDotUpdateTimer();            
    }
    startGridDotUpdateTimer() {
        if (this.reviewGridTimer) {
            this.$timeout.cancel(this.reviewGridTimer);
        }

        this.reviewGridTimer = this.$timeout(() => {
            this.applicationListRefresh();
        }, 5000, true);
    }

    changeWorkqueue(queue) {
        this.loadQueue(queue, true);
    }

    getSetGridCountrySettings() {
        this.gridCountrySettingsForWQ = this.localStorageService.get(gridCountrySettingsForWQlocalStorageServiceKey);

        if (!this.gridCountrySettingsForWQ) {
            this.gridCountrySettingsForWQ = { listByUserCountry: false};
            this.localStorageService.set(gridCountrySettingsForWQlocalStorageServiceKey, this.gridCountrySettingsForWQ);
        }
    }

    getSetDefaultWqSettings() {
        this.defaultWqSettings = this.localStorageService.get(defaultWQSettingslocalStorageServiceKey);

        if (!this.defaultWqSettings) {
            this.defaultWqSettings = { officeAppWqName: 'AllApplicants', officeParWqName: 'ApplicationsAwaitingAssessment', officeAllWqName: 'AllApplications', officeAgyWqName: 'Agency', officeTranWqName: 'ManualPayments', nonOfficeWqName: 'AllApplicants_OLA' };
            this.localStorageService.set(defaultWQSettingslocalStorageServiceKey, this.defaultWqSettings);
        }

        if (this.isOfficer) {
            if (this.workqueueType == 0) {
                this.selectedDefaultWqName = this.defaultWqSettings.officeAppWqName;
            } else if (this.workqueueType == 1) {
                this.selectedDefaultWqName = this.defaultWqSettings.officeParWqName;
            } else if (this.workqueueType == 2) {
                this.selectedDefaultWqName = this.defaultWqSettings.officeAllWqName;
            } else if (this.workqueueType == 3) {
                this.selectedDefaultWqName = this.defaultWqSettings.officeTranWqName;
            } else if (this.workqueueType == 4) {
                this.selectedDefaultWqName = this.defaultWqSettings.officeAgyWqName;
            }
        } else {
            this.selectedDefaultWqName = this.defaultWqSettings.nonOfficeWqName;
        }
    }

    getSetExpandCollapseWQ() {
        this.expandCollapseWQ = this.localStorageService.get(expandCollapseWQlocalStorageServiceKey);

        if (!this.expandCollapseWQ) {
            this.expandCollapseWQ = { myWorkqueues: false, workqueues: false, leftSideBar: true };
            this.localStorageService.set(expandCollapseWQlocalStorageServiceKey, this.expandCollapseWQ);
        }
    }

    expandCollapseWorkqueue(collapseFor) {
        if (collapseFor == 1) {
            this.expandCollapseWQ.workqueues = !this.expandCollapseWQ.workqueues;
        }
        else if (collapseFor == 2){
            this.expandCollapseWQ.myWorkqueues = !this.expandCollapseWQ.myWorkqueues;
        }
        else if (collapseFor == 3){
            this.expandCollapseWQ.leftSideBar = !this.expandCollapseWQ.leftSideBar;
        }
            
        this.localStorageService.set(expandCollapseWQlocalStorageServiceKey, this.expandCollapseWQ);                
    }

        

    changePageSize() {
        this.lastPage = Math.floor(this.totalCount / this.params.pageSize);
        this.refresh();
    }

    clearQuickSerch() {
        if (this.isOfficer) {
            this.$state.go('app.workQueue', { queueName: 'ApplicationsAwaitingAssessment', quickSerch: '' });
        } else {
            this.$state.go('app.workQueue', { queueName: 'AllApplicants_OLA', quickSerch: '' });
        }
    }

    fillInterviewersAndAgencies(data: any[]) {
        this.interviewers =
            _.sortBy(
                data.map((i: any) =>
                    <any>{
                        id: i.id,
                        name: `${i.firstName} ${i.lastName}`,
                        country: i.countryCode,
                        agencyId: i.agencyId,
                        isActive: i.isActive
                    }),
                (i) => i.name);

        this.agencies =
            _.sortBy(
                _.uniqBy(
                    data.map((i: any) => 
                        <any>{
                            id: i.agencyId,
                            name: i.agencyName,
                            country: i.countryCode,
                            isActive: i.isActive
                        }),
                    (a) => a.id),
                (a) => a.name);

        this.updateAgencieOptions();
        this.updateInterviewerOptions();
    }

    getColumnPriority(name: string) {
        if (this.workqueue) {
            for (let col of this.workqueue.columns) {
                if (col.name === name) {
                    return col.order;
                }
            }
        }
        return 9999;
    }

    applicationStatusListFilter() {
        if (this.workqueueType == WorkqueueType.Applicants) {
            this.applicationStatusOptions = this.applicationStatuses && this.applicationStatuses.filter((v) => v.applicantSearch);
        } else if (this.workqueueType == WorkqueueType.Participants) {
            this.applicationStatusOptions = this.applicationStatuses && this.applicationStatuses.filter((v) => v.participantSearch);
        } else if (this.workqueueType == WorkqueueType.All) {
            this.applicationStatusOptions = this.applicationStatuses
        }
    }
        
    selectedType() {
        this.loadQueue();
        this.applicationStatusListFilter();
        this.getSetDefaultWqSettings();
    }

    getApplications(currentPage, pageItems, filterBy, filterByFields, orderBy, descending) {
        this.params.page = currentPage;
        this.refresh();
    }

    refresh() {
        //check if refresh is disabled
        if (this.disableRefresh) {
            return;
        }

        this.loading = true;

        // apply special filters
        if (this.isLoggedInJunoUserFromGermany) {
            _.merge(this.params, {filters: {agency: [1210]}}); 
        }
        this.params.filters.quickSerch = this.quickSerch;

        this.params.gridCountrySettingsForWQ = this.gridCountrySettingsForWQ;
        this.localStorageService.set(gridCountrySettingsForWQlocalStorageServiceKey, this.gridCountrySettingsForWQ);

        this.localStorageService.set(defaultWQSettingslocalStorageServiceKey, this.defaultWqSettings);
            
        new this.WorkQueue.all(this.params, (result) => {
            this.totalCount = result.totalCount;
            this.lastPage = Math.floor(this.totalCount / this.params.pageSize);
            this.currentPage = result.result;
            this.selections = [];

            this.loading = false;
            this.$scope.$broadcast("grid_refresh");
            this.$rootScope.$broadcast('refreshFixedColumns');
        }, () => {
            this.loading = false;
        });
    }

    getGridTemplate() {
        var rootPath = 'areas/queue/grid/';
        return `${rootPath}Default.html`;
    }

    toggleItem(item) {
        item.checked = !item.checked;
        this.itemChanged(item);
    }

    itemChanged(item) {
        if (item.checked) {
            let serach = {};
            serach[this.workqueue.dataSourceKey] = item[this.workqueue.dataSourceKey]; 
            if (!_.includes(this.selections, serach)) {
                this.selections.push(item);

                for (let entry of this.currentPage) {
                    if (!entry.checked) {
                        return
                    }
                }
                this.allSelected = true;
            }
        } else {
            this.allSelected = false;
            _.remove(this.selections, (value) => value[this.workqueue.dataSourceKey] === item[this.workqueue.dataSourceKey]);
        }
    }

    toggleAllSelected() {
        this.allSelected = !this.allSelected;
        this.allSelectedChange();
    }

    allSelectedChange() {

        this.selections = [];
        if (this.allSelected) {
            for (let entry of this.currentPage) {
                entry.checked = true;
                this.selections.push(entry);
            }
        } else {
            for (let entry of this.currentPage) {
                entry.checked = false;
            }
        }
    }


    getProgressItemClass(state: number) {
        return ProgressItemStatus[state || 0];
    }

    hasSomeSelectedInFilter(filter) {
        return (filter) ? true : false;
    }


    getSettings() {
            
        this.WorkQueueSettings.query({
            userId: this.Auth.user.userId
        }, (data) => {
            //load all workqueue
            this.settings = data;
        });
    }

    loadSettings(setting, dontOverrideColumns: Boolean = false) {
        if (setting) {

            this.selectedFilterWorkQueue = setting;
            if (setting.name.indexOf('__wq-') !== 0) {
                this.gridTitle = setting.name;
            }


            // remember to disable refresh and enable it in next digest using timeout
            // otherwise browser will call serveral times 
            this.disableRefresh = true;

            var columns = this.params.columns;
            angular.copy(setting.settings, this.params);
            if (dontOverrideColumns) {
                this.params.columns = columns;
            }
            this.params.page = 0;                

            this.resetGridTimer();
        }
    }
        
    deleteSettings(setting) {
        this.dialogs
            .confirm('Please Confirm', `Are you sure that you wish to delete "${setting.name}" filter?`)
            .result
            .then(() => {
                this.WorkQueueSettings.remove({
                    userId: this.Auth.user.userId,
                    workQueueId: setting.id
                }, () => {
                    this.getSettings();
                    if (this.selectedFilterWorkQueue && this.selectedFilterWorkQueue.id === setting.id) {
                        this.loadQueue();
                    }
                });
            });
    }

    refreshByCountryFromSettings() {
        this.gridCountrySettingsForWQ.listByUserCountry = !this.gridCountrySettingsForWQ.listByUserCountry;

        this.refresh();
    }

    refreshByChangingDefaultWq(selectedWqName) {

        if (this.isOfficer) {
            if (this.workqueueType == 0) {
                this.defaultWqSettings.officeAppWqName = selectedWqName;
                this.selectedDefaultWqName = this.defaultWqSettings.officeAppWqName;
            } else if (this.workqueueType == 1) {
                this.defaultWqSettings.officeParWqName = selectedWqName;
                this.selectedDefaultWqName = this.defaultWqSettings.officeParWqName;
            } else if (this.workqueueType == 2) {
                this.defaultWqSettings.officeAllWqName = selectedWqName;
                this.selectedDefaultWqName = this.defaultWqSettings.officeAllWqName;
            } else if (this.workqueueType == 3) {
                this.defaultWqSettings.officeTranWqName = selectedWqName;
                this.selectedDefaultWqName = this.defaultWqSettings.officeTranWqName;
            } else if (this.workqueueType == 4) {
                this.defaultWqSettings.officeAgyWqName = selectedWqName;
                this.selectedDefaultWqName = this.defaultWqSettings.officeAgyWqName;
            }
        }else {
            this.defaultWqSettings.nonOfficeWqName = selectedWqName;
            this.selectedDefaultWqName = this.defaultWqSettings.nonOfficeWqName;
        }

            
        this.refresh();
    }

    saveSettings() {
        //TODO get user id 
        // add modal windows and ask for name
        this.params.workqueueType = this.workqueueType;

        this.$uibModal.open({
            templateUrl: 'areas/queue/SaveFilterModal.html',
            controller: 'SaveFilterController as ctrl',
            backdrop: false,
            resolve: {
                settings: () => this.params,
                rootScope: this.$scope
            }
        }).result.then((data) => {
            this.selectedFilterWorkQueue = data;
            this.gridTitle = data.name;

            this.getSettings();
        });
    }


    saveWorkqueueSettings() {
        //TODO get user id 
        // add modal windows and ask for name
        this.params.workqueueType = this.workqueueType;

        let filterSettings = {
            userId: this.Auth.user.userId,
            settings: this.params,
            name: `__wq-${this.params.workqueueId}`
        }

        this.dialogs.confirm(`Please confirm`, `Are you sure that you want to save default settings for Work Queue`)
            .result
            .then(() => this.WorkQueueSettings.save(filterSettings).$promise)
            .then((data) => {
                this.selectedFilterWorkQueue = data;

                this.getSettings();
            });

        //this.$uibModal.open({
        //    templateUrl: 'areas/queue/SaveFilterModal.html',
        //    controller: 'SaveFilterController as ctrl',
        //    backdrop: false,
        //    resolve: {
        //        settings: () => this.params,
        //        rootScope: this.$scope
        //    }
        //}).result.then((data) => {
        //    this.selectedFilterWorkQueue = data;
        //    this.gridTitle = data.name;

        //    this.getSettings();
        //});
    }

    applyFilters() {
        this.refresh();
    }

    updateAgencieOptions() {
        if (this.params.filters.country && this.params.filters.country.length > 0) {
            this.agencieOptions = _.filter(this.agencies, (a) => _.includes(this.params.filters.country, a.country));
        } else {
            this.agencieOptions = this.agencies.slice();
        }

        //if (this.agencieOptions.length > 0) {
        //    this.agencieOptions.unshift({ id: 0, name: 'None' });
        //} else {
        //    this.agencieOptions.unshift({ name: '----- None Available ------' });
        //}
    }

    updateInterviewerOptions() {
        //it's important to check agency first and then country 
        if (this.params.filters.agency && this.params.filters.agency.length > 0) {
            this.interviewerOptions = _.filter(this.interviewers, (i) => _.includes(this.params.filters.agency, i.agencyId) && i.isActive == true);
        } else if (this.params.filters.country && this.params.filters.country.length > 0) {
            this.interviewerOptions = _.filter(this.interviewers, (i) => _.includes(this.params.filters.country, i.country) && i.isActive == true);
        } else {
            this.interviewerOptions = this.interviewers.slice();
        }
            

        if (this.interviewerOptions.length > 0) {
            this.interviewerOptions.unshift({ id: 0, name: 'None' });
        } else {
            this.interviewers.unshift({ name: '----- None Available ------' });
        }
    }

    clearFilter() {
        this.loadQueue();
    }
        
    setAsDefaultFilter() {   
        if (this.selectedFilterWorkQueue) {
            this.WorkQueueSettings.setAsDefault({
                userId: this.Auth.user.userId,
                workQueueId: this.selectedFilterWorkQueue.id
            }, () => {
                this.getSettings();
            });
        }
        else {
            this.dialogs.notify('Set default', 'You should select filter workqueue to set default.')
        }
    }

    openFindAuPairModal() {
        this.$uibModal.open({
            templateUrl: 'areas/queue/FindAuPairModal.html',
            controller: 'FindAuPairModalController as ctrl',
            backdrop: false,
            windowClass: 'find-aupair-modal',
            resolve: {
                settings: () => angular.copy(this.params),
                agencies: () => this.agencies,
                interviewers: () => this.interviewers,
                applicationStatuses: () => this.applicationStatuses,
                programOptions: () => this.programOptions,
                countryOptions: () => this.countryOptions,
                drivingLicenceOptions: () => this.drivingLicenceOptions,
                visaOptions: () => this.visaOptions

            }
        }).result.then((data) => {
            this.disableRefresh = true;

            angular.copy(data, this.params);

            this.resetGridTimer();
                
        });
    }
    resetGridTimer() {
            
        if (this.gridTimer) {
            this.$timeout.cancel(this.gridTimer);
        }

        this.gridTimer = this.$timeout(() => {
            this.disableRefresh = false;
            this.refresh();
        }, 0, false);
    }

    openColumnSettingsModal() {
        this.$uibModal.open({
            templateUrl: 'areas/queue/ColumnsSettingsModal.html',
            controller: 'ColumnsSettingsModalController as ctrl',
            backdrop: false,
            //size: 'lg',
            resolve: {
                columns: () => this.workqueue.columns,
                columnsSettings: () => angular.copy(this.params.columns)
            }
        }).result.then((data) => {
            angular.copy(data, this.params.columns);

            var key = `${this.localStorageServiceKeyPrefix}${WorkqueueType[this.workqueueType]}`;
            this.columnSelections[key] = this.params.columns;
            this.WorkQueueColumnSelection.save({
                name: key,
                userId: this.Auth.user.userId,
                UserSelection: this.params.columns
            })

            this.refresh();
        });
    }

    loadQueue(queue?, updateUrlAfterChangingWq: boolean = false) {

        if (!queue) {
            queue = this.queues.find(q => q.workqueueType == this.workqueueType);
        }

        this.Workqueues.get({ workqueueId: queue.workqueueId }, (workqueue) => {

            this.disableRefresh = true;
            this.params.workqueueId = workqueue.workqueueId;

            let wqName = `__wq-${queue.workqueueId}`;
            let settings = this.settings.userSettings.find((i) => i.name === wqName);
            if (settings) {
                this.params.columns = angular.copy(settings.settings.columns);
            } else {
                this.params.columns = workqueue
                    .columns
                    .filter((x) => x.showByDefault)
                    .reduce((result, x) => {
                        result[x.name] = true;
                        return result;
                    }, {});
            }

            this.params.filters = workqueue.defaultFilters;
            this.params.page = 0;
            this.params.orderBy = workqueue.defaultOrderBy
            this.params.descending = workqueue.defaultDescending

            this.filters = _.reduce(workqueue.columns, (res, c: any) => {
                res[c.name] = true;
                return res;
            }, {});

            this.workqueue = workqueue;
            this.gridTitle = workqueue.displayName;

            this.selectedFilterWorkQueue = undefined;

            if (updateUrlAfterChangingWq) {
                this.$state.params['queueName'] = workqueue.name;
                this.$state.go(this.$state.current.name, { queueName: workqueue.name });
            }

            this.resetGridTimer();
        })
    }
        
        

    callButton(method) {

        switch (method.callMethod) {

            case 'assignToInterviewer': {
                if (!this.validateAssignApplication()) return;
                    
                let interviewerId = null;
                if (this.selections.length !== 0 && this.selections.every(x => x.interviewerId === this.selections[0].interviewerId)) {
                    interviewerId = this.selections[0].interviewerId;
                }

                let applicationIds = this.selections.map(selection => selection.applicationId);
                let action = new AssignToInterviwer(this.$uibModal, this.$scope, this.Interviewer, applicationIds, this.Auth.user.roleDetails.agencyId, interviewerId);
                return action
                    .execute()
                    .then(() => {
                        this.refresh();
                    })
            }

            case 'assignToAgency': {
                if (!this.validateAssignApplication()) return;

                let applicationIds = this.selections.map(selection => selection.applicationId);
                let agencyId = null;
                let interviewerId = null;
                if (this.selections.length !== 0 && this.selections.every(x => x.agencyId === this.selections[0].agencyId)) {
                    agencyId = this.selections[0].agencyId;
                    if (this.selections.every(x => x.interviewerId === this.selections[0].interviewerId)) {
                        interviewerId = this.selections[0].interviewerId;
                    }
                }
                let action = new AssignToAgency(this.$uibModal, this.$scope, this.Interviewer, applicationIds, agencyId, interviewerId);
            
                return action
                    .execute()
                    .then(() => {
                        this.refresh();
                    })
            }
                    
            case 'bulkMessage': {
                let action = new sendBulkMessage(this.$uibModal, this.SendBulkMessage, this.Auth, this.selections);
                return action
                    .execute()
                    .then(() => {
                        this.refresh();
                    });
            }

            case 'archiveApplication': {
                let action = new archiveApplication(this.$uibModal, this.Auth, this.selections)
                return action
                    .execute()
                    .then(() => {
                        this.$scope.$emit('queue:refresh');
                    });
            }

            case 'export': {
                let bulkExportAllow = this.Auth.isInBusinessArea(BusinessArea.BULK_EXPORT)
                if (bulkExportAllow) {
                    this.GridExport.getAsCSV(this.params, 'export.csv')
                } else {
                    this.GridExport.saveAsCSV('.applicationsTable', 'export.csv', this.selections.length != 0)
                }
                return;
            }

            case 'printPLCLetter': {
                return this.printPLCLetter();
            }

            case 'approvePayment': {
                this.loading = true;
                return this.Finance.approveTransaction({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) })
                    .$promise
                    .then(() => this.refresh(), () => this.displayError())
                    .finally(() => this.loading = false);
            }

            case 'deletePayment': {
                return this.dialogs.confirm(`Remove payment`, `Are you sure that you want to remove this payment?`)
                    .result
                    .then(() => this.loading = true)
                    .then(() => this.Finance.removeUnapproveTransaction({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) }).$promise)
                    .then(() => this.refresh())
                    .finally(() => this.loading = false);
            }

            case 'approveManualPayment': {
                return this.dialogs.confirm(`Approve payment`, `Are you sure that you want to approve this payment?`)
                    .result
                    .then(() => this.loading = true)
                    .then(() => this.Finance.approveManualPayment({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) }).$promise)
                    .then(() => this.refresh(), () => this.displayError())
                    .finally(() => this.loading = false);
            }

            case 'deleteManualPayment': {
                return this.dialogs.confirm(`Remove payment`, `Are you sure that you want to remove this payment?`)
                    .result
                    .then(() => this.loading = true)
                    .then(() => this.Finance.removeManualPayment({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) }).$promise)
                    .then(() => this.refresh())
                    .finally(() => this.loading = false);
            }

            case 'approveRefund': {
                this.loading = true;
                return this.Finance.approveRefund({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) })
                    .$promise
                    .then(() => this.refresh(), () => this.displayError())
                    .finally(() => this.loading = false);
            }

            case 'deleteRefund': {
                return this.dialogs.confirm(`Remove refund`, `Are you sure that you want to remove this refund?`)
                    .result
                    .then(() => this.loading = true)
                    .then(() => this.Finance.removeUnapproveRefund({ accountTransactionIDs: _.map(this.selections, (s) => s.accountTransactionId) }).$promise)
                    .then(() => this.refresh())
                    .finally(() => this.loading = false);
            }

            case 'cancelInvoice': {
                return this.dialogs.confirm(`Cancel invoice`, `Are you sure that you want to cancel these invoices?`)
                    .result
                    .then(() => this.loading = true)
                    .then(() => this.Finance.cancelInvoices({ invoiceIds: _.map(this.selections, (s) => s.invoiceId) }).$promise)
                    .then(() => this.refresh(), (response) => {
                        if (typeof response.data === 'string' || response.data instanceof String) {
                            this.dialogs.error('Error', response.data);
                        }
                    })
                    .finally(() => this.loading = false);
            }

            case 'generateContracts': {

                var agencyIds = this.selections.filter(x => x.shouldBeExtended).map(a => a.agencyId);
                if (agencyIds.length == 0) {
                    this.dialogs.error('Generate contracts', 'None of selected contracts can be extended.');
                    return;
                }
                if (agencyIds.length != this.selections.length) {
                    return this.dialogs.confirm('Generate contracts', `Only ${agencyIds.length} of ${this.selections.length} selected contracts can be extended. Do you want to perform action?`)
                        .result
                        .then(() => this.generateContracts(agencyIds));
                }

                return this.generateContracts(agencyIds);
            }

            case 'contractNotRequired': {
                this.loading = true
                let promises = [];
                for (let x of this.selections) {
                    promises.push(this.AgencyBasicDetails
                        .get({ agencyId: x.agencyId })
                        .$promise
                        .then(a => {
                            a.contractRequired = false;
                            return a.$save();
                        }));
                }

                return this.$q.all(promises)
                    .catch(() => {
                        this.dialogs.error('Contract Not Required', 'There was some error while processing your action. Please try again in 5 minutes.');
                        return;
                    })
                    .then(() => this.refresh())
                    .finally(() => this.loading = false);
            }

            case 'approveCertificate': {
                return this.Workqueues.approveCertificate((result) => {
                    this.refresh();
                    this.dialogs.notify('Action processed', 'Your action on the Work Queue has been processed.');
                }, () => this.displayError());
            }

            case 'confirmMeeting': {
                let applicationIds = this.selections.map(selection => selection.applicationId);
                return this.confirmMeeting(applicationIds)
                    .then(() => this.loading = true)
                    .then(() => this.refresh(), () => this.displayError())
                    .finally(() => this.loading = false);;
            }

            case 'iTepSatisfactory': {
                let promises = [];
                for (let x of this.selections) {
                promises.push(this.ITep
                    .satisfactory({ applicationId: x.applicationId, id: x.iTEPTestId })
                    .$promise);
                }
                return this.$q.all(promises).then(() => {
                    return this.refresh();
                });
            }

            case 'iTepUnsatisfactory': {
                
                let promises = [];
                for (let x of this.selections) {
                    promises.push(this.ITep
                        .unsatisfactory({ applicationId: x.applicationId, id: x.iTEPTestId })
                        .$promise);
                }
                return this.$q.all(promises).then(() => {
                    return this.refresh();
                });
            }

            case 'refresh': {
                return this.refresh();
            }

            case 'createAgency': {
                return this.$uibModal
                    .open({
                        templateUrl: 'controls/agency/agencyModal.html',
                        controller: AgencyModalController,
                        controllerAs: '$ctrl',
                        backdrop: false
                    })
                    .result
                    .then((agencyId) => {
                        //If agency name does exists then it will display agencyid if new then it will display new created agencyId.

                    }).then(() => {
                        return this.refresh();
                    });
            }

            case 'approveContact': {
                this.loading = true;
                return this.AgencyContact.approveContact({ contactIDs: _.map(this.selections, (s) => s.contactId) })
                    .$promise
                    .then(() => this.refresh() , () => this.displayError())
                    .finally(() => this.loading = false);
            }

            case 'addCompetitorReport': {
                let agencyIds = this.selections.map(a => a.agencyId);
                return this.Competitors.validate({}, agencyIds).$promise
                    .then((res) => {
                        if (res.isValid) {
                            this.Competitors.addCompetitors({}, agencyIds).$promise
                                .then((res) => {
                                    //move to another grid
                                this.refresh();
                                });
                        }
                        else {
                            this.dialogs.error('Competitor report review', 'You are attempting to request a Competitor Report from an agency or agencies that already have pending requests - please review those requests before attempting to add new ones');
                        }
                    });
            }

            case 'approveCompetitorReport': {
                this.loading = true;
                return this.Competitors.approve({}, this.selections.map(s => s.competitorId))
                    .$promise
                    .then(() => this.refresh() , () => this.displayError())
                    .finally(() => this.loading = false);
            }

            case 'scheduleInterview': {

                let applicantUserId = this.selections[0].userId;
                this.scheduleInterview(applicantUserId);
                return;
            }

            default: {
                //this.alerts = [];
                return this.Workqueues[method.callMethod]((result) => {
                    this.refresh();
                    this.dialogs.notify('Action processed', 'Your action on the Work Queue has been processed.');
                }, () => this.displayError());
            }
        }
    }

    callEntryButton(method, entry) {
        switch (method.callMethod) {

            case 'scheduleInterview': {

                let applicantUserId = entry.userId;
                this.scheduleInterview(applicantUserId);
            }
        }
    }

    
    scheduleInterview(applicantUserId) {
        let interviewerId = {
            agencyId: this.Auth.user.roleDetails.agencyId,
            userId: this.Auth.user.userId,
            interviewerId: this.Auth.user.roleDetails.contactId,
            dateStart: moment().startOf('day').add(36, 'hours').toDate()
        }

        let action = new ScheduleInterview(this.$uibModal, interviewerId, applicantUserId);
        return action
            .execute()
            .then(() => {
                this.refresh();
            });
    }

    confirmMeeting(applicationIds) {
        var action = new ConfirmMeeting(this.$uibModal, applicationIds)
        return action.execute();
    }

    printPLCLetter() {
        var applicationIds = this.selections.map((i) => i.applicationId);

        this.PlacementLetter.signedURL({
            applicationIds: applicationIds
        }, (result) => {
            this.$window.open(result.url, '_blank');
        });
    }

    generateContracts(agencyIds) {
        this.loading = true;
        return this.AgencyContract.createContracts({ agencyIds: agencyIds })
            .$promise
            .then(() => this.refresh(), () => this.displayError())
            .finally(() => this.loading = false);
    }

    addCompetitorReport() {
        console.log('call here');
    }

    displayError() {
        //this.alerts.push({
        //    type: 'danger',
        //    msg: 'There was some error while processing your action. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
        //    dismissOnTimeout: 20000
        //});
    }

    goFirstPage() {
        if (this.params.page > 0) {
            this.params.page = 0;
        }
    }
    goPrevPage() {
        if (this.params.page > 0) {
            this.params.page = this.params.page - 1;
        }
    }
    goNextPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.params.page + 1;
        }
    }
    goLastPage() {
        if (this.params.page < this.lastPage) {
            this.params.page = this.lastPage;
        }
    }
}   


angular
    .module('app')
    .controller('QueueController', QueueController);
