import { IAlertConfig } from '../../../../models/bootstrap/Alert'
import { Auth } from '../../../../services/AuthService';

export class AgencyCompetitorModalController {
    alerts: IAlertConfig[] = []
    modelOpenMode: string = 'Create';
    isEdit: boolean = false;
    isOfficeUser: boolean = false;
    isAgencyUser: boolean = false;

    static $inject = ['$uibModalInstance', 'Auth', '$scope', 'Competitors', 'competitor'];
    constructor(private $uibModalInstance, private Auth, private $scope, private Competitors, private competitor) {

        this.isOfficeUser = this.Auth.user.isOfficer;
        this.isAgencyUser = this.Auth.user.isAgent;
        competitor.sponsorId = competitor.sponsorId + "";

        //office user can view competitor
        if (this.isOfficeUser || competitor.status === 1) {
            this.modelOpenMode = 'View'
        }

        //agent can edit until submitted
        if (this.isAgencyUser && competitor.status != 1) {
            this.isEdit = true;
            if (competitor.id > 0) {
                this.modelOpenMode = 'Edit'
            }
        }

    }

    validate() {
        this.$scope.$broadcast('submitted', true)
        return this.competitor.$validation();
    }

    save() {
        if (!this.validate()) {
            return
        }

        this.alerts = [];

        this.Competitors.save(this.competitor).$promise
            .then(() => {
                this.$uibModalInstance.close();
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but competitor is not saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                });
            });
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel');
    }


}

