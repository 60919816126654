
enum InsuranceAmazonResources { PlanOfInsurance = 1, LiabilityAndPropertyInsurance = 2, InsuranceCoverageExplained = 3 }

class ApplicationInsuranceController {

    planOfInsurance;
    liabilityAndPropertyInsurance;
    insuranceCoverageExplained;
    CISIMobileAPPFlyer;
    insuranceComplaintsProcedure;
    isAgencyGB: boolean;
    isAgencyDE: boolean;
    isPLC: boolean;
    displayInsuranceDocument: boolean;
    isAgencyOther: boolean;
    isAPACTorREM: boolean;
    InsuranceURL;
    InsuranceURLGerman;

    applicationInstance;

    static $inject = ['$scope', 'applicationObservable', 'AmazonResource', 'Auth', 'ExternalResource', 'USAuPairs']
    constructor(private $scope: angular.IScope, private applicationObservable, AmazonResource, private Auth, public ExternalResource, private USAuPairs) {


        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance;

            this.isAgencyGB = applicationInstance.countryCode === 'GB';
            this.isAgencyDE = (applicationInstance.countryCode === 'DE' || applicationInstance.countryCode === 'AT' || applicationInstance.countryCode === 'CH');
            this.isAgencyOther = !this.isAgencyGB && !this.isAgencyDE;
            this.isPLC = applicationInstance.status === 'PLC';
            this.isAPACTorREM = (applicationInstance.status === 'ACT' || applicationInstance.status === 'REM');
            this.displayInsuranceDocument = (applicationInstance.status === 'OLA' || applicationInstance.status === 'AWA' || applicationInstance.status === 'TBM')

            if (this.isAPACTorREM || (!(this.isAgencyDE) && this.isPLC)) {
                this.ExternalResource.preArrivalInsuranceURL({
                    applicationId: applicationInstance.applicationId
                })
                    .$promise
                    .then((result) => {
                        this.InsuranceURL = result.returnurl;
                    });
            }

            if (Auth.user.usAccessToken.length) {
                this.USAuPairs.canAupairExtend({
                    aupairId: this.Auth.user.usAuPairId
                })
                    .$promise
                    .then((result) => {
                        // for TBM, we dont have any arrival date so dont bother assigning URL 
                        if (result.data.germanInsuranceURL) {
                            this.InsuranceURLGerman = result.data.germanInsuranceURL;
                        }
                    })
            }

            AmazonResource.query({ applicationId: applicationInstance.applicationId, groupId: 1 })
                .$promise
                .then(result => {
                    this.planOfInsurance = this.findInsuranceUrl(result.insuranceAmazonUrls, InsuranceAmazonResources.PlanOfInsurance);
                    this.liabilityAndPropertyInsurance = this.findInsuranceUrl(result.insuranceAmazonUrls, InsuranceAmazonResources.LiabilityAndPropertyInsurance);
                    this.insuranceCoverageExplained = this.findInsuranceUrl(result.insuranceAmazonUrls, InsuranceAmazonResources.InsuranceCoverageExplained);
                });
        })

        var arrivalYear = 2017;
        switch (arrivalYear) {
            default:
                this.CISIMobileAPPFlyer = 'https://aupairamericalive-public.s3.amazonaws.com/documents/CISI+Mobile+APP+Flyer.pdf';
                this.insuranceComplaintsProcedure = 'https://aupairamericalive-public.s3.amazonaws.com/documents/Au+Pair+in+America+Insurance+Complaints+Procedure+V1+2020.pdf';

        }
    }
    findInsuranceUrl(insuranceAmazonUrls, insuranceAmazonResource) {
        return insuranceAmazonUrls[insuranceAmazonResource - 1].url;
    }
}

angular
    .module('app')
    .controller('ApplicationInsuranceController', ApplicationInsuranceController)
