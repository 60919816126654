import moment from 'moment';
import { SectionController } from '../SectionController';
import { ReviewPage } from '../../../services/ReviewService';
import { ItemGroupId } from '../../../models/ItemGroup';
import { ReviewItemId } from '../../../models/ReviewItem';

class PersonalDetailsController extends SectionController {
	availability: any;
	contactDetails: any;
	emergencyContact: any;
    drivingDetails: any;
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    page: ReviewPage;

	fields: any[];

    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs', 'Availability', 'ContactDetails', 'EmergencyContact', 'Driving', 'personalDetailsPage', 'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor($rootScope, $scope, private $q: ng.IQService, dialogs, private Availability, private ContactDetails, private EmergencyContact, private Driving, personalDetailsPage, applicationId, review, private ApproveChanges, Auth, ReevaluateSection, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.PersonalDetails, applicationObservable, dialogs);

		var getCountry = () => this.contactDetails.country;

		this.availability = personalDetailsPage.availability;

		this.contactDetails = personalDetailsPage.contact;
		this.contactDetails.getCountry = getCountry;

		this.emergencyContact = personalDetailsPage.emergencyContact;
		this.emergencyContact.getCountry = getCountry

		this.drivingDetails = personalDetailsPage.driving;
        this.drivingDetails.currentDate = moment().startOf('day').toDate();

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.PersonalDetails);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        applicationObservable.subscribe(i => {
            this.applicationStatus = i.status;
        })

        $scope.$watch(() => this.$scope.personalDetailsForm.$dirty, () => {
            this.$dirty = this.$scope.personalDetailsForm.$dirty
        })

        $scope.$on('personalDetails:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })


        $scope.$watch(() => this.contactDetails.telephoneOne.isMobile, (val) => {
            this.checkandSetShowHostFamilyOpt();
        })
        $scope.$watch(() => this.contactDetails.telephoneTwo.isMobile, (val) => {
            this.checkandSetShowHostFamilyOpt();
        })
    }

    checkandSetShowHostFamilyOpt() {
        this.contactDetails.showHFsmsOptIn = this.contactDetails.telephoneOne.isMobile || this.contactDetails.telephoneTwo.isMobile;
    }


    refreshSection(type, data) {
        var getCountry = () => this.contactDetails.country;
        switch (type) {
            case ReviewItemId.YourAvailability:
                this.availability = data;
                break;
            case ReviewItemId.ContactDetails:
                this.contactDetails = data;
                this.contactDetails.getCountry = getCountry;
                break;
            case ReviewItemId.EmergencyContactDetails:
                this.emergencyContact = data;
                this.emergencyContact.getCountry = getCountry;
                break;
            case ReviewItemId.DrivingDetails:
                this.drivingDetails = data;
                this.drivingDetails.currentDate = moment().startOf('day').toDate();
                break;
            default:
                break;
        }
    }

	save() {
		this.$scope.$broadcast('submitted', true);

		var promisses = [];
		var form = this.$scope.personalDetailsForm;		

        if (form['ContactDetails'].$dirty) {
            this.contactDetails.metaform = form['ContactDetails'].$metaformVersion;
            promisses.push(
                this.ContactDetails.update(this.contactDetails)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.ContactDetails))
            );
        }

        if (form['DrivingDetails'].$dirty) {
            this.drivingDetails.metaform = form['DrivingDetails'].$metaformVersion;
            promisses.push(
                this.Driving.update(this.drivingDetails)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.DrivingDetails))
            );
        }	

        if (form['Availability'].$dirty) {
            this.availability.metaform = form['Availability'].$metaformVersion;
            promisses.push(
                this.Availability.update(this.availability)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.YourAvailability))
            );
        }

        if (form['EmergencyContactDetails'].$dirty) {
            this.emergencyContact.metaform = form['EmergencyContactDetails'].$metaformVersion;
            promisses.push(
                this.EmergencyContact.update(this.emergencyContact)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.EmergencyContactDetails))
            );
        }

		this.$q.all(promisses)
			.then(() => {
                form.$setPristine();

                if (!this.contactDetails.showHFsmsOptIn) {
                    this.contactDetails.hFsmsOptIn = null;
                }

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
			}, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
			});
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.PersonalDetails,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}


angular
	.module('app')
	.controller('PersonalDetailsController', PersonalDetailsController);