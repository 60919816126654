import _ from 'lodash-es'
import { Notification } from './Notification'

class ApplicationMessagesController {

		me: any;
        selected: any;
        applicantUserId: number;
		newMessageText: string;
        conversations;
        isLoading: boolean = true;
        isInterviewerAssigned: boolean;
        notifications: Notification[];
        selectedNotification: Notification;

        static $inject = ['$scope', 'dialogs', 'Auth', 'Communication', 'recieverUserId', 'applicationStatus', 'NotificationsService']
        constructor(private $scope, private dialogs, Auth, private Communication, private recieverUserId, applicationStatus, NotificationsService) {
            this.me = Auth.user;

            this.applicantUserId = applicationStatus.applicantUserId;
            this.isInterviewerAssigned = (applicationStatus.interviewerId) ? true : false;

            if (Auth.user.isOfficer) {
                NotificationsService.query({ applicationId: applicationStatus.id }).$promise.then(result => this.notifications = result);
            }

            this.conversations = [];
            if (this.me.isApplicant) {
                Communication.loadConversations().then(c => {
                    this.loadConversations(c, recieverUserId);
                })
            } else {
                Communication.loadApplicationConversations(this.applicantUserId).then(c => {
                    this.loadConversations(c, recieverUserId);
                })
            }
            
        $scope.$on('commun:update', () => { });
    }

    loadConversations(conversations, selectUserId) {
        this.conversations = conversations;
        if (selectUserId) {
            this.selectConversationByUserId(selectUserId);
        } else {
            var newestConversation = _.maxBy(this.conversations, (conversation: any) => conversation.getLastMessageDate());
            this.switchConversation(newestConversation);
        }
            
        this.isLoading = false;
    }

		send(text) {
			this.selected
				.sendMessage(this.newMessageText)
                .then(() => { this.newMessageText = ''; });
		}

        select(conversation) {
			if (angular.isString(this.newMessageText) && this.newMessageText.length > 0) {
				this.dialogs
					.confirm('Please Confirm', 'You want to switch to other conversation. You will lost your reply. Are you sure ?')
					.result
					.then(() => {
                        this.newMessageText = '';
                        this.selectedNotification = undefined;
						this.switchConversation(conversation);
					});
			} else {
                this.selectedNotification = undefined;
				this.switchConversation(conversation);
            }
        }
        
        selectNotificationThread(notification: Notification) {
            this.selected = undefined;

            this.selectedNotification = notification;
        }

        selectedItem(){
            if(angular.isUndefined(this.selected)) {
                return this.selectedNotification;
            }
            else {
                return this.selected;
            }
        }

        selectedName(){
            if(angular.isUndefined(this.selected)) {
                return this.selectedNotification.from;
            }
            else {
                return this.selected.userName;
            }
        }

        areUnredMessages() {
            return this.selectedCounter() > 0;
        }

        selectedCounter(){
            if(angular.isUndefined(this.selected)) {
                return 0;
            }
            else {
                return this.selected.unreadCount;
            }
        }

        //select conversation or create new conversation if not exists.
        selectConversationByUserId(userId) {
            if (this.conversations) {
                var conversationDetail = _.chain(this.conversations)
                    .filter(c => c.participants.some(p => p.userId == userId))
                    .value();

                if (conversationDetail && conversationDetail.length > 0) {
                    this.switchConversation(conversationDetail[0]);
                } else if (this.me.isApplicant){
                    this.Communication.addConversation({ RecipientId: userId });
                } //Conversation will not be added for juno user
            }
        }

        isConversationSelected() {
            return !angular.isUndefined(this.selected);
        }

        isNotificationSelected() {
            return !angular.isUndefined(this.selectedNotification);
        }

        stripHtml(item: string) {
            var lines = item.split(/<[p>]+>/g);
            var strippedLines = lines.map(line => line.replace(/<[^>]+>/g, ''));
            return strippedLines;
        }

        private switchConversation(conversation) {
            if (angular.isUndefined(conversation)) {
                return;
            }

            //office user cannot change unread message status
            conversation.loadMessages();
            
            if (conversation.participants.some(p => p.userId == this.me.userId)) {
                if (conversation.unreadCount > 0) {
                    conversation.unreadCount = 0;
                    conversation.clearUnreadMessages();
                }
            }

		this.selected = conversation;
	}
}


angular
	.module('app')
    .controller('ApplicationMessagesController', ApplicationMessagesController);

