
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { RoutingProvider, state } from '../../services/RoutingService';
import { AccessLevelView } from '../accessLevel';

import { ApplicantHomeController } from '../../areas/home/ApplicantHomeController'
import { ApplicantDataController } from '../../areas/application/applicantData/ApplicantDataController'
import { PreInterviewController } from '../../areas/application/preInterview/PreInterviewController';
import { HealthAndWellBeingController } from '../../areas/application/healthAndWellBeing/HealthAndWellBeingController'
import { DeclarationsController } from '../../areas/application/declarations/DeclarationsController'


class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        const application: state<AccessLevelView> = {
            name: 'applications',
            url: '/application/{applicationId:int}',
            abstract: true,
            resolve: {
                applicationId: ['$stateParams', function ($stateParams) {
                    return $stateParams.applicationId;
                }],
                applicationObservable: ['ApplicationInstance', 'applicationId', function (ApplicationInstance, applicationId) {
                    return ApplicationInstance(applicationId);
                }],
                review: ['ReviewService', 'Auth', 'applicationObservable', function (ReviewService, Auth, applicationObservable) {
                    
                    if (applicationObservable) {
                        return applicationObservable.toPromise().then(a => ReviewService(a.applicationId, Auth.user.userId, Auth.user.role).$promise)
                    }
                }]
            },
            views: {
                content: {
                    applicant: {
                        controller: 'ApplicationController as ctrl',
                        template: 'templates/empty.html'
                    },
                    office: {
                        controller: 'ApplicationController as ctrl',
                        template: 'areas/application/office.html'
                    },
                    agency: {
                        controller: 'ApplicationController as ctrl',
                        template: 'areas/application/staff.html'
                    },
                    branch: {
                        controller: 'ApplicationController as ctrl',
                        template: 'areas/application/staff.html'
                    },
                    interviewer: {
                        controller: 'ApplicationController as ctrl',
                        template: 'areas/application/staff.html'
                    }
                }
            },
            states: [
                {
                    name: 'home',
                    url: '/home',
                    resolve: {
                        applicationStatus: ['ApplicationStatus', 'applicationId', function (ApplicationStatus, applicationId: number) {
                            if (applicationId) {
                                return ApplicationStatus.get({ applicationId: applicationId }).$promise
                            }
                        }],
                        review: ['Auth', 'ReviewService', 'applicationObservable', function (Auth, ReviewService, applicationObservable) {
                        
                            let showAsApplicant = true;
                            if (applicationObservable) {
                                return applicationObservable.toPromise().then(a => ReviewService(a.applicationId, Auth.user.userId, Auth.user.role, null, null, showAsApplicant).$promise)
                            }
                        }]
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: ApplicantHomeController,
                                template: 'areas/home/applicant.html'
                            }
                        }
                    }
                },
                {
                    name: 'applicationMessages',
                    url: '/messages',
                    params: {
                        recieverUserId: null,
                        applicantUserId: '@applicantUserId'
                    },
                    resolve: {
                        recieverUserId: ['$stateParams', function ($stateParams) {
                            return $stateParams.recieverUserId;
                        }],
                        applicationStatus: ['ApplicationStatus', 'applicationId', function (ApplicationStatus, applicationId: number) {
                            return ApplicationStatus.get({ applicationId: applicationId }).$promise
                        }],
                        $title: () => 'Messages'
                    },
                    views: {
                        '': {
                            interviewer: {
                                controller: 'ApplicationMessagesController as msgCtrl',
                                template: 'areas/messages/applicant.html'
                            },
                            staff: {
                                controller: 'ApplicationMessagesController as msgCtrl',
                                template: 'areas/messages/applicant.html'
                            }
                        }
                    }
                },
                {
                    name: 'editProfile',
                    url: '/edit/profile',
                    views: {
                        '': {
                            office: {
                                controller: 'EditProfileController as ctrl',
                                template: 'areas/application/editApplication/editProfile.html'
                            }
                        }
                    },
                    resolve: {
                        profilePage: ['ProfilePage', 'applicationId', function (ProfilePage, applicationId) {
                            return ProfilePage.get({ applicationId: applicationId }).$promise;
                        }],
                        applicantHobbies: ['Hobby', 'applicationObservable', function (Hobby, applicationObservable) {
                            return applicationObservable.toPromise().then(a => {
                                return Hobby.get({ applicantId: a.applicantId }).$promise;
                            });
                        }]
                    }
                },
                {
                    name: 'editPersonalDetails',
                    url: '/edit/personalDetails',
                    resolve: {
                        personalDetailsPage: ['PersonalDetailsPage', 'applicationId', function (PersonalDetailsPage, applicationId) {
                            return PersonalDetailsPage.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            office: {
                                controller: 'EditPersonalDetailsController as ctrl',
                                template: 'areas/application/editApplication/editPersonalDetails.html'
                            }
                        }
                    }
                },
                {
                    name: 'editTravelAndProgram',
                    url: '/edit/travelAndProgram',
                    views: {
                        '': {
                            office: {
                                controller: 'EditTravelAndProgramController as ctrl',
                                template: 'areas/application/editApplication/editTravelAndProgram.html'
                            }
                        }
                    },
                    resolve: {
                        travelAndProgramPage: ['TravelAndProgramPage', 'applicationId', function (TravelAndProgramPage, applicationId) {
                            return TravelAndProgramPage.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editChildCareExperiance',
                    url: '/edit/childcare',
                    views: {
                        '': {
                            office: {
                                controller: 'EditChildCareExperianceController as ctrl',
                                template: 'areas/application/editApplication/editChildCareExperiance.html'
                            }
                        }
                    },
                    resolve: {
                        childcareExperiencePage: ['ChildcareExperiencePage', 'applicationId', function (ChildcareExperiencePage, applicationId) {
                            return ChildcareExperiencePage.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editMedicalAndCriminal',
                    url: '/edit/medicalAndCriminal',
                    views: {
                        '': {
                            office: {
                                controller: 'EditMedicalAndCriminalController as ctrl',
                                template: 'areas/application/editApplication/editMedicalAndCriminal.html'
                            }
                        }
                    },
                    resolve: {
                        medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                            return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editHealthAndWellBeing',
                    url: '/edit/healthAndWellBeing',
                    views: {
                        '': {
                            office: {
                                controller: 'EditHealthAndWellBeingController as ctrl',
                                template: 'areas/application/editApplication/editHealthAndWellBeing.html'
                            }
                        }
                    },
                    resolve: {
                        medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                            return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editPersonal',
                    url: '/edit/personal',
                    views: {
                        '': {
                            office: {
                                controller: 'EditPersonalController as ctrl',
                                template: 'areas/application/editApplication/editPersonal.html'
                            }
                        }
                    },
                    resolve: {
                        profilePage: ['ProfilePage', 'applicationId', function (ProfilePage, applicationId) {
                            return ProfilePage.get({ applicationId: applicationId }).$promise;
                        }],
                        applicantHobbies: ['Hobby', 'applicationObservable', function (Hobby, applicationObservable) {
                            return applicationObservable.toPromise().then(a => {
                                return Hobby.get({ applicantId: a.applicantId }).$promise;
                            });
                        }],
                        personalDetailsPage: ['PersonalDetailsPage', 'applicationId', function (PersonalDetailsPage, applicationId) {
                            return PersonalDetailsPage.get({ applicationId: applicationId }).$promise;
                        }],
                        medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                            return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        }],
                        travelAndProgramPage: ['TravelAndProgramPage', 'applicationId', function (TravelAndProgramPage, applicationId) {
                            return TravelAndProgramPage.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editInterviewReportMotivation',
                    url: '/edit/interviewReport',
                    views: {
                        '': {
                            office: {
                                controller: 'EditInterviewReportMotivationController as ctrl',
                                template: 'areas/application/editApplication/editInterviewReportMotivation.html'
                            }
                        }
                    },
                    resolve: {
                        interviewReport: ['InterviewReport', 'applicationId', function (InterviewReport, applicationId) {
                            return InterviewReport.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editPassportAndVisa',
                    url: '/edit/passport',
                    views: {
                        '': {
                            office: {
                                controller: 'EditPassportAndVisaController as ctrl',
                                template: 'areas/application/editApplication/editPassportAndVisa.html'
                            }
                        }
                    },
                    resolve: {
                        travelAndProgramPage: ['TravelAndProgramPage', 'applicationId', function (TravelAndProgramPage, applicationId) {
                            return TravelAndProgramPage.get({ applicationId: applicationId }).$promise;
                        }],
                        interviewReport: ['InterviewReport', 'applicationId', function (InterviewReport, applicationId) {
                            //return {};
                            return InterviewReport.get({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'editPlacement',
                    url: '/edit/placement',
                    views: {
                        '': {
                            office: {
                                controller: 'EditPlacementController as ctrl',
                                template: 'areas/application/editApplication/editPlacement.html'
                            }
                        }
                    },
                    resolve: {
                        placementMatchDetails: ['PlacementMatchDetails', 'applicationId', function (PlacementMatchDetails, applicationId) {
                            return PlacementMatchDetails.get({ applicationId: applicationId }).$promise
                        }],
                        applicationStatusHistory: ['ApplicationStatusHistory', 'applicationId', function (ApplicationStatusHistory, applicationId) {
                            return ApplicationStatusHistory.query({ applicationId: applicationId }).$promise
                        }],
                        $title: () => 'Placement'
                    }
                },
                {
                    name: 'editHostFamily',
                    url: '/edit/hostFamily',
                    views: {
                        '': {
                            office: {
                                controller: 'EditHostFamilyController as ctrl',
                                template: 'areas/application/editApplication/editHostFamily.html'
                            }
                        }
                    }
                },
                {
                    name: 'editAccount',
                    url: '/account',
                    views: {
                        '': {
                            office: {
                                controller: 'EditAccountController as ctrl',
                                template: 'areas/application/editApplication/editAccount.html'
                            }
                        }
                    },
                    resolve: {
                        proofOfPayment: ['Finance', 'applicationId', function (Finance, applicationId) {
                            return Finance.proofOfPaymentInformation({ applicationId: applicationId }).$promise
                        }],
                        invoices: ['Invoice', 'applicationId', function (Invoice, applicationId) {
                            return Invoice.list({ applicationId: applicationId }).$promise
                        }]
                    }
                },
                {
                    name: 'applicantData',
                    url: '/applicantData',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: ApplicantDataController,
                                template: 'areas/application/applicantData/applicantData.html'
                            }
                        }
                    },
                    resolve: {
                        applicantUserId: ['applicationObservable', function (applicationObservable) {
                            return applicationObservable.toPromise().then(a => a.userId);
                        }],
                        userLastLogin: ['UserLastLogin', 'applicantUserId', function (UserLastLogin, applicantUserId) {
                            return UserLastLogin.get({ userId: applicantUserId }).$promise;
                        }],
                        userAgreements: ['UserAgreement', 'applicantUserId', function (UserAgreement, applicantUserId) {
                            return UserAgreement.query({ userId: applicantUserId }).$promise;
                        }],
                        userStatus: ['User', 'applicantUserId', function (User, applicantUserId) {
                            return User.locked({ userId: applicantUserId }).$promise;
                        }],
                        gdprStatus: ['GdprStatus', 'applicantUserId', function (GdprStatus, applicantUserId) {
                            return GdprStatus.get({ userId: applicantUserId }).$promise;
                        }],
                        applicantDataReplication: ['ApplicantDataReplication', 'applicationId', function (ApplicantDataReplication, applicationId) {
                            return ApplicantDataReplication.query({ applicationId: applicationId }).$promise;
                        }]
                    }
                },
                {
                    name: 'applicationPayments',
                    url: '/payments',
                    resolve: {},
                    views: {
                        '': {
                            loggedin: {
                                controller: 'PaymentsController as ctrl',
                                template: 'areas/payments/office.html'
                            },
                        }
                    },
                    states: [
                        {
                            name: 'invoiceDetails',
                            url: '/',
                            views: {
                                content: {
                                    loggedin: {
                                        controller: 'ApplicantPaymentsController as ctrl',
                                        template: 'areas/payments/invoice/applicant.html'
                                    }
                                }
                            },
                            resolve: {
                                placementInfo: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId) {
                                    return ApplicationPlacement.placementInfo({ applicationId: applicationId }).$promise
                                }],
                                paymentOptions: ['PaymentOptions', 'applicationObservable', 'applicationId', function (PaymentOptions, applicationObservable, applicationId) {
                                    return applicationObservable.toPromise().then(a => {
                                        return PaymentOptions.byAgency({ countryCode: a.countryCode, agencyId: a.agencyId }).$promise;
                                    });
                                }],
                                applicationStatus: ['ApplicationStatus', 'applicationId', function (ApplicationStatus, applicationId: number) {
                                    return ApplicationStatus.get({ applicationId: applicationId }).$promise
                                }],
                                $title: () => 'Invoice'
                            }
                        },
                        {
                            name: 'insuranceDetails',
                            url: '/Insurance',
                            views: {
                                content: {
                                    loggedin: {
                                        controller: 'ApplicationInsuranceController as ctrl',
                                        template: 'areas/payments/insurance/ApplicationInsurance.html'
                                    }
                                }
                            },
                            resolve: {
                                placementInfo: ['ApplicationPlacement', 'applicationId', function (ApplicationPlacement, applicationId: number) {
                                    return ApplicationPlacement.placementInfo({ applicationId: applicationId }).$promise;
                                }],
                                $title: () => 'Insurance'
                            }
                        },
                        {
                            name: 'feeSheet',
                            url: '/feeSheet',
                            views: {
                                content: {
                                    loggedin: {
                                        template: 'areas/payments/feeSheet/feeSheet.html',
                                        controller: 'FeeSheetController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                $title: () => 'Fee Sheet',
                                financialPeriodID: ['$stateParams', function ($stateParams) {
                                    return $stateParams.financialPeriodID;
                                }],
                                contractId: ['$stateParams', function ($stateParams) {
                                    return $stateParams.contractId;
                                }]
                            },
                            params: {
                                financialPeriodID: null,
                                contractId: null
                            }
                        }
                    ]
                },
                {
                    name: 'applicationShare',
                    url: '/share',
                    views: {
                        '': {
                            office: {
                                template: 'areas/share/office.html',
                                controller: 'ApplicationShareController as ctrl'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'application',
                            url: '/application',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/application/application.html',
                                        controller: 'ShareApplicationController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                applicationGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.applicationGuid
                                }],
                                application: ['ShareApplication', 'applicationGuid', function (ShareApplication, applicationGuid) {
                                    return ShareApplication.get({ applicationGuid: applicationGuid }).$promise
                                }]
                            }
                        },
                        {
                            name: 'backgroundQuestions',
                            url: '/backgroundQuestions',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/backgroundQuestions/backgroundQuestions.html',
                                        controller: 'ShareBackgroundQuestionsController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                backgroundQuestionsGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.backgroundQuestionsGuid
                                }],
                                backgroundQuestions: ['ShareBackgroundQuestions', 'backgroundQuestionsGuid', function (ShareBackgroundQuestions, backgroundQuestionsGuid) {
                                    return ShareBackgroundQuestions.get({ backgroundQuestionsGuid: backgroundQuestionsGuid }).$promise
                                }]
                            }
                        },
                        {
                            name: 'childcareExperience',
                            url: '/childcareExperience',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/childcareExperience/childcareExperience.html',
                                        controller: 'ShareChildcareExperienceController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                childcareExperienceGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.childcareExperienceGuid
                                }],
                                childcareExperience: ['ShareChildcareExperience', 'childcareExperienceGuid', function (ShareChildcareExperience, childcareExperienceGuid) {
                                    return ShareChildcareExperience.get({ childcareExperienceGuid: childcareExperienceGuid }).$promise
                                }]
                            }
                        },
                        {
                            name: 'interviewReport',
                            url: '/interviewReport',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/interviewReport/interviewReport.html',
                                        controller: 'ShareInterviewReportController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                interviewReportGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.interviewReportGuid
                                }],
                                interviewReport: ['ShareInterviewReport', 'interviewReportGuid', function (ShareInterviewReport, interviewReportGuid) {
                                    return ShareInterviewReport.get({ interviewReportGuid: interviewReportGuid }).$promise
                                }]
                            }
                        },
                        {
                            name: 'telephoneReferenceCheck',
                            url: '/telephoneReferenceCheck',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/telephoneReferenceCheck/telephoneReferenceCheck.html',
                                        controller: 'ShareTelephoneReferenceCheckController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                telephoneReferenceCheckGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.telephoneReferenceCheckGuid
                                }],
                                telephoneReferenceCheck: ['ShareTelephoneReferenceCheck', 'telephoneReferenceCheckGuid', function (ShareTelephoneReferenceCheck, telephoneReferenceCheckGuid) {
                                    return ShareTelephoneReferenceCheck.get({ telephoneReferenceCheckGuid: telephoneReferenceCheckGuid }).$promise
                                }]
                            }
                        },
                        {
                            name: 'shareApplication',
                            url: '/shareApplication',
                            views: {
                                '': {
                                    office: {
                                        template: 'areas/share/shareApplication/shareApplication.html',
                                        controller: 'ShareShareApplicationController as ctrl'
                                    }
                                }
                            },
                            resolve: {
                                shareApplicationGuid: ['applicationShareGuids', function (applicationShareGuids) {
                                    return applicationShareGuids.shareApplicationGuid
                                }],
                                shareApplication: ['ShareShareApplication', 'shareApplicationGuid', function (ShareShareApplication, shareApplicationGuid) {
                                    return ShareShareApplication.get({ shareApplicationGuid: shareApplicationGuid }).$promise
                                }]
                            }
                        }
                    ],
                    resolve: {
                        applicationShareGuids: ['ApplicationShareGuids', 'applicationId', function (ApplicationShareGuids, applicationId) {
                            return ApplicationShareGuids.get({ applicationId: applicationId }).$promise
                        }]
                    }
                },
                {
                    name: 'interviewReport1',
                    url: '/main/interviewReport',
                    resolve: {
                        interviewReport: ['InterviewReport', 'applicationId', function (InterviewReport, applicationId) {
                            //return {};
                            return InterviewReport.get({ applicationId: applicationId }).$promise;
                        }],
                        agencyId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.agencyId;
                        }],
                        interviewerId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.contactId;
                        }]
                    },
                    views: {
                        '': {
                            interviewer: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            branch: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            // for agency views, in the FUTURE!!!!
                            agency: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            // for office views, in the FUTURE!!!!  Not that we know WTF this should be called
                            office: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'interviewReportShow',
                    url: '/interviewReportShow',
                    resolve: {
                        interviewReport: ['InterviewReport', 'applicationId', function (InterviewReport, applicationId) {
                            //return {};
                            return InterviewReport.query({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            staff: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'settings',
                    url: '/settings',
                    views: {
                        '': {
                            staff: {
                                controller: 'SettingsController as ctrl',
                                template: 'areas/user/settings/settingsApplicant.html'
                            },
                            preInterview: {
                                controller: 'SettingsController as ctrl',
                                template: 'areas/user/settings/settingsApplicant.html'
                            },
                            applicant: {
                                controller: 'SettingsController as ctrl',
                                template: 'areas/user/settings/settingsApplicant.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'marketing',
                            url: '/marketing/{action:(?:unsubscribe)}',
                            views: {
                                '': {
                                    everyone: {
                                        controller: 'MarketingController as ctrl',
                                        template: 'areas/user/marketing/marketing.html'
                                    }
                                }
                            },
                            resolve: {
                                action: ['$stateParams', function ($stateParams) {
                                    return $stateParams.action
                                }],
                                marketingPreferences: ['MarketingPreferences', 'applicationId', function (MarketingPreferences, applicationId) {
                                    return MarketingPreferences.get({ applicationId: applicationId })
                                        .$promise;
                                }]
                            },
                            params: {
                                action: { value: null, squash: true }
                            }
                        }
                    ]
                }
            ]
        }

        var applicationInstance: state<AccessLevelView> = {
            name: 'inst',
            url: '',
            abstract: true,
            resolve: {
                edit: ['$stateParams', function ($stateParams) {
                    return $stateParams.edit;
                }],
                menu: function () {

                },
                $title: ['applicationObservable', function (applicationObservable) {
                    return applicationObservable.toPromise().then(applicationInstance => {
                        return `${applicationInstance.applicationId} - ${applicationInstance.firstName} ${applicationInstance.lastName}`;
                    });
                }]
            },
            views: {
                '': {
                    applicant: {
                        controller: 'ApplicationInstanceController as ctrl',
                        template: 'areas/application/instance/applicant.html'
                    },
                    staff: {
                        controller: 'ApplicationInstanceController as ctrl',
                        template: 'areas/application/instance/staff.html'
                    }
                }
            },
            params: {
                edit: null
            },
            states: [
                {
                    name: 'preInterview',
                    url: '/preInterview',
                    resolve: {
                        applicantId: ['applicationObservable', function (applicationObservable) {
                            if (applicationObservable) {
                                return applicationObservable.toPromise().then(a => a.applicantId)
                            }
                        }]
                    },
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            staff: {
                                controller: PreInterviewController,
                                template: 'areas/application/preInterview/preInterview.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'highlights',
                    url: '',
                    resolve: {
                        profilePage: ['ProfilePage', 'applicationId', function (ProfilePage, applicationId) {
                            return ProfilePage.get({ applicationId: applicationId }).$promise;
                        }],
                        applicantHobbies: ['Hobby', 'applicationObservable', function (Hobby, applicationObservable) {
                            return applicationObservable.toPromise().then(a => {
                                return Hobby.get({ applicantId: a.applicantId }).$promise;
                            });
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'HighlightsController as ctrl',
                                template: 'areas/application/highlights/highlights.edit.html'
                            },
                            staff: {
                                controller: 'HighlightsController as ctrl',
                                template: 'areas/application/highlights/highlights.display.html'
                            },
                        }
                    }
                },
                {
                    name: 'personalDetails',
                    url: '/personalDetails',
                    resolve: {
                        personalDetailsPage: ['PersonalDetailsPage', 'applicationId', function (PersonalDetailsPage, applicationId) {
                            return PersonalDetailsPage.get({ applicationId: applicationId }).$promise;
                        }],
                        drivingLicenseOptions: ['ApplicationLookup', 'applicationId', function (ApplicationLookup, applicationId) {
                            return ApplicationLookup.drivingLicenseType({ applicationId: applicationId }).$promise;
                        }],
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'PersonalDetailsController as ctrl',
                                template: 'areas/application/personalDetails/personalDetails.edit.html'
                            },
                            staff: {
                                controller: 'PersonalDetailsController as ctrl',
                                template: 'areas/application/personalDetails/personalDetails.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'travelAndProgram',
                    url: '/travelAndProgram',
                    resolve: {
                        travelAndProgramPage: ['TravelAndProgramPage', 'applicationId', function (TravelAndProgramPage, applicationId) {
                            return TravelAndProgramPage.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'TravelAndProgramController as ctrl',
                                template: 'areas/application/travelAndProgram/travelAndProgram.edit.html'
                            },
                            staff: {
                                controller: 'TravelAndProgramController as ctrl',
                                template: 'areas/application/travelAndProgram/travelAndProgram.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'childcare',
                    url: '/childcare',
                    resolve: {
                        childcareExperiencePage: ['ChildcareExperiencePage', 'applicationId', function (ChildcareExperiencePage, applicationId) {
                            return ChildcareExperiencePage.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'ChildcareController as ctrl',
                                template: 'areas/application/childcare/childcare.edit.html'
                            },
                            office: {
                                controller: 'ChildcareController as ctrl',
                                template: 'areas/application/childcare/childcare.display.html'
                            },
                            staff: {
                                controller: 'ChildcareController as ctrl',
                                template: 'areas/application/childcare/childcare.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'educationAndWork',
                    url: '/educationAndWork',
                    resolve: {
                        educationAndWorkPage: ['EducationAndWorkPage', 'applicationId', function (EducationAndWorkPage, applicationId) {
                            return EducationAndWorkPage.get({ applicationId: applicationId }).$promise;
                        }]/*,
                    review: ['ReviewService', 'Auth', 'applicationId', function (ReviewService, Auth, applicationId: number, referenceId, itemId) {
                        itemId = 16;
                        return ReviewService(applicationId, Auth.user.userId, Auth.user.role, null, itemId).$promise;
                    }]*/
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'EducationAndWorkController as ctrl',
                                template: 'areas/application/educationAndWork/educationAndWork.edit.html'
                            },
                            staff: {
                                controller: 'EducationAndWorkController as ctrl',
                                template: 'areas/application/educationAndWork/educationAndWork.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'medicalAndCriminal',
                    url: '/medicalAndCriminal',
                    resolve: {
                        medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                            return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'MedicalAndCriminalController as ctrl',
                                template: 'areas/application/medicalAndCriminal/medicalAndCriminal.edit.html'
                            },
                            staff: {
                                controller: 'MedicalAndCriminalController as ctrl',
                                template: 'areas/application/medicalAndCriminal/medicalAndCriminal.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'healthAndWellBeing',
                    url: '/healthAndWellBeing',
                    resolve: {
                        allergies: ['Allergies', 'applicationId', function (Allergies, applicationId) {
                            return Allergies.get({ applicationId: applicationId }).$promise;
                        }],
                        physicalHealth: ['PhysicalHealth', 'applicationId', function (PhysicalHealth, applicationId) {
                            return PhysicalHealth.get({ applicationId: applicationId }).$promise;
                        }],
                        mentalHealth: ['MentalHealth', 'applicationId', function (MentalHealth, applicationId) {
                            return MentalHealth.get({ applicationId: applicationId }).$promise;
                        }],
                        medicalTreatments: ['MedicalTreatments', 'applicationId', function (MedicalTreatments, applicationId) {
                            return MedicalTreatments.get({ applicationId: applicationId }).$promise;
                        }],
                        otherHealthConditions: ['OtherHealthConditions', 'applicationId', function (OtherHealthConditions, applicationId) {
                            return OtherHealthConditions.get({ applicationId: applicationId }).$promise;
                        }],
                        restrictedHealthInformation: ['RestrictedHealthInformation', 'applicationId', function (RestrictedHealthInformation, applicationId) {
                            return RestrictedHealthInformation.get({ applicationId: applicationId }).$promise;
                        }],
                        vaccinations: ['Vaccinations', 'applicationId', function (Vaccinations, applicationId) {
                            return Vaccinations.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            controllerAs: 'ctrl',
                            applicant: {
                                controller: HealthAndWellBeingController,
                                template: 'areas/application/healthAndWellBeing/healthAndWellBeing.edit.html'
                            },
                            staff: {
                                controller: HealthAndWellBeingController,
                                template: 'areas/application/healthAndWellBeing/healthAndWellBeing.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'declarations',
                    url: '/declarations',
                    resolve: {
                        preExistingMedicalConditions: ['PreExistingMedicalConditionsDetails', 'applicationId', function (PreExistingMedicalConditionsDetails, applicationId) {
                            return PreExistingMedicalConditionsDetails.get({ applicationId: applicationId }).$promise;
                        }],
                        smoking: ['SmokingDetails', 'applicationId', function (SmokingDetails, applicationId) {
                            return SmokingDetails.get({ applicationId: applicationId }).$promise;
                        }],
                        criminal: ['CriminalDetails', 'applicationId', function (CriminalDetails, applicationId) {
                            return CriminalDetails.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            controllerAs: 'ctrl',
                            applicant: {
                                controller: DeclarationsController,
                                template: 'areas/application/declarations/declarations.edit.html'
                            },
                            staff: {
                                controller: DeclarationsController,
                                template: 'areas/application/declarations/declarations.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'myUploads',
                    url: '/myUploads',
                    resolve: {
                        educationAndWorkPage: ['EducationAndWorkPage', 'applicationId', function (EducationAndWorkPage, applicationId) {
                            return EducationAndWorkPage.get({ applicationId: applicationId }).$promise;
                        }]
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'MyUploadsController as ctrl',
                                template: 'areas/application/myUploads/myUploads.edit.html'
                            },
                            staff: {
                                controller: 'MyUploadsController as ctrl',
                                template: 'areas/application/myUploads/myUploads.display.html'
                            },
                        }
                    }
                },
                {
                    name: 'interviewReport',
                    url: '/interviewReport',
                    resolve: {
                        interviewReport: ['InterviewReport', 'applicationId', function (InterviewReport, applicationId) {
                            //return {};
                            return InterviewReport.get({ applicationId: applicationId }).$promise;
                        }],
                        agencyId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.agencyId;
                        }],
                        interviewerId: ['Auth', function (Auth) {
                            return Auth.user.roleDetails.contactId;
                        }]
                    },
                    views: {
                        '': {
                            interviewer: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            branch: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            // for agency views, in the FUTURE!!!!
                            agency: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.edit.html'
                            },
                            // for office views, in the FUTURE!!!!  Not that we know WTF this should be called
                            office: {
                                controller: 'InterviewReportController as ctrl',
                                template: 'areas/application/interviewReport/interviewReport.display.html'
                            }
                        }
                    }
                },
                {
                    name: 'references',
                    url: '/references',
                    resolve: {
                        references: ['Reference', 'applicationId', function (Reference, applicationId) {
                            return Reference.query({ applicationId: applicationId }).$promise;
                        }],
                        referenceSummary: ['Reference', 'applicationId', function (Reference, applicationId) {
                            return Reference.summary({ applicationId: applicationId }).$promise;
                        }],
                        childcareExperience: ['ChildcareExperience', 'applicationId', function (ChildcareExperience, applicationId) {
                            return ChildcareExperience.get({ applicationId: applicationId }).$promise;
                        }],
                        program: ['Program', 'applicationId', function (Program, applicationId) {
                            return Program.get({ applicationId: applicationId }).$promise;
                        }],
                        childcareId: ['$stateParams', function ($stateParams) {
                            return $stateParams.childcareId;
                        }]
                    },
                    params: {
                        childcareId: null
                    },
                    views: {
                        '': {
                            applicant: {
                                controller: 'ReferenceController as refCtrl',
                                template: 'areas/application/reference/reference.html'
                            },
                            staff: {
                                controller: 'ReferenceController as refCtrl',
                                template: 'areas/application/reference/reference.staff.html'
                            }
                        }
                    },
                    states: [
                        {
                            name: 'inst',
                            url: '/{referenceId:int}',
                            views: {
                                '@app.applications': {
                                    staff: {
                                        controller: 'ReferenceInstanceController as ctrl',
                                        template: 'areas/application/reference/inst/reference.html'
                                    }
                                }
                            },
                            resolve: {
                                referenceId: ['$stateParams', function ($stateParams) {
                                    return $stateParams.referenceId
                                }],
                                reference: ['Reference', 'referenceId', function (Reference, referenceId) {
                                    return Reference.get({ referenceId: referenceId }).$promise
                                }],
                                telephoneReferenceCheck: ['TelephoneReferenceCheck', 'referenceId', function (TelephoneReferenceCheck, referenceId) {
                                    return TelephoneReferenceCheck.get({ referenceId: referenceId }).$promise
                                }],
                                dce: ['ChildcareExperience', 'applicationId', 'reference', function (ChildcareExperience, applicationId, reference) {
                                    if (reference.referenceTargetId && reference.referenceTargetId > 0) {
                                        return ChildcareExperience.get({ applicationId: applicationId, id: reference.referenceTargetId }).$promise
                                    }
                                    //else {
                                    //    return ChildcareExperience.get({ applicationId: applicationId }).$promise
                                    //}
                                }],
                                review: ['ReviewService', 'Auth', 'applicationObservable', 'referenceId', function (ReviewService, Auth, applicationObservable, referenceId) {
                                    let itemId = 24;
                                    if (applicationObservable) {
                                        return applicationObservable.toPromise().then(a => ReviewService(a.applicationId, Auth.user.userId, Auth.user.role, referenceId, itemId).$promise)
                                    }
                                }]
                            }
                        }
                    ]
                },
                {
                    name: 'print',
                    url: '/print',
                    resolve: {
                        applicantHobbies: ['Hobby', 'applicationObservable', function (Hobby, applicationObservable) {
                            return applicationObservable.toPromise().then(a => {
                                return Hobby.get({ applicantId: a.applicantId }).$promise;
                            });
                        }],
                        profilePage: ['ProfilePage', 'applicationId', function (ProfilePage, applicationId) {
                            return ProfilePage.get({ applicationId: applicationId }).$promise;
                        }],
                        personalDetailsPage: ['PersonalDetailsPage', 'applicationId', function (PersonalDetailsPage, applicationId) {
                            return PersonalDetailsPage.get({ applicationId: applicationId }).$promise;
                        }],
                        travelAndProgramPage: ['TravelAndProgramPage', 'applicationId', function (TravelAndProgramPage, applicationId) {
                            return TravelAndProgramPage.get({ applicationId: applicationId }).$promise;
                        }],
                        childcareExperiencePage: ['ChildcareExperiencePage', 'applicationId', function (ChildcareExperiencePage, applicationId) {
                            return ChildcareExperiencePage.get({ applicationId: applicationId }).$promise;
                        }],
                        educationAndWorkPage: ['EducationAndWorkPage', 'applicationId', function (EducationAndWorkPage, applicationId) {
                            return EducationAndWorkPage.get({ applicationId: applicationId }).$promise;
                        }],
                        medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                            return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        }]
                        //medicalAndCriminalPage: ['MedicalAndCriminalPage', 'applicationId', function (MedicalAndCriminalPage, applicationId) {
                        //    return MedicalAndCriminalPage.get({ applicationId: applicationId }).$promise;
                        //}]
                    },
                    views: {
                        '@': {
                            applicant: {
                                controller: 'ApplicationPrintController as printCtrl',
                                template: 'areas/application/print/applicationPrint.html'
                            },
                            staff: {
                                controller: 'ApplicationPrintController as printCtrl',
                                template: 'areas/application/print/applicationPrint.html'
                            }
                        }
                    }
                }
            ]
        };

        RoutingProvider.addState(application, 'app');
        RoutingProvider.addState(applicationInstance, 'app.applications');
    }
}

angular
    .module('app')
    .config(RoutingConfig);