
class ContactInterviewerController {
    applicationId;
    interviewerId;
    contactInterviewDetail: any;

    static $inject = ['ProfileData', 'Application', 'applicationId'];
    constructor(private ProfileData, private Application, applicationId) {
        this.applicationId = applicationId;

        this.Application.get({ applicationId: this.applicationId }, (response) => {
            if (response.interviewerId && response.interviewerId > 0) {
                this.ProfileData.get({ interviewerId: response.interviewerId }, (interviewDetail) => {
                    this.contactInterviewDetail = interviewDetail;
                });
            }
        });
    }
}
angular
    .module('app')
    .controller('ContactInterviewerController', ContactInterviewerController);