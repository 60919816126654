import moment from 'moment';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController';

class EditTravelAndProgramController extends EditApplicationController {

    //applicant: any
    //application: any

    //program: any;
    //qualifications: any;
    //passport: any;
    //visa: any;

    //applicationId: number
    //applicantUserId: number
    //applicationAgencyId: number
    //applicationInterviewerId: number


    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', 'applicationObservable', 'Auth', '$q', 'applicationId', 'travelAndProgramPage', 'Program', 'Passport','ApplicationReviewItemsAny'];
    constructor($scope, applicationObservable, private Auth, private $q, applicationId, travelAndProgramPage, private Program, private Passport, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);

        this.applicationId = applicationId;

        applicationObservable.subscribe(applicationInstance => {
            this.applicantUserId = applicationInstance.userId;
            this.applicationAgencyId = applicationInstance.agencyId;
            this.applicationInterviewerId = applicationInstance.interviewerId;
        })

            
        this.program = travelAndProgramPage.program;
        this.qualifications = travelAndProgramPage.qualifications;
        //this.prematch = travelAndProgramPage.prematch;
        this.passport = travelAndProgramPage.passport;
        this.passport.currentDate = moment().startOf('day').toDate();

        //this.visa = travelAndProgramPage.visa;
        //this.visa.currentDateVisa = moment().startOf('day').toDate();



        $scope.$watch(() => this.$scope.travelAndProgramFrom.$dirty, () => {
            this.$dirty = this.$scope.travelAndProgramFrom.$dirty
        })

    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.travelAndProgramFrom;            

        if (form['ProgramSelection'].$dirty || form['Program'].$dirty) {
            this.program.metaform = form['Program'].$metaformVersion;
            promisses.push(
                this.Program.update(this.program).$promise);
        }

        var isNameChange = form['Edit_Passport'].firstName.$dirty || form['Edit_Passport'].lastName.$dirty
        if (form['Edit_Passport'].$dirty) {
            this.passport.metaform = form['Edit_Passport'].$metaformVersion;
            promisses.push(
                this.Passport.update(this.passport).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine()
                if (isNameChange) {
                    this.Auth.updateUser({
                        firstName: this.passport.firstName,
                        lastName: this.passport.lastName
                    })
                }

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditTravelAndProgramController', EditTravelAndProgramController)