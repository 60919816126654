import { SectionController } from '../SectionController'
import { ReviewPage } from '../../../services/ReviewService'
import { ItemGroupId } from '../../../models/ItemGroup'
import { ReviewItemId } from '../../../models/ReviewItem'


class MedicalAndCriminalController extends SectionController {

    medical: any
    smoking: any
    criminal: any
    covid: any
    preExistingMedicalConditions: any
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    medicalUploadState: any;
    criminalUploadState: any;
    page: ReviewPage;
    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', 'dialogs', 'MedicalDetails', 'SmokingDetails', 'CriminalDetails', 'CovidVaccinationDetails', 'PreExistingMedicalConditionsDetails', 'medicalAndCriminalPage', 'applicationId', 'review', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable']
    constructor($rootScope: angular.IRootScopeService, $scope: angular.IScope, private $q: ng.IQService, dialogs, private MedicalDetails, private SmokingDetails, private CriminalDetails, private CovidVaccinationDetails, private PreExistingMedicalConditionsDetails, medicalAndCriminalPage, applicationId, review, private ApproveChanges, Auth, ReevaluateSection, applicationObservable) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.MedicalAndCriminalHistory, applicationObservable, dialogs)
            
        this.medical = medicalAndCriminalPage.medical
        this.smoking = medicalAndCriminalPage.smoking
        this.criminal = medicalAndCriminalPage.criminal
        this.preExistingMedicalConditions = medicalAndCriminalPage.preExistingMedicalConditions
        this.covid = medicalAndCriminalPage.covidVaccination

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.MedicalAndCriminalHistory);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        var heightFeet, heightInches
        var weightStone, weightPound

        $scope.$watch(() => this.medical.height, (newVal, oldVal) => {
            var totalInches = Math.round(newVal / 2.54)
            heightFeet = Math.floor(totalInches / 12)
            heightInches = totalInches % 12

        })

        $scope.$watch(() => this.medical.weight, (newVal, oldVal) => {
            var totalPounds = Math.round(newVal / 0.45359237)
            weightStone = Math.floor(totalPounds / 14)
            weightPound = totalPounds % 14

        })

        if (this.medical.healthStatementDate) {
            this.medical.healthStatement = true;
        }
        $scope.$watch(() => this.medical.healthStatement, (newVal, oldVal) => {
            if (newVal)
                this.medical.healthStatementDate = new Date();
            else
                this.medical.healthStatementDate = undefined;

        })

        Object.defineProperties(this.medical, {
            "heightFeet": {
                get: function () {
                    return heightFeet
                },
                set: function (v) {
                    heightFeet = v
                    if (isNaN(heightInches)) { heightInches = 0; }
                    if (isNaN(parseInt(heightFeet))) { heightFeet = 0 }                       
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "heightInches": {
                get: function () {
                    return heightInches
                },
                set: function(v) {
                    heightInches = v
                    if (isNaN(heightFeet)) { heightFeet = 0; } 
                    if (isNaN(parseInt(heightInches))) { heightInches = 0 }    
                    this.height = Math.round((parseInt(heightFeet) * 12 + parseInt(heightInches)) * 2.54)
                }
            },
            "weightStone": {
                get: function () {
                    return weightStone
                },
                set: function(v) {
                    weightStone = v
                    if (isNaN(weightPound)) { weightPound = 0; } 
                    if (isNaN(parseInt(weightStone))) { weightStone = 0 }    
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            },
            "weightPound": {
                get: function () {
                    return weightPound
                },
                set: function(v) {
                    weightPound = v
                    if (isNaN(weightStone)) { weightStone = ""; } 
                    if (isNaN(parseInt(weightPound))) { weightPound = 0 }    
                    this.weight = Math.round((parseInt(weightStone) * 14 + parseInt(weightPound)) * 0.45359237)
                }
            }
        })

        applicationObservable.subscribe(i => {
            this.medical.gdpr = i.gdpr;
            this.applicationStatus = i.status;
        });

        this.medical.heightCentimeters = this.medical.height
        this.medical.weightKilograms = this.medical.weight

        $scope.$watch(() => this.$scope.medicalAndCriminalForm.$dirty, () => {
            this.$dirty = this.$scope.medicalAndCriminalForm.$dirty
        })

        $scope.$on('medicalAndCriminal:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })
    }

    refreshSection(type, data) {
        switch (type) {
            case ReviewItemId.MedicalAndHealth:
                this.medical = data;
                if (this.medical.healthStatementDate) {
                    this.medical.healthStatement = true;
                }

                this.medical.heightCentimeters = this.medical.height
                this.medical.weightKilograms = this.medical.weight

                break;
            case ReviewItemId.SmokingDeclaration:
                this.smoking = data;
                break;
            case ReviewItemId.YourPoliceRecord:
                this.criminal = data;
                break;
            case ReviewItemId.PreExistingMedicalConditions:
                this.preExistingMedicalConditions = data;
                break;
            case ReviewItemId.CovidVaccination:
                this.covid = data;
                break;
            default:
                break;
        }
    }

    save() {
        this.$scope.$broadcast('submitted', true)

        var promisses = []
        var form = this.$scope.medicalAndCriminalForm
        if (form['MedicalAndHealth'].$dirty) {
            this.medical.metaform = form['MedicalAndHealth'].$metaformVersion
            // medicalAidConfirmation is considered as invalid when option 'no' is selected
            // angularjs doesn't bind value to model when value is invalid
            // this hack is needed to post false when 'no' is selected otherwise medicalAidConfirmation is undefined
            if (form['MedicalAndHealth'].medicalAidConfirmation.$viewValue == false) {
                this.medical.medicalAidConfirmation = false;
            }
            promisses.push(
                this.MedicalDetails.update(this.medical)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.MedicalAndHealth))
            )
        }
            
        if (form['PreExistingMedicalConditions'].$dirty) {
            this.preExistingMedicalConditions.metaform = form['PreExistingMedicalConditions'].$metaformVersion
            // preExistingMedicalConditionsConfirmation is considered as invalid when option 'no' is selected
            // angularjs doesn't bind value to model when value is invalid
            // this hack is needed to post false when 'no' is selected otherwise preExistingMedicalConditionsConfirmation is undefined
            if (form['PreExistingMedicalConditions'].preExistingMedicalConditionsConfirmation.$viewValue == false) {
                this.preExistingMedicalConditions.preExistingMedicalConditionsConfirmation = false;
            }
            promisses.push(
                this.PreExistingMedicalConditionsDetails.update(this.preExistingMedicalConditions)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.PreExistingMedicalConditions))
            )
        }

        if (form['CovidVaccination'].$dirty) {
            this.covid.metaform = form['CovidVaccination'].$metaformVersion
            promisses.push(
                this.CovidVaccinationDetails.update(this.covid)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.CovidVaccination))
            )
        }

        if (form['SmokingDeclaration'].$dirty) {
            this.smoking.metaform = form['SmokingDeclaration'].$metaformVersion
            promisses.push(
                this.SmokingDetails.update(this.smoking)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.SmokingDeclaration))
            )
        }

        if (form['PoliceRecord'].$dirty) {
            this.criminal.metaform = form['PoliceRecord'].$metaformVersion
            promisses.push(
                this.CriminalDetails.update(this.criminal)
                    .$promise
                    .then(() => this.sectionChanged(ReviewItemId.YourPoliceRecord))
            )
        }
            
        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();
               
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.MedicalAndCriminalHistory,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }
}

angular
    .module('app')
    .controller('MedicalAndCriminalController', MedicalAndCriminalController)
