import { IAlertConfig } from '../../../models/bootstrap/Alert' 

class ExtensionTermController { 
    showMessageText: boolean = false;
    alerts: IAlertConfig[] = [];
    details: any;
    displayReadOnly: boolean = false;
    displayOfficeMessage: boolean = false;
    showError: boolean;
    data: any;

    static $inject = ['$rootScope', '$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $scope, private $q, private $timeout, private Auth, private USAuPairs, private Spinner) {

        this.$rootScope.$broadcast('start:extension');
        this.getExtensiontermData();     
    }

    getExtensiontermData() {
        this.Spinner.show();

        this.USAuPairs.getExtensionTerm({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = result.data;
                this.data = result.data.extensionAppReview;

                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.extTermSectionStatus == '3'
                    || this.data.extensionApplicationReview.extTermSectionStatus == '5'
                    || this.data.extensionApplicationReview.extTermSectionStatus == '6')
                        ) || false;

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.extTermSectionStatus == '4') || false;
            })
            .finally(() => {
                this.Spinner.hide();
            }); 
    }

    submit() {
        this.$scope.$broadcast('submitted', true);
        this.showError = false;

        var form = this.$scope.extensionTermForm;
        if (form.$valid) {
            this.Spinner.show();
            var promises = [];
            this.alerts = [];

            this.details.aupairId = this.Auth.user.usAuPairId;
            this.details.months = this.details.auPairApExtensionMonths;
            this.details.isSubmitted = true;

            promises.push(this.USAuPairs.saveExtensionTerm({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

            this.$q.all(promises)
                .then(() => {
                    this.$scope.extensionTermForm.$dirty = false;
                    this.$rootScope.$broadcast('update:extension');
                    this.getExtensiontermData();
                    this.alerts.push({
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                }, () => {
                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                })
                .finally(() => {
                    this.Spinner.hide();
                });
        }
        else {
            this.showError = true;
        }
    }
}
angular
    .module('app')
    .controller('ExtensionTermController', ExtensionTermController);
