import moment from 'moment';
import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService';
import { TerminateContract } from '../../action/terminateContract/terminateContract';
import { CreateNewContract } from '../../action/createNewContract/createNewContract';


class AgencyContractualAgreementController {
    agencyId: number

    alerts: IAlertConfig[] = [];
    agencyContractFees: any[];

    agencyContractData: any;        
    selectedContractId: any;
    canEditable: boolean = true;
    scheduledOfferedAndNewContractsDates: any;
    contractFound: boolean = false;
    isAgencyContractManagementUser: boolean = false;


    static $inject = ['$scope', '$rootScope', '$timeout', 'Auth', 'dialogs', 'AgencyContract', 'AgencyContractFees', '$window', 'agencyId', '$state', '$uibModal'];
    constructor(private $scope, private $rootScope, private $timeout, private Auth, private dialogs, private AgencyContract, private AgencyContractFees, private $window, agencyId, private $state, private $uibModal) {
        this.agencyId = agencyId;

        this.loadData();

        this.isAgencyContractManagementUser = Auth.isInBusinessArea(BusinessArea.AGENCY_CONTRACT_MANAGEMENT);

        $scope.$watch(() => this.selectedContractId, () => {
            if (this.selectedContractId && this.selectedContractId > 0) {
                this.loadContract();
            }
        });

        $scope.$on('new-contract-created', (event, data) => {
            this.loadData();

            //var message = 'New contract has been generated';
            //this.displaySuccessMessage(message);
        })

        $scope.$on('contract-create-error', (event, data) => {
            var message = 'We are sorry but your contract cannot be created. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>';
            this.displayErrorMessage(message);
        })

        $scope.$on('contract-terminated', (event, data) => {
            var message = 'The selected contract has been marked as terminated';
            this.loadData();

            if (data.createNewContract) {
                message = 'The selected contract has been marked as terminated and a new contract has been generated';
            }
            this.displaySuccessMessage(message);
        })

        $scope.$on('contract-terminated-error', (event, data) => {
            var message = 'We are sorry but your contract cannot be terminated. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>';
            this.displayErrorMessage(message);
        })
    }

    loadData() {
        this.AgencyContract.getScheduledOfferedAndNewContracts({ agencyId: this.agencyId }, (result) => {
            this.scheduledOfferedAndNewContractsDates = result;
            if (this.scheduledOfferedAndNewContractsDates.length > 0) {
                if (this.selectedContractId && this.selectedContractId == this.scheduledOfferedAndNewContractsDates[0].contractId) {
                    this.loadContract();
                }

                this.selectedContractId = "" + this.scheduledOfferedAndNewContractsDates[0].contractId;
            }
        });

        this.AgencyContract.checkAgencyHasAnyContractYet({ agencyId: this.agencyId }, (result) => {
            this.contractFound = result.contractFound;
        })
    }

    displaySuccessMessage(message) {
            
        this.alerts.push({
            type: 'success',
            msg: message,
            dismissOnTimeout: 5000
        });

        //this.dialogs.notify('Agency Fee', 'Thank You for submitting your fees. Your contract will be issued shortly.');
    }

    displayErrorMessage(message) {
        if (!message) {
            message = 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>';
        }

        this.alerts.push({ // TODO should I be able to display the same message twice?
            type: 'danger',
            msg: message,
            dismissOnTimeout: 5000
        });
    }

    loadContract() {
        this.AgencyContract.getAgencyContract({ agencyId: this.agencyId, contractId: this.selectedContractId })
            .$promise
            .then((results) => {
                if (results.id) {
                    this.agencyContractData = results;

                    this.setAgencyContractProperty();

                    this.loadContractFees();
                    this.canEditable = (this.Auth.user.isOfficer && !this.agencyContractData.isAccepted);
                }
                else {
                    this.clearData();
                }
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Agency Contract. Please try again after a while.')
            });
    }

    loadContractFees() {
        this.AgencyContractFees.query({ agencyId: this.agencyId, contractId: this.selectedContractId })
            .$promise
            .then((r) => {
                this.agencyContractFees = r;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Agency Contract Fees. Please try again after a while.')
            });
    }

    setAgencyContractProperty() {
        this.agencyContractData.startDate_readonly = function () {
            return this.previousContractId > 0;
        }

        var minDate = this.agencyContractData.endDateMinDate;
        this.agencyContractData.endDate_mindate = function () {
            return minDate;
        } 
        var maxDate = this.agencyContractData.endDateMaxDate;
        this.agencyContractData.endDate_maxdate = function () {
            return maxDate;
        }
    }

    clearData() {
        this.agencyContractData = {};
        this.agencyContractFees = [];
        this.canEditable = false;
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        if (this.$scope.agencyContractForm.$dirty) {
            this.AgencyContract.saveAgencyContract({ agencyId: this.agencyId, contractId: this.selectedContractId }, this.agencyContractData)
                .$promise
                .then((results) => {                       

                    this.$scope.agencyContractForm.$setPristine();

                    this.agencyContractData = results;
                    this.setAgencyContractProperty();

                    if (this.$scope.agencyContractFeesForm.$dirty) {
                        if (this.Auth.user.isOfficer) {
                            if (!this.agencyContractData.isAccepted) {
                                this.saveContractFees();
                            }

                        } else {
                            this.dialogs.confirm('Confirm', 'Do you want to submit your Local Fees? You will not be able to edit Local Fees after submitting')
                                .result
                                .then(() => {
                                    this.saveContractFees();
                                })
                        }
                    } else { 
                            
                        this.alerts.push({ 
                            type: 'success',
                            msg: 'Saved successfully',
                            dismissOnTimeout: 5000
                        });
                    }
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while saving Agency Contract. Please try again after a while.')
                });

        } else{
            if (this.$scope.agencyContractFeesForm.$dirty) {
                if (this.Auth.user.isOfficer) {
                    if (!this.agencyContractData.isAccepted || this.agencyContractData.canEditable) {
                        this.saveContractFees(); 
                    }

                } else {
                    this.dialogs.confirm('Confirm', 'Do you want to submit your Local Fees? You will not be able to edit Local Fees after submitting')
                        .result
                        .then(() => {
                            this.saveContractFees();
                        })
                }
            }
        }
    }

    saveContractFees() {
        this.AgencyContractFees.save({ agencyId: this.agencyId, contractId: this.selectedContractId }, this.agencyContractFees)
            .$promise
            .then(() => {
                this.$scope.agencyContractFeesForm.$setPristine();
                this.displaySuccessMessage('Saved successfully');
                this.loadContractFees();
            }, () => {
                this.displayErrorMessage('');
            });
    }
        
    offerAgencyContract() {
        if (!this.$scope.agencyContractForm.$dirty && !this.$scope.agencyContractFeesForm.$dirty) {
            return this.dialogs.confirm('Issue Contract', `Do you want to issue this contract?`)
                .result
                .then(() => {
                    this.$scope.$broadcast('submitted', true);

                    this.AgencyContract.offerAgencyContract({ agencyId: this.agencyId, contractId: this.agencyContractData.id })
                        .$promise
                        .then((results) => {
                            //this.agencyContractData = results;
                            this.loadData();
                            //this.loadContract(); this should call by itself. I modified in getScheduledOfferedAndNewContracts
                        }, () => {
                            this.dialogs.error('Internal error', 'Error occured while offering contract. Please try again after a while.')
                        });

                });                
        }
        else {
            //it will display message for saving changes before updating
            //todo ask for what to do if form changes are not saved and we are issun=ing contract
            this.$window.location.reload();
        }
    }

    allowResubmitContract() {
        if (!this.$scope.agencyContractForm.$dirty && !this.$scope.agencyContractFeesForm.$dirty) {
            return this.dialogs.confirm('Allow Resubmit Fees', `Do you want to allow the agent to resubmit their Fees?`)
                .result
                .then(() => {
                    //this.$scope.$broadcast('submitted', true);

                    this.AgencyContract.allowResubmitContract({ agencyId: this.agencyId, contractId: this.agencyContractData.id })
                        .$promise
                        .then((results) => {
                            //this.agencyContractData = results;
                            this.loadData();
                            //this.loadContract(); this should call by itself. I modified in getScheduledOfferedAndNewContracts
                        }, () => {
                            this.dialogs.error('Internal error', 'Error occured while offering contract. Please try again after a while.')
                        });

                });
        }
        else {
            //it will display message for saving changes before updating
            //todo ask for what to do if form changes are not saved and we are issun=ing contract
            this.$window.location.reload();
        }
    }
    createAgencyContract() {
        var contractStartDateNew = moment().format('DD MMM YYYY');

        if (this.agencyContractData && this.agencyContractData.nextContractStartDate) {
            contractStartDateNew = moment(this.agencyContractData.nextContractStartDate).format('DD MMM YYYY');
        }

        var message = `Are you sure you want to create a new contract for this agency?<br/>NOTE: The new start date will be ${contractStartDateNew}. This cannot be changed afterwards.`;

        this.dialogs.confirm('Create new Contract', message)
            .result
            .then(() => {
                //create contract
                this.AgencyContract.create({ agencyId: this.agencyId })
                    .$promise
                    .then((results) => {
                        //TODO: reloading page
                        this.loadData();
                        //this.loadContract(); It should load in load data. It is not passing conteractId. If there is no contract eists.
                    }, () => {
                        this.dialogs.error('Internal error', 'Error occured while create contract. Please try again after a while.')
                    });

            });
    }

    terminateAgencyContract() {
        if (this.agencyContractData.canTerminateContract) {
            var action = new TerminateContract(this.$uibModal, this.agencyContractData)
            action.execute();
        }
    }

    createNewAgencyContract() {
        if (this.agencyContractData.canCreateNewContract) {
            var action = new CreateNewContract(this.$uibModal, this.agencyContractData)
            action.execute();
        }
    }

    viewAgencyContract() {
        if (this.agencyContractData && this.agencyContractData.id) {
            var url = this.$state.href('app.agencyInformation.contractualAgreement', { contractId: this.agencyContractData.id });
            this.$window.open(url, '_blank');
        }
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('AgencyContractualAgreementController', AgencyContractualAgreementController)