import _ from 'lodash-es'
import { Notification } from './Notification'

class UserMessagesController {

	me: any;
    selected: any;
    applicantUserId: number;
	newMessageText: string;
    conversations; // ??
    canSendMessage: boolean ;
    isLoading: boolean = true;
    isInterviewerAssigned: boolean;
    notifications: Notification[];
    selectedNotification: Notification;

    static $inject = ['$rootScope', '$scope', 'dialogs', 'Auth', 'Communication', 'recieverUserId', 'applicantUserId', 'applicationObservable']
    constructor(private $rootScope, private $scope, private dialogs, private Auth, private Communication, private recieverUserId, applicantUserId, applicationObservable) {
        this.me = Auth.user;
        this.canSendMessage = Auth.user.isAgent;
        this.applicantUserId = applicantUserId;
        this.conversations = Communication.conversations;

        if (applicationObservable) {
            applicationObservable.subscribe(applicationInstance => {
                this.isInterviewerAssigned = (applicationInstance.interviewerId) ? true : false;
            })
        }

        if (recieverUserId && recieverUserId > 0) {

            this.selectConversationByUserId(recieverUserId);     
                
        } else if (applicantUserId && applicantUserId > 0) {

            this.selectConversationByUserId(applicantUserId);

        } else {

            // show conversation with youngest message
            var newestConversation = _.maxBy(this.conversations, (conversation: any) => conversation.getLastMessageDate());
            if (angular.isUndefined(newestConversation)) return;
                
            newestConversation.loadMessages();

            if (newestConversation) {
                this.selected = newestConversation;
                var canSendMessage = newestConversation.participants.some(p => p.userId == this.me.userId);

                this.selected.canSendMessage = this.canSendMessage = canSendMessage;

                if (this.selected.canSendMessage) {
                    if (newestConversation.unreadCount > 0) {
                        newestConversation.unreadCount = 0;
                        newestConversation.clearUnreadMessages();

                        this.$rootScope.$broadcast('commun:update');
                        this.$rootScope.$broadcast('update_menu:');
                    }
                }

            }
        }

        $scope.$on('commun:update', () => { });

        $scope.$on('addedNewConversation', (event, newConversation) => {
            this.switchConversation(newConversation);
        });
            

        if (!this.canSendMessage && applicantUserId && applicantUserId > 0) {
            this.conversations = [];

            let monthsBack = 4;
            Communication.loadApplicationConversations(applicantUserId, monthsBack).then(c => {
                this.conversations = c;
                var newestConversation = _.maxBy(this.conversations, (conversation: any) => conversation.getLastMessageDate());
                this.switchConversation(newestConversation);
                this.isLoading = false;  
            })
        } else {
            this.isLoading = false;      
        }
	}

	///TODO listen from signalR for a new messages and conversations 

		
    getConversations() {
        if (this.applicantUserId && this.canSendMessage) {
            return _.chain(this.conversations)
                .filter(c => c.participants.some(p => p.userId == this.applicantUserId))
                .map(c => c)
                .value();
        } else {

            var countUnread = 0;

            for (var i = 0; i < this.conversations.length; i++) {
                if (this.conversations[i].unreadCount == 1) {
                    countUnread += 1;
                }
            }

            if (this.conversations.length == 1 || this.conversations.length> 1 && countUnread == 1 && this.conversations[0].unreadCount == 1) {
                if (this.canSendMessage) {
                    // TODO this should be a part of loadMessages method ?
                    if (this.conversations[0].unreadCount > 0) {
                        this.conversations[0].unreadCount = 0;
                        this.conversations[0].clearUnreadMessages();
                    }
                }

            }
            return this.conversations;
        }
	}


	send(text) {
		this.selected
			.sendMessage(this.newMessageText)
            .then(() => {

				this.newMessageText = '';
			});
	}

    select(conversation) {
		if (angular.isString(this.newMessageText) && this.newMessageText.length > 0) {
			this.dialogs
				.confirm('Please Confirm', 'You want to switch to other conversation. You will lost your reply. Are you sure ?')
				.result
				.then(() => {
                    this.newMessageText = '';
                    this.selectedNotification = undefined;
					this.switchConversation(conversation);
				});
        } else {
            this.selectedNotification = undefined;
			this.switchConversation(conversation);
        }
    }

    selectNotificationThread(notification: Notification) {
        this.selected = undefined;

        this.selectedNotification = notification;
    }

    selectedItem() {
        if (angular.isUndefined(this.selected)) {
            return this.selectedNotification;
        }
        else {
            return this.selected;
        }
    }

    selectedName() {
        if (angular.isUndefined(this.selected)) {
            return this.selectedNotification.from;
        }
        else {
            return this.selected.userName;
        }
    }

    areUnredMessages() {
        return this.selectedCounter() > 0;
    }

    selectedCounter() {
        if (angular.isUndefined(this.selected)) {
            return 0;
        }
        else {
            return this.selected.unreadCount;
        }
    }

    //select conversation or create new conversation if not exists.
    selectConversationByUserId(recieverUserId) {
        if (this.conversations) {
            var conversationDetail = _.chain(this.conversations)
                .filter(c => c.participants.some(p => p.userId == recieverUserId))
                .map(c => c)
                .value();

            this.selected = conversationDetail;

            if (conversationDetail && conversationDetail.length > 0) {
                this.switchConversation(conversationDetail[0]);
            }
            else if (this.canSendMessage){
                this.Communication.addConversation({ RecipientId: recieverUserId });
            } //Conversation will not be added for juno user
        }
    }

    isConversationSelected() {
        return !angular.isUndefined(this.selected);
    }

    isNotificationSelected() {
        return !angular.isUndefined(this.selectedNotification);
    }

    stripHtml(item: string) {
        var lines = item.split(/<[p>]+>/g);
        var strippedLines = lines.map(line => line.replace(/<[^>]+>/g, ''));
        return strippedLines;
    }

    private switchConversation(conversation) {
        let canSendMessage = conversation.participants.some(p => p.userId == this.me.userId);

		if (angular.isUndefined(conversation)) return;			

        //office user cannot change unread message status
        conversation.loadMessages();

        if (canSendMessage) {
            // TODO this should be a part of loadMessages method ?
            if (conversation.unreadCount > 0) {
                conversation.unreadCount = 0;
                conversation.clearUnreadMessages();
                this.$rootScope.$broadcast('commun:update');
                this.$rootScope.$broadcast('update_menu:');
            }
        }

        this.selected = conversation;
        this.selected.canSendMessage = this.canSendMessage = canSendMessage;
	}
}


angular
	.module('app')
    .controller('UserMessagesController', UserMessagesController);

