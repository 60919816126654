import { IAlertConfig } from '../../../../models/bootstrap/Alert' 

export class MaintenanceMessagesModalController {

    alerts: IAlertConfig[] = []
    editMode: boolean;
    $dirty: boolean = false;
        
    minDate: Date = new Date();
    maxDate: Date;
    readOnlyForm: boolean;

    static $inject = ['$uibModalInstance', '$scope', 'MaintenanceMessages', 'message', 'preFilled'];
    constructor(private $uibModalInstance, private $scope, private MaintenanceMessages, private message, preFilled) {
        this.$scope.hstep = 1;
        this.$scope.mstep = 15;

        if (preFilled) {
            var message: any = {
                messageText: this.message.messageText,
                editMode: false,
                validFrom: new Date(),
                validUntil: new Date()
            };
            this.message = angular.copy(message, this.message);
        }

        //this.message.editMode = this.message.messageId > 0;

        $scope.$on('date-time-changed', (event, data) => {
            this.$dirty = true;
        })

        $scope.$watch(() => this.$scope.messageForm.$dirty, () => {
            this.$dirty = this.$scope.messageForm.$dirty
        })
    }

    saveMessage() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.messageForm;
        if (this.$dirty && this.message.validFrom && this.message.validUntil) {

            this.MaintenanceMessages.save(this.message)
                .$promise.then(
                    () => {
                        form.$setPristine();
                        this.$uibModalInstance.close();
                    },
                    () => {

                        this.alerts.push({
                            type: 'danger',
                            msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                            dismissOnTimeout: 5000
                        });
                    }
                );
        }
    }

    close() {
        this.$uibModalInstance.dismiss();
    }
}
