import { NoteItemId, ItemGroupId } from '../../../models/ItemGroup'
import { SectionController } from '../SectionController'
import { ReviewPage } from '../../../services/ReviewService'
import { DocumentUploadConfig, MultiDocumentUploader } from '../../../controls/documentsUpload/DocumentUploadConfig'
import { ReviewItemId } from '../../../models/ReviewItem'

class EducationAndWorkController extends SectionController {

    history: any   
    displayApproveButton: boolean;
    isOfficeUser: boolean;
    isApplicant: boolean;
    referenceId: any;
    page: ReviewPage;

    documentUploadConf: DocumentUploadConfig<MultiDocumentUploader>;
    applicationStatus: string;

    static override $inject = ['$rootScope', '$scope', '$q', '$uibModal', 'dialogs', 'EducationAndWorkHistory', 'educationAndWorkPage', 'review', 'applicationId', 'ApplicationUploader', 'ApproveChanges', 'Auth', 'ReevaluateSection', 'applicationObservable'];
    constructor($rootScope, $scope, private $q: ng.IQService, private $uibModal, dialogs, private EducationAndWorkHistory, educationAndWorkPage, review, applicationId, private ApplicationUploader, private ApproveChanges, Auth, ReevaluateSection, applicationObservable, referenceId) {
        super($rootScope, $scope, Auth, ReevaluateSection, review, applicationId, ItemGroupId.EducationAndWorkHistory, applicationObservable, dialogs);
            
        this.referenceId = referenceId;

        this.history = educationAndWorkPage.history;

        this.isOfficeUser = Auth.user.isOfficer;
        this.isApplicant = Auth.user.isApplicant;

        this.page = review.getPage(ItemGroupId.EducationAndWorkHistory);
        this.displayApproveButton = this.page && this.page.approveChange && this.isOfficeUser;

        $scope.$watch(() => this.$scope.educationAndWorkForm.$dirty, () => {
            this.$dirty = this.$scope.educationAndWorkForm.$dirty
        })

        applicationObservable.subscribe(i => {
            this.applicationStatus = i.status;
        })

        $scope.$on('educationAndWork:refresh', (nam, type, data) => {
            this.refreshSection(type, data);
        })
	}

    refreshSection(type, data) {
        if (type == ReviewItemId.EducationAndWorkHistory) {
            //this.history = data;
            var WorkOrEducationData = this.history.find(i => i.id == data.id);
            angular.copy(data, WorkOrEducationData);
        }
    }

	private getTemplateUrl(entry) {
		return entry.historyType === 'E'
			? 'areas/application/educationAndWork/educationHistoryModal.html'
			: 'areas/application/educationAndWork/workHistoryModal.html';
	}

	addEducationHistoryEntry() {
        this.addHistoryEntry('Add education entry', { applicationId: this.applicationId, historyType: 'E' });
	}

	addWorkHistoryEntry() {
        this.addHistoryEntry('Add work entry', { applicationId: this.applicationId, historyType: 'W' });
	}


    private addHistoryEntry(title, entry) {
		this.$uibModal.open({
			templateUrl: this.getTemplateUrl(entry),
			controller: 'EducationAndWorkModalController as eduWorkCtrl',
			backdrop: false,
			resolve: {
				title: () => title,
				historyEntry: () => entry
			}
		}).result.then((data) => {
			this.history.push(data);
		});
	}

    editHistoryEntry(entry) {
		var applicationId = this.applicationId;
        var title = entry.historyType === 'W' ? 'Edit work entry' : 'Edit education entry';
		this.$uibModal.open({
			templateUrl: this.getTemplateUrl(entry),
			controller: 'EducationAndWorkModalController as eduWorkCtrl',
			backdrop: false,
			resolve: {
				applicationId: () => applicationId,
				title: () => title,
				historyEntry: () => angular.copy(entry)
			}
		}).result.then((data) => {
            var i = this.history.indexOf(entry);

            if (i > -1) {
                this.history[i] = data;
                this.sectionChanged(ReviewItemId.EducationAndWorkHistory, data.id);
            }
		});
	}

	removeHistoryEntry(entry) {
		this.dialogs
			.confirm('Please Confirm', 'Are you sure that you wish to remove this entry?')
			.result
			.then(() => {
				this.EducationAndWorkHistory
					.remove({ applicationId: this.applicationId, id: entry.id })
					.$promise
                    .then(() => {
                        this.$scope.$root.$broadcast('note:removeNoteByAssignId', { groupId: ItemGroupId.EducationAndWorkHistory, itemGroupId: NoteItemId.EducationAndWorkHistory, assignId: entry.id });

						var i = this.history.indexOf(entry);
						if (i > -1) this.history.splice(i, 1);
					}, () => {
						this.dialogs.error('Error', 'We are sorry but your entry could not be deleted. Please try again in 5 minutes. If this problem continues, please contact us at: <a target="_blank" href="mailto:info@aupairamerica.co.uk">info@aupairamerica.co.uk<a>');
					});
			});
    }

    getEducationConfig(entry) {
        this.referenceId = entry.id;

        if (!entry.data) {
            this.documentUploadConf = {
                uploader: new this.ApplicationUploader(this.applicationId, 18, entry.id),
                onUpdateHandler: () => {
                    // This is called when applicant add an image OR management unapprove an image
                    entry.documentStatusDesc = "View Certificate";                                               
                },
                onDeleteHandler: () => {
                    // This is called when applicant deletes an image
                    entry.documentStatusDesc = "Upload Certificate";                        
                },
                onApproveHandler: () => {
                    // This is called when management approves an image
                    entry.documentStatusDesc = "View Approved Certificate";
                }
            }
        }
        //return entry.data
    }


	save() {
		this.$scope.$broadcast('submitted', true);

		var promisses = [];
		var form = this.$scope.educationAndWorkForm;

		this.$q.all(promisses).then(() => {
            form.$setPristine();
            //TODO move this to parent class and remove saved flag
            this.alerts.push({ // TODO should I be able to display the same message twice?
                type: 'success',
                msg: 'Save complete',
                dismissOnTimeout: 5000
            })
		}, () => {
            //TODO move this to parent class and remove saved flag
            this.alerts.push({ // TODO should I be able to display the same message twice?
                type: 'danger',
                msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                dismissOnTimeout: 20000
            })
		});
    }

    changesApproved() {

        var user = this.Auth.user
        var data = {
            applicationId: this.applicationId,
            item: ItemGroupId.EducationAndWorkHistory,
            author: user.firstName + ' ' + user.lastName
        };

        this.ApproveChanges
            .update({ applicationId: this.applicationId }, data)
            .$promise
            .then(data => {
                if (this.displayApproveButton === true) {
                    this.displayApproveButton = false;
                    this.page.approveChange = false;
                }

                this.alerts.push({
                    type: 'success',
                    msg: 'Approval complete',
                    dismissOnTimeout: 5000
                });
            }, () => {
                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but this form could not be approved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            });
    }

}

angular
	.module('app')
	.controller('EducationAndWorkController', EducationAndWorkController);

