import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class MaintainAgreementsModalController {

    alerts: IAlertConfig[] = []
    editMode: boolean;
    $dirty: boolean = false;
    readOnlyForm: boolean;
    isCopyPreviousText: boolean = false;

    static $inject = ['$uibModalInstance', 'dialogs', '$state', '$scope', 'MaintainAgreements', 'agreement','Auth'];
    constructor(private $uibModalInstance, private dialogs, private $state, private $scope, private MaintainAgreements, private agreement, Auth) {

        $scope.$watch(() => this.$scope.agreementForm.$dirty, () => {
            this.$dirty = this.$scope.agreementForm.$dirty;
        })

        $scope.$watch(() => this.agreement.isCopyPreviousText, (newValue, oldValue) => {
            if (newValue) {
                this.copyPreviousText();
            }
        });

        $scope.$watch(() => this.agreement.type, (newValue, oldValue) => {
            if (newValue) {
                this.agreement.isCopyPreviousText = false;
            }
        });
        this.setAgreementProperty();
    }

    setAgreementProperty() {
        var currentDate = new Date();

        this.agreement.type_readOnly = function () {
            return this.id > 0;
        }

        this.agreement.startDate_mindate = function () {
            return currentDate;
        }

        var maxDate = this.agreement.validFrom;
        this.agreement.endDate_maxdate = function () {
            return maxDate;
        }
    }

    copyPreviousText() {
        if (this.agreement.id == 0 && this.agreement.isCopyPreviousText && this.agreement.type && !this.agreement.messageText) {
            this.MaintainAgreements.current({ type: this.agreement.type })
                .$promise
                .then((currentAgreement) => {
                    if (currentAgreement) {
                        this.agreement.text = currentAgreement.text;
                    }
                }, () => {
                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are unable to get current agreement at this moment. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
                });
        }
    }

    saveAgreement() {
        this.$scope.$broadcast('submitted', true);

        var form = this.$scope.agreementForm;
        if (this.$dirty && form.$valid && this.agreement.validFrom) {

            //if change anything in the form before today's date
            //if it published then can save only when it's start date is less than today's date.
            if (this.agreement.isPublished) {
                this.dialogs.confirm('Please Confirm', 'You have made a change to this published (but not active) agreement - saving this change will revert this agreement to Draft status')
                    .result
                    .then(() => {
                        this.agreement.isPublished = false;
                        this.save(form);
                    });
            }
            else {
                this.save(form);
            }
        }
    }
    save(form) {
        this.MaintainAgreements.saveAgreement(this.agreement)
            .$promise.then(
                () => {
                    form.$setPristine();

                    this.$uibModalInstance.close();
                },
                () => {
                    this.alerts.push({
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
                }
            );
    }

    previewAgreement() {
        var url = this.$state.href('app.tools.agreementPreview', { id: this.agreement.id });
        if (url) {
            window.open(url, '_blank');
        }
    }

    publishMessageConfirmText() {
        return `<p>Please confirm you wish to publish this agreement.</p> <p>Doing so will terminate the current active agreement of this type on the date you have specified <strong>${this.agreement.validFrom.toDateString()}</strong> and all appropriate people will be presented with the new agreement after that point.</p> <br/><strong>Agreement Start Date:</strong> ${this.agreement.validFrom.toDateString()} <br/><strong>Agreement End Date:</strong> ${this.agreement.validTo.toDateString()} <br/><strong>Agreement Type:</strong> ${this.agreement.type}`;
    }

    saveAndPublishAgreement() {
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.agreementForm;
        if (form.$valid && (this.$dirty || !this.agreement.isPublished) && this.agreement.validFrom && this.agreement.validTo) {
            this.dialogs.confirm('Please Confirm', this.publishMessageConfirmText())
                .result
                .then(() => {
                    this.agreement.isPublished = true;

                    this.MaintainAgreements.saveAgreement(this.agreement)
                        .$promise.then(
                            () => {
                                form.$setPristine();
                                this.$uibModalInstance.close();
                            },
                            () => {
                                this.agreement.isPublished = false;
                                this.alerts.push({
                                    type: 'danger',
                                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                                    dismissOnTimeout: 5000
                                });
                            }
                        );
                });
        }
    }

    close() {
        this.$uibModalInstance.close();
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}
