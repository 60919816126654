import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController';

class EditChildCareExperianceController extends EditApplicationController {

    preferences: any;
    //applicationId: number

    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    static override $inject = ['$scope', 'Auth', '$uibModal', '$q', 'dialogs', 'applicationId', 'childcareExperiencePage', 'ChildcarePreferences','ApplicationReviewItemsAny'];
    constructor($scope, private Auth, private $uibModal, private $q, private dialogs, applicationId, childcareExperiencePage, private ChildcarePreferences, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);

        this.applicationId = applicationId;
        this.preferences = childcareExperiencePage.preferences;

        $scope.$watch(() => this.$scope.childcareForm.$dirty, () => {
            this.$dirty = this.$scope.childcareForm.$dirty
        });
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.childcareForm;            

        if (form['ChildcareExperience'].$dirty) {
            this.preferences.metaform = form['ChildcareExperience'].$metaformVersion;
            promisses.push(
                this.ChildcarePreferences.update(this.preferences).$promise);
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })

                this.CheckAnyReviewPending(this.applicationId);
            }, () => {
                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 20000
                })
            })
    }
}

angular
    .module('app')
    .controller('EditChildCareExperianceController', EditChildCareExperianceController)