
class UploadStatus {
    static upload = 'upload';
    static failed = 'failed';
    static success = 'success';
}

class ResourcesGroupModalController {

    static $inject = ['$scope', '$q', '$uibModalInstance', 'dialogs', 'Upload', 'Resource', 'resource'];

    file;
    promise;
    status: UploadStatus;
    progress: number;
    errorMessage: string;

    documentTypeId: number;


    constructor(
        private $scope,
        private $q,
        private $uibModalInstance,
        private dialogs,
        private Upload,
        private Resource,
        private resource) {

            $scope.$watch(() => this.resource.title, () => {
            this.errorMessage = undefined;
        });
    }

    validate() {
        this.$scope.$broadcast('submitted', true);

        return this.resource.$validation();
    }

    isInProgress() {
        return this.status === UploadStatus.upload;
    }

    needFile() {
        return this.resource.typeId && this.resource.typeId !== 1;
    }

    progressType() {
        switch (this.status) {
            case UploadStatus.upload:
                return 'info';
            case UploadStatus.success:
                return 'success';
            case UploadStatus.failed:
                return 'danger';
            default:
                return '';
        }
    }

    fileSelected($files, $event) {
        if (!angular.isArray($files) || $files.length !== 1) {
            return;
        }

        this.file = $files[0];
    }

    getUrlForDisplay(url: string) {
        if (url === '' || url === undefined) {
            return url;
        }

        return url.split('?')[0];
    }

    upload() {
        const extension = this.file.name.slice(this.file.name.lastIndexOf('.'));

        return this.Resource.prepareUpload({
                name: this.resource.title,
                isPrivate: this.resource.isPrivate,
                extension })
            .$promise
            .then((uploadDescription) => this.uploadItems(uploadDescription), error => this.errorMessage = error.data.message)
            .then(() => {
                if (!this.isError()) {
                    this.$uibModalInstance.close();
                }
            });
    }

    isError() {
        return this.errorMessage !== undefined;
    }

    uploadItems(uploadDescription) {
        this.status = 'upload';

        const key = uploadDescription.filePattern;

        this.promise = this.Upload.upload({
            url: 'https://' + uploadDescription.bucket + '.s3.amazonaws.com/',
            method: 'POST',
            fields: {
                key: key,
                AWSAccessKeyId: uploadDescription.awsAccessKeyId,
                acl: uploadDescription.acl,
                policy: uploadDescription.policy,
                signature: uploadDescription.signature,
                'Content-Type': this.file.type
            },
            file: this.file
        });

        return this.promise.then((data, status, headers, config) => {
            this.status = 'success';
            this.progress = 100;
            return this.Resource.uploadSuccess({
                documentId: uploadDescription.documentId,
                file: key,
                resource: this.resource
            }).result;
        }, () => {
            this.status = 'failed';
            this.progress = 100;
            this.Resource.uploadFailed({
                documentId: uploadDescription.documentId,
                errorCode: 1,
                errorMessage: ''
            });
            return this.$q.reject();
        }, (evt) => {
            this.progress = Math.round(100.0 * evt.loaded / evt.total);
        });

    }

    canUploadResource() {
        if (this.needFile()) {
            return this.file !== undefined;
        } else {
            return false;
        }
    }

    removeItem() {
        delete this.file;
    }

    abort() {
        if (this.promise) {
            this.promise.abort();
        }
    }

    cancel() {
        this.$uibModalInstance.close(false);
    }

    save() {
        if (!this.validate()) {
            return;
        }
        this.Resource.save(this.resource).$promise.then(() => {
            this.$uibModalInstance.close();
        });
    }

    delete() {
        this.Resource.remove({ id: this.resource.id }).$promise.then(() => {
            this.$uibModalInstance.close();
        });
    }
}

angular
    .module('app')
    .controller('ResourcesGroupModalController', ResourcesGroupModalController);
