import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class BasicDetailsController {

    alerts: IAlertConfig[] = []

    static $inject = ['$scope', 'Branches', 'branch'];
    constructor(private $scope, private Branches, private branch) {
    }

    save() {
        this.$scope.$broadcast('submitted', true);
        this.alerts = [];

        if (this.$scope.basicDetailsForm.$dirty) {
            this.Branches
                .save(this.branch)
                .$promise
                .then(() => {
                    this.$scope.basicDetailsForm.$setPristine();

                    this.alerts.push({ 
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 5000
                    });
                }, () => {
                    this.alerts.push({ 
                        type: 'danger',
                        msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                        dismissOnTimeout: 5000
                    });
                })
        }
            
    }
}    

