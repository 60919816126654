import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { EditApplicationController } from './EditApplicationController'

interface InterviewReportScope extends ng.IScope {
    interviewReportForm: any;
}

class Section {
    note: any = {}
}

class EditInterviewReportMotivationController extends EditApplicationController {        
    //$dirty: boolean = false

    //alerts: IAlertConfig[] = []

    applicationInstance

    static override $inject = ['$scope', '$q', 'InterviewReport', 'interviewReport', 'applicationId', 'applicationObservable', 'Auth','ApplicationReviewItemsAny'];
    constructor(
        $scope: InterviewReportScope,
        private $q: ng.IQService,
        private InterviewReport,
        public interviewReport,
        applicationId,
        applicationObservable, ApplicationReviewItemsAny) {
        super($scope, applicationId, ApplicationReviewItemsAny);

        if (!this.interviewReport.applicationId)
            this.interviewReport.applicationId = applicationId;

        applicationObservable.subscribe(applicationInstance => {
            this.applicationInstance = applicationInstance
            if (!this.interviewReport.interviewerId)
                this.interviewReport.interviewerId = applicationInstance.interviewerId;
        })

            

        if (!this.interviewReport['motivation']) {
            this.interviewReport['motivation'] = new Section();
        }
            
        $scope.$watch(() => this.$scope.interviewReportForm.$dirty, () => {
            this.$dirty = this.$scope.interviewReportForm.$dirty;
        })
    }

    save() {
        this.$scope.$broadcast('submitted', true);

        var promisses = [];
        var form = this.$scope.interviewReportForm;
        if (form.$dirty) {
            promisses.push(
                this.InterviewReport.update(this.interviewReport).$promise);
        }

        this.$q.all(promisses)
        .then(() => {
            form.$setPristine();
            //TODO move this to parent class and remove saved flag
            this.alerts.push({ // TODO should I be able to display the same message twice?
                type: 'success',
                msg: 'Save complete',
                dismissOnTimeout: 5000
            })

            this.CheckAnyReviewPending(this.applicationId);
        }, () => {
            //TODO move this to parent class and remove saved flag
            this.alerts.push({ // TODO should I be able to display the same message twice?
                type: 'danger',
                msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                dismissOnTimeout: 20000
            })
        });
    }
}

angular
    .module('app')
    .controller('EditInterviewReportMotivationController', EditInterviewReportMotivationController)