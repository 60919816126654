
// ***************************************************************************************************************************
// filename:    navigation.js
// author:      Jason Gilbert and Pawel Szajkowski
// created:     21-Aug-2014
// description: Provides the navigation logic/structures based on the ui-router plugin
// notes/mods:  n/a
// ***************************************************************************************************************************
import { AccessLevelView } from '../accessLevel';
import { RoutingProvider, state } from '../../services/RoutingService';

import { ContactCommissionsController } from '../../areas/contact/ContactDetails/ContactCommissionsController';
import { PaymentDetailsController } from '../../areas/contact/ContactDetails/PaymentDetailsController';
import { BusinessArea } from '../../services/AuthService';

function contactTitle(subsection) {
    return ['interviewer', function (interviewer) {
        return `${subsection} - ${interviewer.id} - ${interviewer.firstName} ${interviewer.lastName}`;
    }]
}

class RoutingConfig {
    static $inject = ['RoutingProvider'];
    constructor(RoutingProvider: RoutingProvider<AccessLevelView>) {

        const contactList: state<AccessLevelView> = {
            name: 'contacts',
            url: '/contacts',
            views: {
                content: {
                    staff: {
                        controller: 'ContactQueueController as ctrl',
                        template: 'areas/contact/queue.html'
                    }
                }
            },
            resolve: {
                $title: () => 'Contacts'
            }
        };


        RoutingProvider.addState(contactList, 'app');

        const contactInformation: state<AccessLevelView> = {
            name: 'contactInformation',
            url: '/contacts/{contactId:int}',
            resolve: {
                contactId: ['$stateParams', function ($stateParams) {
                    return $stateParams.contactId;
                }],
                interviewer: ['Interviewer', 'contactId', function (Interviewer, contactId) {
                    return Interviewer.get({ id: contactId }).$promise
                }],
                contactPaymentDetails: ['ContactPaymentDetails', 'contactId', 'Auth', (ContactPaymentDetails, contactId, Auth) => {
                    if (Auth.isInBusinessArea(BusinessArea.FINANCE_ADMINISTRATOR)) {
                        return ContactPaymentDetails.get({ contactId: contactId }).$promise
                    }                    
                }]
            },
            views: {
                content: {
                    office: {
                        controller: 'ContactInformationController as ctrl',
                        template: 'areas/contact/ContactInformations/contactInformations.html'
                    }
                }
            },
            states: [
                {
                    name: 'contactBasicDetails',
                    url: '/basicDetails',
                    views: {
                        '': {
                            office: {
                                controller: 'ContactBasicDetailsController as ctrl',
                                template: 'areas/contact/ContactDetails/contactBasicDetails.html'
                            }
                        }
                    },
                    resolve: {
                        ContactBasicDetailsPage: ['ContactBasicDetailsPage', 'contactId', function (ContactBasicDetailsPage, contactId) {
                            return ContactBasicDetailsPage.get({ contactId: contactId }).$promise;
                        }],
                        $title: contactTitle('Basic details')
                    }
                },
                {
                    name: 'contactAddress',
                    url: '/address',
                    views: {
                        '': {
                            office: {
                                controller: 'ContactAddressController as ctrl',
                                template: 'areas/contact/ContactDetails/contactAddress.html'
                            }
                        }
                    },
                    resolve: {
                        ContactAddressPage: ['ContactAddressPage', 'contactId', function (ContactAddressPage, contactId) {
                            return ContactAddressPage.get({ contactId: contactId }).$promise;
                        }],
                        $title: contactTitle('Address')
                    }
                },
                {
                    name: 'contactPayments',
                    url: '/payments',
                    views: {
                        '': {
                            office: {
                                controller: 'ContactPaymentsController as ctrl',
                                template: 'areas/contact/ContactDetails/contactPayments.html'
                            }
                        }
                    },
                    resolve: {
                        paymentInfo: ['Payments', 'contactId', function (Payments, contactId) {
                            return Payments.contactInfo({ contactId: contactId }).$promise;
                        }],
                            
                        $title: contactTitle('Payments')
                    }
                },
                {
                    name: 'contactComments',
                    url: '/comments',
                    views: {
                        '': {
                            office: {
                                controller: 'ContactCommentsController as ctrl',
                                template: 'areas/contact/ContactDetails/contactComments.html'
                            }
                        }
                    },
                    resolve: {
                        $title: contactTitle('Comments')
                    }
                },
                {
                    name: 'commissions',
                    url: '/commissions',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: ContactCommissionsController,
                                template: 'areas/contact/ContactDetails/contactCommissions.html'
                            }
                        }
                    },
                    resolve: {
                        $title: contactTitle('Commissions')
                        //contactPaymentDetails: ['ContactPaymentDetails', 'contactId', (ContactPaymentDetails, contactId) => {
                        //    return ContactPaymentDetails.get({ contactId: contactId }).$promise
                        //}]
                    }
                },
                {
                    name: 'paymentDetails',
                    url: '/paymentDetails',
                    views: {
                        '': {
                            controllerAs: '$ctrl',
                            office: {
                                controller: PaymentDetailsController,
                                template: 'areas/contact/ContactDetails/paymentDetails.html'
                            }
                        }
                    },
                    resolve: {
                        $title: contactTitle('PaymentDetails')
                        //contactPaymentDetails: ['ContactPaymentDetails', 'contactId', (ContactPaymentDetails, contactId) => {
                        //    return ContactPaymentDetails.get({ contactId: contactId }).$promise
                        //}]
                    }
                },
                {
                    name: 'interviewerTraining',
                    url: '/training',
                    views: {
                        '': {
                            staff: {
                                controller: 'InterviewerTrainingController as ctrl',
                                template: 'areas/profile/interviewerTraining/interviewerTraining.html'
                            }
                        }
                    },
                    resolve: {
                        $title: () => 'Interviewer Training'
                    }
                }
            ]
        };

        RoutingProvider.addState(contactInformation, 'app');

        const data: state<AccessLevelView> = {
            name: 'contact',
            url: '/contact',
            abstract: true,
            views: {
                content: {                    
                    anon: {
                        template: 'areas/contact/applicant.html'
                    },
                    preInterview: {
                        template: 'areas/contact/applicant.html'
                    },
                    preloggedin: {
                        template: 'areas/contact/applicant.html'
                    },
                    applicant: {
                        template: 'areas/contact/applicant.html'
                    }
                }
            },
            resolve: {
                applicationId: ['Auth', function (Auth) {
                    if (Auth.user && Auth.user.roleDetails) { //in some cases we get errors so safe check
                        return Auth.user.roleDetails.applicationId;
                    }
                    return null;
                }],
            },
            states: [
                {
                    name: 'interviewer',
                    url: '/interviewer',
                    views: {
                        '': {
                            everyone: {
                                controller: 'ContactInterviewerController as ctrl',
                                template: 'areas/contact/interviewer.html'
                            }
                        }
                    }
                },
                {
                    name: 'london',
                    url: '/london',
                    views: {
                        '': {
                            everyone: {
                                controller: 'ContactController as ctrl',
                                template: 'areas/contact/londonOffice.html'
                            }
                        }
                    }
                },
                {
                    name: 'agency',
                    url: '/agency',
                    views: {
                        '': {
                            everyone: {
                                controller: 'ContactAgencyController as ctrl',
                                template: 'areas/contact/agency.html'
                            }
                        }
                    }
                }
            ]
        };

        RoutingProvider.addState(data, 'app');
    }
}

angular
    .module('app')
    .config(RoutingConfig);