
export class AltosController {    

    applicationId;

    static $inject = ['$scope', '$q', '$state', 'applicantId'];
    constructor(private $scope, private $q, private $state, private applicantId) {
        this.applicantId;
    }

    //writeEmailToSelectedApplicant() {
    //    var user = this.Auth.user;

    //    var data = {
    //        applicantId: this.applicantId,
    //        userId: user.userId,
    //        senderName: user.firstName + ' ' + user.lastName,
    //        senderEmailId: user.userName
    //    };

    //    this.AltasEmail.send(data)
    //        .$promise
    //        .then(() => {
    //            this.dialogs.notify('send email successfully');
    //        }, () => {
    //            this.dialogs.error('Error', 'We are sorry but Email cannot be send. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>')
    //        });
    //}
}
angular
    .module('app')
    .controller('AltosController', AltosController);