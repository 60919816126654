
import { IAlertConfig } from '../../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../../services/AuthService';

class AgencyTargetModalController {

    isAgencyManagmentAllowed: boolean = false;

    showMessageText: boolean = false
    alerts: IAlertConfig[] = []

    static $inject = ['$scope', '$uibModalInstance', 'AgencyTarget', 'agencyTarget', 'Auth', '$timeout', 'editMode']
    constructor(private $scope, private $uibModalInstance, private AgencyTarget, private agencyTarget, Auth, private $timeout, private editMode) {

        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);

        $scope.$watch(() => this.agencyTarget.auPair, () => {
            this.setTargetTotal();
        });

        $scope.$watch(() => this.agencyTarget.educare, () => {
            this.setTargetTotal();
        });

        $scope.$watch(() => this.agencyTarget.apex, () => {
            this.setTargetTotal();
        });
    }

    setTargetTotal() {
        this.agencyTarget.targetTotal = this.agencyTarget.auPair + this.agencyTarget.educare + this.agencyTarget.apex;
    }

    validate() {
        this.$scope.$broadcast('submitted', true)
        return this.agencyTarget.$validation();
    }

    cancel() {
        this.$uibModalInstance.dismiss('cancel')
    }

    save() {
        if (!this.validate()) {
            return
        }

        this.alerts = [];

        this.AgencyTarget.saveAgencyTarget({ agencyId: this.agencyTarget.agencyId }, this.agencyTarget)
            .$promise.then(() => {
                this.$uibModalInstance.close()
            },
            () => {

                this.alerts.push({
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                })
                this.showMessageText = true;
                this.$timeout(() => { this.showMessageText = false; }, 5000);
            });
            
    }
}

angular
    .module('app')
    .controller('AgencyTargetModalController', AgencyTargetModalController)