import { IAlertConfig } from '../../../models/bootstrap/Alert' 
import { BusinessArea } from '../../../services/AuthService'

class AgencyAddressController {
    agencyAddress: any
    agencyId: number
    isAgencyManagmentAllowed: boolean = false;

    $dirty: boolean = false
    alerts: IAlertConfig[] = []

    static $inject = ['$scope', 'Auth', '$q', 'agencyAddressPage', 'AgencyAddress'];
    constructor(private $scope, private Auth, private $q, agencyAddressPage, private AgencyAddress) {
        this.agencyId = agencyAddressPage.agencyId;
        this.agencyAddress = agencyAddressPage;

        this.isAgencyManagmentAllowed = Auth.isInBusinessArea(BusinessArea.AGENCY_MANAGEMENT);

        $scope.$watch(() => this.$scope.agencyAddressForm.$dirty, () => {
            this.$dirty = this.$scope.agencyAddressForm.$dirty
        })
    }
    save() {
        this.$scope.$broadcast('submitted', true);
        var promisses = [];
        this.alerts = [];

        var form = this.$scope.agencyAddressForm;

        if (form['AgencyAddress'].$dirty) {
            this.agencyAddress.metaform = form['AgencyAddress'].$metaformVersion
            promisses.push(
                this.AgencyAddress.saveAgencyAddress({ agencyId: this.agencyAddress.agencyId}, this.agencyAddress).$promise)
        }

        this.$q.all(promisses)
            .then(() => {
                form.$setPristine();

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'success',
                    msg: 'Save complete',
                    dismissOnTimeout: 5000
                })
            }, () => {

                //TODO move this to parent class and remove saved flag
                this.alerts.push({ // TODO should I be able to display the same message twice?
                    type: 'danger',
                    msg: 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>',
                    dismissOnTimeout: 5000
                })
            })
    }

    closeAlert(index: number) {
        this.alerts.splice(index, 1)
    }
}

angular
    .module('app')
    .controller('AgencyAddressController', AgencyAddressController)