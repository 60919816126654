

class FeeSheetDirective implements ng.IDirective {
    restrict = 'AE'
    controller = FeeSheetController
    controllerAs = 'ctrl'
    bindToController = true
    templateUrl = 'controls/feeSheet/feeSheet.html'
    scope = {
        contractId: '=',
        countryCode: '=',
        agencyId: '=',
        financialPeriodId: '=',
        applicationId:'='
    }
}

class FeeSheetController {

    showFees: boolean;

    contractId: number;
    countryCode: string;
    agencyId: number;
    financialPeriodId: number;
    applicationId: number;

    agencyContractFees: any[];
    otherFees: any[];
    contract: any;

    static $inject = ['$filter', '$scope', 'dialogs', 'AgencyApexPromotionsDiscounts', 'AgencyContractFees', 'AgencyContract'];
    constructor(private $filter, private $scope, private dialogs, private AgencyApexPromotionsDiscounts, private AgencyContractFees, private AgencyContract) {

        $scope.$watch(() => this.contractId, (newVal, oldVal) => {
            if (newVal != oldVal) this.loadFees();
        });

        $scope.$watch(() => this.countryCode, (newVal, oldVal) => {
            if (newVal != oldVal) this.loadFees();
        });

        $scope.$watch(() => this.agencyId, (newVal, oldVal) => {
            if (newVal != oldVal) this.loadFees();
        });

        $scope.$watch(() => this.financialPeriodId, (newVal, oldVal) => {
            if (newVal != oldVal) this.loadFees();
        });
    }

    $onInit() {
        this.loadFees();
    }


    loadFees() {
        this.showFees = true;
        if (this.contractId && this.agencyId && this.countryCode && this.financialPeriodId) {

        this.AgencyContract.getScheduledOfferedAndNewContracts({ agencyId: this.agencyId }, (result) => {
            for (let contract of result) {
                if (contract.contractId == this.contractId) {
                    this.contract = contract
                }
            }
        });

        this.AgencyContractFees.queryByApplication({ agencyId: this.agencyId }, { contractId: this.contractId, countryCode: this.countryCode })
            .$promise
            .then((r) => {
                this.agencyContractFees = r;
            }, () => {
                this.dialogs.error('Internal error', 'Error occured while getting Agency Contract Fees. Please try again after a while.')
            });

            //load promotion and discounts
            this.AgencyApexPromotionsDiscounts.query({ agencyId: this.agencyId }, { applicationid: this.applicationId, financialPeriodId: this.financialPeriodId, countryCode: this.countryCode })
                .$promise
                .then((r) => {
                    this.otherFees = r;
                    for (let fee of this.otherFees) {
                        if (fee.availableDiscounts) {
                            for (let discount of fee.availableDiscounts) {
                                discount.programs = `${this.getPromotion(discount)}`;
                                discount.validDateText = `${this.getDate(discount)}`;
                            }
                        }
                    }
                }, () => {
                    this.dialogs.error('Internal error', 'Error occured while getting Agency Promotions and Discounts. Please try again after a while.')
                });
        } else {
            this.showFees = false;
        }
    }

    getDate(discount) {
        var filter = this.$filter('date');
        var validFromFormat = 'dd MMM';
        var validToFormat = 'dd MMM yyyy';

        //if (discount.validFrom.getFullYear() != discount.validTo.getFullYear()) {
        //    validFromFormat = validToFormat += ' yyyy'
        //}
        //else {
        //    //if both year are same then no need to display year in valid from
        //    validToFormat += ' yyyy';
        //}

        return `${filter(discount.validFrom, validFromFormat)} - ${filter(discount.validTo, validToFormat)}`
    }

    getPromotion(discount) {
        var programs = [];
        if (!discount.programAP || !discount.programED || !discount.programEX) {
            if (discount.programAP && discount.programEX) programs.push('APIA/APEX')
            else {
                if (discount.programAP) programs.push('APIA')
                if (discount.programEX) programs.push('APEX')
            }

            if (discount.programED) programs.push('EduCare')
        }
        return programs.join(',')
    }

}

angular
    .module('app')
    .directive('feeSheet', () => new FeeSheetDirective());