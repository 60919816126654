
class ApplicationPrintController {
        
    applicantHobbies: any;
    aboutYou: any;
    hostFamilyLetter: any;
    availability: any;
    contactDetails: any;
    emergencyContact: any;
    drivingDetails: any;
    prematch: any;
    passport: any;
    visa: any;
    preferences: any;
    previousExperience: any;
    dce: any;
    history: any;
    medical: any;
    criminal: any;

    static $inject = ['$scope', 'applicantHobbies', 'profilePage', 'personalDetailsPage', 'travelAndProgramPage', 'childcareExperiencePage', 'educationAndWorkPage', 'medicalAndCriminalPage'];
    constructor(private $scope, applicantHobbies, profilePage, personalDetailsPage, travelAndProgramPage, childcareExperiencePage, educationAndWorkPage, medicalAndCriminalPage) {
        this.applicantHobbies = applicantHobbies;
        this.aboutYou = profilePage.aboutYou;
        this.hostFamilyLetter = profilePage.hostFamilyLetter;


        this.availability = personalDetailsPage.availability;
        this.contactDetails = personalDetailsPage.contact;
        this.emergencyContact = personalDetailsPage.emergencyContact;
        this.drivingDetails = personalDetailsPage.driving;

        this.prematch = travelAndProgramPage.prematch;
        this.passport = travelAndProgramPage.passport;
        this.visa = travelAndProgramPage.visa;

        this.preferences = childcareExperiencePage.preferences;
        this.previousExperience = childcareExperiencePage.previousExperience;
        this.dce = childcareExperiencePage.dce;

        this.history = educationAndWorkPage.history;

        this.medical = medicalAndCriminalPage.medical;
        this.criminal = medicalAndCriminalPage.criminal;
    }
        
}

angular
    .module('app')
    .controller('ApplicationPrintController', ApplicationPrintController);