import { IAlertConfig } from '../../../models/bootstrap/Alert' 

export class usContact {
    usCellPhone: number
    otherPhone: number
    preferredMethod: string
    otherEmailAddress: string
    skypeId: string
    bestTime: string
    okToText: boolean
    whatsAppNo: number
    preferedMethod: string
    aupairId: string
    isSubmitted: boolean
       
}

class ExtensionContactInfoController {        
    showMessageText: boolean = false
    alerts: IAlertConfig[] = []
    details = new usContact();
    displayReadOnly: boolean = false;
    displayOfficeMessage: boolean = false;
    message: string;
    data: any;

    static $inject = ['$rootScope', '$scope', '$q', '$timeout', 'Auth', 'USAuPairs', 'Spinner']
    constructor(private $rootScope, private $scope, private $q, private $timeout, private Auth, private USAuPairs, private Spinner) {                     
        this.getContactData();        
    }

    getContactData() {
        this.Spinner.show();  

        this.USAuPairs.getContact({
            aupairId: this.Auth.user.usAuPairId
        })
            .$promise
            .then((result) => {
                this.details = (result.data && result.data.aupairContact) || new usContact();
                this.details.skypeId = result.data.skypeId;  
                this.details.usCellPhone = result.data.usCellPhone;  
                this.details.otherEmailAddress = result.data.otherEmailAddress;  

                this.data = result.data.extensionAppReview;
                this.displayReadOnly = (this.data.extensionApplicationReview
                    && (this.data.extensionApplicationReview.usContactSectionStatus == '3'
                    || this.data.extensionApplicationReview.usContactSectionStatus == '5'
                    || this.data.extensionApplicationReview.usContactSectionStatus == '6')
                        ) || false;

                if (!this.displayReadOnly) {
                    this.details.usCellPhone = (result.data.usCellPhone && +result.data.usCellPhone);
                    this.details.otherPhone = (result.data.aupairContact && result.data.aupairContact.otherPhone && +result.data.aupairContact.otherPhone);
                    this.details.whatsAppNo = (result.data.aupairContact && result.data.aupairContact.whatsAppNo && +result.data.aupairContact.whatsAppNo);
                }

                this.displayOfficeMessage = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.usContactSectionStatus == '4') || false;

                this.message = (this.data.extensionApplicationReview
                    && this.data.extensionApplicationReview.usContactSectionStatusReason)
            })
            .finally(() => {
                this.Spinner.hide();
            });
    }

    submit() {  
        this.$scope.$broadcast('submitted', true);
        var form = this.$scope.contactForm;
        if (form['USContactForm'].$valid) {  
            this.Spinner.show();
            var promises = [];
            this.alerts = [];

            this.details.aupairId = this.Auth.user.usAuPairId;
            this.details.isSubmitted = true;

            promises.push(this.USAuPairs.saveContact({ aupairId: this.Auth.user.usAuPairId }, this.details).$promise);

            this.$q.all(promises)
                .then((result) => {
                    this.$scope.contactForm.$setPristine();
                    this.$rootScope.$broadcast('update:extension');
                    this.getContactData();     
                    this.alerts.push({
                        type: 'success',
                        msg: 'Save complete',
                        dismissOnTimeout: 3000
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, 3000);
                }, (result) => {
                    var timeOut = 5000;
                    var errorMessage = 'We are sorry but your form could not be saved. Please try again in 5 minutes. <br /> If this problem continues, please contact us at: <a target= "_blank" href= "mailto:info@aupairamerica.co.uk" > info@aupairamerica.co.uk</a>'

                    if (result.data.hasError) {
                        timeOut = 30000;
                        errorMessage = result.data.error[0].errors
                    }

                    this.alerts.push({
                        type: 'danger',
                        msg: errorMessage,
                        dismissOnTimeout: timeOut
                    })
                    this.showMessageText = true;
                    this.$timeout(() => { this.showMessageText = false; }, timeOut);
                })
                .finally(() => {
                    this.Spinner.hide();
                });
        }
    }
}
angular
    .module('app')
    .controller('ExtensionContactInfoController', ExtensionContactInfoController);