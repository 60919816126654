import _ from 'lodash-es'
import { DocumentUploadConfig, MultiDocumentUploader } from '../documentsUpload/DocumentUploadConfig'
import { allowTypes, ChangeDocumentTypeController } from '../changeDocumentType/ChangeDocumentTypeController'


class ApplicationUploadsDirective implements ng.IDirective {
        restrict = 'AE'
        controller = ApplicationUploadsController
        controllerAs = 'auCtrl'
        bindToController = true
        templateUrl = 'controls/applicationUploads/applicationUploads.html'    
        scope = {
            applicationId: '=',
            applicationStatus: '=',
            isDocumentOpen: '=?'
        }               
}
    
class Dictionary {
    
    _keys: Array<number> = new Array<number>();
    _values: Array<any> = new Array<any>();
    
    constructor(init: { key: number; value: any; }[]) {
    
        for (var x = 0; x < init.length; x++) {
            this[init[x].key] = init[x].value;
            this._keys.push(init[x].key);
            this._values.push(init[x].value);
        }
    }
    
    add(key: number, value: any) {
        this[key] = value;
        this._keys.push(key);
        this._values.push(value);
    }
    
    remove(key: number) {
        var index = this._keys.indexOf(key, 0);
        this._keys.splice(index, 1);
        this._values.splice(index, 1);
    
        delete this[key];
    }
    
    keys(): number[] {
        return this._keys;
    }
    
    values(): any[] {
        return this._values;
    }
    
    containsKey(key: string) {
        if (typeof this[key] === "undefined") {
            return false;
        }
    
        return true;
    }
}

class ApplicationUploadsController {

    applicationId: number
    isDocumentOpen: boolean
    uploads: any[]
    showOtherDocumentUpload: boolean
    otherDocumentUploadsConf: DocumentUploadConfig<MultiDocumentUploader>
    isOfficeUser: boolean
    approvedLabel: string
    awaitingApprovalLabel: string
    imageLoads: Dictionary;
    loadTimestamp: number;
    loading: boolean;
    applicationStatus: string;

    static $inject = ['$scope', '$rootScope', 'ApplicationUploads', 'Auth', 'dialogs', 'ApplicationUploader', 'ApplicationDocument', 'ApplicationDocumentPage', '$sce', '$uibModal'];
    constructor(private $scope, private $rootScope, private ApplicationUploads, private Auth, private dialogs, ApplicationUploader, private ApplicationDocument, private ApplicationDocumentPage, private $sce, private $uibModal) {

        this.approvedLabel = 'approved';
        this.awaitingApprovalLabel = 'awaiting approval';

        this.isOfficeUser = Auth.user.isOfficer;

        $scope.$watch(() => this.applicationId, (newValue, oldValue) => {
            if (newValue) {
                this.loadUploads(newValue);
            }
        });

        $scope.$watch(() => {
            if (this.uploads) {
                for (let upload of this.uploads) {
                    if (upload.isOpen) return true;
                }
            }
            return false;
        }, (newValue, oldValue) => {
            this.isDocumentOpen = newValue;

        });

        $scope.$on('uploadDataStateChanged', (event, uploadChange) => this.loadUploads(this.applicationId));
        // TODO: check if works after migration to angular 1.7
        if ($scope.$parent && $scope.$parent.ctrl && $scope.$parent.ctrl.applicationId) {
            this.showOtherDocumentUpload = true;

            let uploader = new ApplicationUploader($scope.$parent.ctrl.applicationId, 20);    //20 is Other + Other certificate (Type of document 16, 31)

            this.otherDocumentUploadsConf = {
                uploader: uploader
            }
        }

    }

    now() {
        return Math.floor(Date.now() / 1000);
    }

    enableVideo(upload: any) {
        for(let page of upload.pages) {
            page.videoUrl = [{
                src: this.$sce.trustAsResourceUrl(page.uri),
                type: 'video/mp4'
            }];
        }
    }

    loadUploads(applicationId: number) {
        this.ApplicationUploads.query({ applicationId: applicationId }, (response) => {
                
            this.uploads = response;

            var initLoading = this.uploads.map(function(val) {
                return { key: val.id, value: false};
            })
            this.imageLoads = new Dictionary(initLoading);

            this.loadTimestamp = this.now();

            for(let upload of this.uploads) {
                if(upload.document == "Video") {
                    this.enableVideo(upload);
                }
            }
        });
    }

    refreshExpiredUrls(upload: any, response: any)
    {
        for(let page of upload.pages) {
            var newPage = response.pages.find(x => x.id = page.id);
            page.uri = newPage.uri;
        }
    }

    loadUpload(applicationId: number, upl: any) {
        this.ApplicationUploads.getUpload({ applicationId: applicationId, applicationDocumentId: upl.id }, (response) => {
                
            var upload = this.uploads.filter(u => u.id == upl.id)[0];
            this.refreshExpiredUrls(upload, response);
            if(upload.document == "Video") {
                this.enableVideo(upload);
            }

            this.imageLoads.add(upload.id, true);

            this.loadTimestamp = this.now();
        });
    }

    haveUrlsExpired()
    {
        return (this.now() - this.loadTimestamp) > 540;
    }

    loadImages(upl: any) {
        if(this.haveUrlsExpired() && !this.wasUploadSelected(upl)) {
            this.loadUpload(this.applicationId, upl);
        }
        else {
            this.markUploadAsSelected(upl);
        }
    }

    markUploadAsSelected(upl: any)
    {
        if(upl != null){
            if(this.imageLoads != undefined) {
                this.imageLoads.add(upl.id, true)
            }
        }
    }

    wasUploadSelected(upl: any) {
        if(this.imageLoads == undefined) {
            return false;
        }

        return this.imageLoads[upl.id];
    }

    approve(upload: any) {
        if (!upload || !_.isNumber(upload.id)) return;

        let approvedStatus = 1;
        this.ApplicationDocument.approve({ documentId: upload.id }, { status: approvedStatus })
            .$promise
            .then(() => {
                upload.state = this.approvedLabel;
                this.uploadDataStateChanged();
            }, () => {
                this.displayApprovalError();
            });
    }

    unapprove(upload: any) {
        if (!upload || !_.isNumber(upload.id)) return;

        let awaitingApprovalStatus = 2;
        this.ApplicationDocument.unapprove({ documentId: upload.id }, { status: awaitingApprovalStatus })
            .$promise
            .then(() => {
                upload.state = this.awaitingApprovalLabel;
                this.uploadDataStateChanged();
            }, () => {
                this.displayApprovalError();
            });
    }

    removePage(upload: any, page: any) {
        if (!upload || !_.isNumber(upload.id)) return;
        if (!page || !_.isNumber(page.id)) return;

        this.dialogs.confirm('Confirm Delete', 'Are you sure that you want to delete this file?')
            .result
            .then(() => {
                this.ApplicationDocumentPage.remove(page)
                    .$promise
                    .then(() => {
                        let pagesIndex = upload.pages.indexOf(page);
                        if (pagesIndex > -1) {
                            upload.pages.splice(pagesIndex, 1);
                        }

                        if (upload.pages.length === 0) {
                            let uploadsIndex = this.uploads.indexOf(upload);
                            if (uploadsIndex > -1) {
                                this.uploads.splice(uploadsIndex, 1);
                            }
                        }
                        this.uploadDataStateChanged();
                    }, () => {
                        this.displayRemovalError();
                    })
            });
    }

    displayChangeUploadType(upload: any) {
        return this.isOfficeUser && allowTypes.indexOf(upload.documentType) !== -1;
    }

    changeUploadType(upload: any) {
        if (!upload || !_.isNumber(upload.id)) return;

        this.$uibModal
            .open({
                templateUrl: 'controls/changeDocumentType/changeDocumentType.html',
                controller: ChangeDocumentTypeController,
                controllerAs: '$ctrl',
                backdrop: false,
                resolve: {
                    document: () => upload,
                    showRedCrossCertDoc: () => (this.applicationStatus === 'PLC' || this.applicationStatus === 'TBM' || upload.documentType === 53) ?? 0
                }
            })
            .result
            .then(() => { this.uploadDataStateChanged(); });
    }

    uploadDataStateChanged() {
        this.$rootScope.$broadcast('sideMenu:uploadDataStateChanged', true);
    }

    displayApprovalError = () => this.dialogs.error('Internal error', 'Error occurred while unapproving an image. Please try again after a while.');
    displayRemovalError = () => this.dialogs.error('Internal error', 'Error occurred while removing an image. Please try again after a while.');

}


angular
    .module('app')
    .directive('applicationUploads', () => new ApplicationUploadsDirective());
